const privacy = {
  'privacy.title': 'Privacy Policy',
  'privacy.html': '',
  'privacy.markdown': `
  #### 1. General
  Makestar Corporation which provides service on Makestar.co, values the personal information provided by members for use of our service, and we set and strictly follow the privacy policy.
  The company’s privacy policy follows related laws and the privacy policy established by the Korean Ministry of Information and Communication, and we will do our best to protect personal information so member will not experience any loss from leak of personal information.


  #### 2. The collecting purpose and use purpose of personal information
  The reason of collecting personal information and the use purpose of personal information is to provide optimum service to the member, and we are collecting personal information needed in order to do so. The personal information provided by the member are used to provide useful information to the members or to provide answer to various opinions requested by members.


  #### 3. The range of collecting and collecting method of personal information
  1. Collecting range
      * The company collects the personal information that has been agreed on by the members and it is through legal and fair procedure. For this, we included a step when member can press “Agree” button, so when the “Agree” button is pressed, it is assumed that the member has agreed on the collecting of personal information.
      * Personal information that is collected by the company
        * Information received at time of member registration : User’s name, email address, password, nickname, birthdate, gender, country of residence
        * Information received at the time of funding participation : Address, name, email, phone number
  2. Collecting method : Member registration and information change through website, inquiry mail, phone call, etc.


  #### 4. Use and provision of personal information
  1. The company may use the collected personal information for following purpose
      * Website member management : Identification/identity check for membership service use, etc., prevention of members’ illegal use and prevention of unauthorized use, confirmation of intention to register, limitation of registration and number of registration, delivery of announcement, processing of consultation, etc.
      * Provide email service
      * Use for survey and event
      * Providing contents and customized service, service purchase and fee payment, financial transaction identity verification and financial service, delivery service following product order
      * New service (contents) development and specialization, finding out frequency of access, statistics on members’ service use
  2. The personal information provided by member cannot be used for purpose other than the ones already notified or specified by the company and cannot be provided to a third party. However, there will be exception for following cases.
      * In case there is a request from investigative agency, following procedure and method set by law, for investigation purpose
      * In case there is a special regulation under Korea’s financial real name transaction and confidentiality related laws, credit information’s use and protection related laws, electronic communication fundamental law, electronic communication business law, local tax law, Consumer Protection Act, Korean Bank law, Criminal Protection Act, etc.
      * Based the regulations of Korean law, statistics purpose, academic study, market research, etc
  3. In case consent from a member is needed, the following has to be stated or announced beforehand, and a member has right to refuse and also withdraw consent at any time.
      * Identity of person in charge of personal information management (Affiliation, name and phone number)
      * Collection purpose and use purpose of personal information
      * Personal information that is collected
      * Possession period and use period of personal information that is collected
      * Matter related to providing information for a third party
      * Member’s right to withdraw consent, and right to request viewing/correction of error regarding their information and ways to exercise the right
      * Other regulations set by other laws related to Korean telecommunication network promotion
  4. In case inaccurate personal information such as email causes inconvenience in the service quality, the website administrator can delete or revise that information.
  5. The personal information that member willingly provides can be collected and used by a third party and the member is responsible.
  6. Also checking personal information to perform tasks is limited to the person in charge of the task (personal information handler) who has authority to access.


  #### 5. Disclosure to Third Parties
  1. The company does not disclose your private personal information without the user’s explicit consent except in the limited circumstances stipulated under section 4 and in the following instances.
      * The user has previously agreed to disclosure of private personal information to a third party.
      * When it is reasonably necessary to comply with a law, regulation, legal process, or governmental request.
      * When necessary to process transactions.
  2. In the event that disclosure of private personal information is required outside of the above mentioned instances, the company should receive consent from the user and provide the information to a third party for the purpose outlined below in order to provide for the proper implementation of transaction between users
  **Disclosure of private personal information to a third party**
        * Receiver : Project creator
        * Information Provided : Name, ID, mobile phone number, email address, phone number
        * Usage : Delivery of reward
        * Holding Time : 1 month from time of delivery

  #### 6. The Rights and Exert of the user regarding individual information
  1. The company always respects each individual right to all information.
  2. Users have the rights to track the information such as record of visit history, e-signature by his ID as well as error correcting and withdraw a motion, and the company needs to take steps after checking the user’s personal information.
  3. When the user asks the company to correct the errors, the company does not have rights to use user’s individual information, and should deliver the announcement of the usage.
  4. When the user may want to browse or modify the individual information, users have the rights to access to the ‘profile settings’ to browse or modify the information after login at any time.

  #### 7. Possession and use period of personal information
  In principle after the purpose of personal information collection and use purpose have been fulfilled (membership cancellation and email service cancellation)
  1. Reason for storing information by the company’s internal Regulations
      * email address
        * Reason for storing : To prevent abuse by rogue members and unauthorized use, and to prevent crossing of service use
        * Storing period : 1 year
  2. Reason for storing information by related laws
      * Website visit records
        * Reason for storing : Korean communication confidentiality law
        * Storing period : 3 months
      * Records related to identity verification
        * Reason for storing : Korean laws related to telecommunication network use promotion and information protection
        * Storing period : 6 months
      * Records related to disply/advertisement
        * Reason for storing : Korean laws related to protecting comsumers in electronic transaction, etc.
        * Storing period : 6 months
      * Records related to contract or withdrawal of subscription
        * Reason for storing : Korean laws related to protecting consumers in electronic transaction, etc.
        * Storing period : 5 years
      * Records related to paying bill and provision of commodities, etc.
        * Reason for storing : Korean laws related to protecting consumers in electronic transaction, etc.
        * Storing period : 5 years
      * Records related to processing consumer complaint or dispute
        * Reason for storing : Korean laws related to protecting consumers in electronic transaction, etc.
      * Storing period : 3 years


  #### 8. Technical and administrative measures for personal information protection
  1. The company will do their best to seek technical and administrative measures in order to prevent personal information from leaking.
  2. In regards to handling user’s personal information, the company is doing best to guarantee stability by preventing loss, theft, leak, falsification or damage. To do so, the company is seeking technical measures by using security device with password, computer virus prevention device using vaccine program, and encrypted algorithm, etc., and access restriction device with Firewall and other technical device needed to secure stability by using security device that can safely transfer personal information within the network.
  3. The company limits the employees who can access personal information to a minimum number, is conducting continuous training for following this policy and protecting personal information. Also the company is doing their best by changing the administrator’s password for personal information access changed regularly to prevent illegal leak of information.


  #### 9. Regarding Cookie management
  1. The company uses “Cookie” that stores members’ information and finds them frequently. Cookie is minimal information that website sends to members’ computer browser. When the member accesses the website, the company’s computer will read the contents of Cookie in the member’s browser, it finds rest of member’s information, so service can be provided without additionally entering name, website, address, etc.
  2. Cookie distinguishes member’s computer but does not distinguish members individually. Also, members can have option to adjust web browser options to accept all Cookie, get notice when Cookie is installed, or block all Cookie
  3. With cookie setting block instuction (Internet Explorer) being as simple as “selecting Tools from top of web browser > internet option > personal information tab > privacy(personal information handling) level,” user can set the web browser’s options to allow all cookies, confirm every time Cookie is saved, or refuse saving of all Cookie. However, be aware that if the saving of Cookie is refused, there may be limitation in using some services that need logging in.


  #### 10. Advertisement information delivery
  The company does not send advertisement information for commercial use against members’ expression of intention to refuse receiving. Company’s mailing service may send newsletter or service information guide, or have surveys and events to provide better service and for better expression of intention to refuse receiving, it will be marked on the bottom. The minimum personal information collected for statistics and prize delivery will be destroyed after prize delivery/return (1 month after completion).

  #### 11. Liiv Mate New Member Third-Party Information Agreement
  1. Audience: Member of the 'Liiv Mate' App on the 'Co-sponsored'
  2. Provided for: LG UPLUS Co., Ltd.
  3. Provided information:
      * 1)Pledger's information provided at Makestar sign-up: name, age, sex, contact number, e-mail address, etc.
      * 2)Pledger statistics involving project and funding(payment): funded project name, funding amount, reward etc.
      * 3)Pledger statistics involving Makestar service usage history: Makestar visit history, project name etc.
  4. Use of personal information: The provided information is used for statistical analysis of service usage pattern for the purpose of service development, personalization, customer management (delivery of notices, checking of service usage status, etc.), events, etc.
  5. Information retention period: Duration of Liiv Mate App 'Pledge Together' service use.

  #### 12. Personal information management duty and announcement of person in charge
  The company has assigned a personal information management personnel who is in charge of receiving opinions and processing complaints. Person in charge of personal information management is as following

  [Person in charge of personal information management]

  * Name : Oh Jongmyung
  * Phone : +82-2-6959-2076
  * E-MAIL : privacy@makestar.co
  `,
};

export default privacy;
