import React, { FC, ReactNode } from 'react';

//Style
import classNames from 'classnames/bind';
import styles from './Terms.module.scss';
import { useIntl } from 'react-intl';

const cx = classNames.bind(styles);

interface Props {
  children: ReactNode;
  title: string;
  index: number;
  checked: boolean | undefined;
  onChecked(index: number, checked: boolean): void;
}

const Term: FC<Props> = ({ checked, children, onChecked, index, title }) => {
  // 전체 체크 확인 때문에 부모 컴포넌트에서 관리
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked } = e.currentTarget;
    onChecked(index, checked);
  };

  const { formatMessage } = useIntl();

  return (
    <div className={cx('term')}>
      <div className={cx('title')}>
        <input type={'checkbox'} checked={checked} onChange={onChange} />
        <span>{title}</span>
        <span className={cx('required')}>{`(${formatMessage({
          id: 'required',
        })})`}</span>
      </div>
      <div className={cx('contents')}>{children}</div>
    </div>
  );
};

export default Term;
