export const warmup = '/v1/warmup';
export const login = '/v1/user/login';
export const findPassword = '/v1/user/passwd';
export const checkEmail = '/v1/user/check/email';
export const checkNickname = '/v1/user/check/nickname';
export const signup = '/v1/user';
export const countries = '/v1/user/countries';
export const userCheck = '/v1/user/me';
//fanclub
export const fanclub = '/verify_member';
