import React, { SFC } from 'react';
import Message from '../Message/Message';

//Icons
import { ReactComponent as ErrorIcon } from '../../img/icon-alert-error-24-px.svg';

//Styles
import styles from './Error.module.scss';
import classNames from 'classnames/bind';
import { makestar } from '../../modules/Request/Uri/domain';
import { Link, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

const cx = classNames.bind(styles);

interface Props {
  title?: string;
  messages?: string[];
  toHome?: boolean;
  toLogin?: boolean;
  toGoLinks?: LinkType[];
}

interface LinkType {
  url: string;
  lable: string;
}

const Error: SFC<Props> = ({
  messages,
  title,
  toHome = true,
  toLogin = true,
  toGoLinks,
}) => {
  const location = useLocation();
  const intl = useIntl();
  return (
    <Message>
      <div className={cx('wrapper')}>
        <ErrorIcon />
        <div className={cx('message')}>
          <span className={cx('title')}>
            {title || "404. That's an error."}
          </span>
          {messages &&
            messages.map((message, idx) => (
              <span key={`message_${idx}`}>{message}</span>
            ))}
          {toHome && (
            <a href={makestar} className={cx('button-normal')}>
              {intl.formatMessage({ id: 'toMakestar' })}
            </a>
          )}
          {toLogin && (
            <Link to={`/${location.search}`} className={cx('button-emphasis')}>
              {intl.formatMessage({ id: 'toiLoginPage' })}
            </Link>
          )}
          {toGoLinks &&
            toGoLinks.map((link, index) =>
              link.url.startsWith('http') ? (
                <a
                  href={link.url}
                  key={`link_${index}`}
                  className={cx('button-normal')}
                >
                  {link.lable}
                </a>
              ) : (
                <Link
                  to={`${link.url}${location.search}`}
                  className={cx('button-normal')}
                >
                  {link.lable}
                </Link>
              ),
            )}
        </div>
      </div>
    </Message>
  );
};

export default Error;
