import React, { FC } from 'react';

// Styles
import classNames from 'classnames/bind';
import styles from './Header.module.scss';
import { ReactComponent as Logo } from '../../img/makestar-logo.svg';
import { makestar } from '../../modules/Request';

const cx = classNames.bind(styles);

interface Props {}

const Header: FC<Props> = () => {
  const onClick = (): void => {
    window.location.href = makestar;
  };

  return (
    <div className={cx('header')}>
      <Logo className={cx('logo')} onClick={onClick} />
    </div>
  );
};

export default Header;
