const privacy: Record<string, string> = {
  'privacy.title': '隐私权政策',
  'privacy.html': '',
  'privacy.markdown': `
  #### 一．总则
  提供Makestar.co服务的麦克星达有限公司(以下简称”公司”)认为会员为使用服务而提供的个人信息非常重要,因此制定了《隐私权政策》并且严格遵守。公司的《隐私权政策》遵守相关法律法规及韩国的通信情报部制定的《隐私权政策》，尽全力保护会员避免因个人信息泄露而造成损失。
  会员可随时阅读并确认本政策，《隐私权政策》可能会依据相关法律法规及政府方针，为了提供更好的服务而修改。


  #### 二．个人信息的收集目的及使用目的
  公司收集会员个人信息的理由及使用目的，是为了通过公司运营的网站（makestar.co）提供最佳的服务，因此收集个人信息。会员提供的个人信息是用于为会员提供有用的情报或回答会员的各种疑问。


  #### 三．个人信息的收集范围及收集方法
  1. 收集范围
      * 公司收集通过合法公正的程序和用户的同意的个人信息。因此网站设有对《隐私权政策》及《服务条款》的内容进行同意的【同意】按键，阅读后若点击了【同意】按键则表示同意公司收集会员的个人信息。
      * 公司收集的个人信息项目
        * 注册会员时需要的信息：姓名 , 邮箱地址，密码，昵称，出生年月日，性别，居住过家
        * 参与项目需要的信息：姓名，邮箱地址，地址，电话
  2. 收集方法：通过网页注册及变更会员、咨询邮件、电话咨询等。


  #### 四. 个人信息的使用和提供
  1. 公司收集个人信息用于以下目的。
      * 网页会员管理：根据会员制服务的使用，本人确认、个人识别、防止不良会员的不良使用及不法使用，加入目的的确认、加入及加入次数的限制，转达通知事项和咨询解答等；
      * 提供电子邮件服务；
      * 问卷调查及活动；
      * 提供资讯及会员定制型服务，购买服务及付款，金融交易本人认证及金融服务，根据服务订单的派送服务；
      * 新服务（资讯、内容）的开发和特化，活动等广告性质的转达，根据统计学特征提供服务及刊载广告，掌握网站提交频度，会员使用服务相关统计。
  2. 会员提供的个人信息在未获得会员本人同意的情况下，公司不得用于除已通知或明示的目的范围以外，也不得向第3者提供。但，以下情况除外。
      * 搜查机关提出依据法律规定的程序和方法以搜查为目的的要求的情况；
      * 韩国的《金融实名交易及密码保障相关法律》、《信用情报使用及保护相关法律》、《电气通信基本法》、《电气通信事业法》、《地方税法》、《消费者保护法》、《刑事诉讼法》等法律法规有特别规定的情况；
      * 依据韩国法律的规定用于统计目的、学术研究、市场调查等以无法确定特定个人的形式提供个人信息的情况；
  3. 即使是需获得会员同意的情况公司也需提前通知或明示以下内容。对此会员有拒绝的权利，或者随时可取消同意。
      * 个人信息管理负责人的身份信息（所属、姓名及电话号码）
      * 个人信息的收集目的及使用目的
      * 收集的个人信息项目
      * 收集的个人信息的保留时间及使用时间
      * 给提供第3者个人信息的相关事项
      * 会员的同意取消权和对自身信息的阅读•修正错误要求及处理方法
      * 其他韩国《促进情报通信网使用的相关法律》等相关法律法规规定的事项
  4. 若因电子邮件等虚假个人信息的原因妨碍服务质量的情况，网页运营者有权删除或修正相关信息。
  5. 会员在网络上自发性地提供给第3者个人情报收集及使用的情况，责任全部由会员承担。
  6. 另，为了业务的顺利进行而确认个人信息的情况只有业务担当者（个人信息负责人）有权利。


  #### 五．向第3者提供个人信息
  1. 公司在「4. 个人信息的使用和提供」所规定的范围内使用会员们的个人信息,在未取得会员事先同意的情况下,不会在超出规定的范围内使用或者原则上不会将用户的个人信息提供给第3者.但,以下情况除外.
      * 用户们事先公开或同意向第3这提供信息的情况
      * 依据法律法规,以搜查、调查为目的，根据法律法规规定的程序和方法搜查机关及监督当局要求的情况
      * 为了金钱清算而需要的情况
  2. 之外需要向第3者提供个人信息的情况需通过得到用户的同意等合法的程序才能向第3者提供个人信息。为了用户的交易能正常进行，公司有权在通过得到用户的同意等合法程序后向第3者提供以下个人信息。
  **向第3者提供个人信息**
      * 获得信息的对象 : 项目发起人
      * 分享条目 : 名字，ID，手机号码，邮件地址，电话号码
      * 对方的使用目的 : 用于派送回报
      * 保留及使用时间 : 派送结束后1个月


  #### 六．会员对于个人信息的权利及使用方法
  1. 公司最大限度尊重会员对于个人信息的权利。
  2. 会员可通过访问、电子署名或会员ID等对公司收集的本人的个人信息进行阅读、修正错误以及取消收集同意，公司通过本人确认后需立即执行会员的相关要求。
  3. 会员申请修正错误信息的情况，公司在信息得到修正期间不得使用相对应的个人信息，若公司直接修正则需在修正后通知相应会员。
  4. 会员通过会员ID对个人信息进行阅读及修改的情况，使用注册的ID和密码登录，打开客服中心的个人信息管理菜单，随时可以阅读、确认及修改本人的个人信息。


  #### 七．个人信息的保留和使用时间
  原则上达到收集和使用个人信息的目的后(会员注销及解除电子邮件服务)需销毁相关个人信息。但，包括以下内容时公司和相关法律法规明确规定的时间内信息保留。
  1. 根据公司内部方针的信息保留事由
      * 电子邮件
        * 保留理由：防止不良会员的不正当使用和不法使用及服务混乱
        * 保留时间：1年
  2. 依据相关法律法规的信息保留事由
      * 网站访问记录
        * 保留根据：韩国《通信秘密保护法》
        * 保留时间：3个月
      * 本人确认相关记录
        * 保留根据：韩国《促进情报通信网使用的相关法律》
        * 保留时间：6个月
      * 显示/广告相关记录
        * 保留根据：韩国《电子交易等消费者保护法》
        * 保留时间： 6个月
      * 合约或撤销合约相关记录
        * 保留根据： 韩国《电子交易等消费者保护法》
        * 保留时间： 5年
      * 结算或回报提供相关记录
        * 保留根据： 韩国《电子交易等消费者保护法》
        * 保留时间： 5年
      * 消费者的不满或纷争处理相关记录
        * 保留根据： 韩国《电子交易等消费者保护法》
        * 保留时间： 5年


  #### 八．个人信息保护相关技术管理对策
  1. 公司为保护个人信息不泄露而尽全力寻求技术•管理对策。
  2. 公司尽全力确保安全性保护用户的个人信息不发生丢失、失窃、泄露、伪造或毁损。因此努力构建及加强使用密码等的保安装置、使用杀毒软件的电脑防病毒装置、使用加密算法等在网络环境里安全地传送个人信息的保安装置、防入侵装置等控制接近装置、其他为确保安全性而必需的技术装置等技术对策。
  3. 公司尽可能限制可查看个人信息的职员，遵守本政策及持续进行个人信息保护教育。另，可查看个人信息的运营者密码定期更换，尽全力阻止违法的信息泄露。


  #### 九．Cookie运营的相关事项
  1. 公司保存会员的信息，使用随时找到的“Cookie”。Cookie是网页向会员的电脑浏览器传送的少量的信息。会员登录网页的话，本公司的电脑会读取会员的浏览器内存有的Cookie，在会员的电脑查找会员的追加信息，实现无需姓名、网页地址等追加输入便能提供服务的功能。
  2. Cookie虽然识别会员的电脑但不识别会员的个人信息。另，会员可对浏览器进行选项设置，具有接受全部Cookie、产生Cookie时接受通知，或者拒绝所有Cookie这样的选择权。
  3. 设置拒绝Cookie的方法（IE浏览器）浏览器菜单的道具 > Internet选项 > 个人信息 > 个人信息管理标准, 用户可在此设置浏览器的选项来选择具有接受全部Cookie、产生Cookie时接受通知，或者拒绝所有Cookie。但，拒绝保存Cookie的情况，需要登录使用的一部分服务将被限制提供，望见谅。


  #### 十．广告性信息传播
  在会员明确表示拒绝收信的情况下公司不会发送带有营利目的的广告性信息。公司的邮件服务是为了提供更好的服务而提供新闻报道、服务信息咨询、问卷调查和活动等的服务。为了让会员可以方便快捷地按照自己的意愿接受或拒绝收信，在邮件底部会有选项表示。这时，为统计及奖品发送而收集的最少化的个人信息在奖品发送/退回（截止后1个月）后销毁。
  
  #### 十一. Liiv Mate注册会员第3方信息授权 
  1. 对象:通过 ‘Liiv Mate’ App 上 ‘一起支持’ 服务注册Makestar移动网页的会员
  2. 获得授权方: （株）LG U+
  3. 授权项目:
      * 1)注册Makestar时输入的会员个人信息: 姓名, 年龄, 性别, 联系方式, 邮件地址等
      * 2)项目及众筹(付款) 相关支持者统计信息: 众筹的项目名, 众筹金额, 回报等
      * 3)Makestar登录相关会员统计信息：Makestar登录数据，查看的项目名等。
  4. 被授权方个人信息使用目的: 用于一起支持及Makestar服务的统计/分析，提供使用频度及统计学相关的服务，服务开发，特化，会员管理（通知事项传达，服务使用状况确认等），活动等。
  5. 保有及使用时间: Liiv Mate App 内 '一起支持' 服务使用时间


  #### 十二．个人信息管理义务及负责人公告
  公司指定负责个人信息相关意见收集及处理意见的个人信息管理负责人。个人信息管理负责人相关信息如下。

  **[个人信息管理负责人]**

  * 姓名：张超美
  * 电话: 02-6959-2076
  * 电子邮件 : privacy@makestar.co
  `,
};

export default privacy;
