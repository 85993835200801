import React, { FC } from 'react';
import Message from '../../components/Message/Message';
import { useLocation, match, Redirect } from 'react-router-dom';
import Error from '../../components/Error/Error';
import Success from '../../components/Success/Success';
import { connect } from 'react-redux';
import { StoreState } from '../../reducers';
import { SetFanclubPayload } from '../../actions/info';
import { Record } from 'immutable';
import TempPasswordMessage from '../../components/TempPasswordMessage/TempPasswordMessage';
import { useIntl } from 'react-intl';

interface Match extends match {
  params: {
    type: string;
  };
}

interface Props {
  match: Match;
  fanclub?: Record<SetFanclubPayload>;
}

const ResultContainer: FC<Props> = ({ match, fanclub }) => {
  const { search } = useLocation();
  const intl = useIntl();

  const type =
    new URLSearchParams(search).get('type') || match.params.type || '';

  let element = <Error />;
  switch (type.toLowerCase()) {
    case 'fail':
      element = (
        <Error
          title={intl.formatMessage({ id: 'failureMessage' })}
          messages={intl
            .formatMessage({ id: 'failureSuccessMessage' })
            .split('\n')}
          toLogin={false}
        />
      );
      break;
    /*case 'changeemail':
      element = (
        <Success
          title={'이메일 변경 완료'}
          messages={[
            '이메일 변경이 완료되었습니다.',
            '이후 로그인은 변경된 이메일로 해주세요!!',
          ]}
        />
      );
      break;
    case 'activation':
      element = (
        <Success
          title={'이메일 인증이 완료되었습니다.'}
          messages={['지금 바로 다양한 프로젝트에 참여해보세요!']}
        />
      );
      break;*/
    case 'fanclub':
      if (fanclub) {
        element = (
          <Success
            title={intl.formatMessage(
              {
                id: 'fanclubVerifySuccessMessage',
              },
              { fanclub: fanclub.get('fanclub').name },
            )}
            messages={intl.formatMessage({ id: 'reLoginMessage' }).split('\n')}
            toLogin={false}
            toGoLinks={
              fanclub.get('projectUrl')
                ? [
                    {
                      url: fanclub.get('projectUrl'),
                      lable: intl.formatMessage({ id: 'toProject' }),
                    },
                  ]
                : undefined
            }
          />
        );
      } else {
        element = <Redirect from="/" to="/result/fail" />;
      }
      break;
    case 'findpassword':
      element = (
        <Success
          title={intl.formatMessage({ id: 'tempPasswordLoginMessage' })}
          messages={[
            intl.formatMessage({ id: 'tempSuccessMessage1' }),
            intl.formatMessage({ id: 'tempSuccessMessage2' }),
          ]}
          toLogin={false}
        />
      );
      break;
    case 'password':
      element = <TempPasswordMessage />;
      break;
    case 'error':
      element = (
        <Error
          title={''}
          messages={intl
            .formatMessage({ id: 'requestErrorMessage' })
            .split('\n')}
          toLogin={false}
        />
      );
      break;
    default:
      element = <Redirect to={'/notfound'} />;
      break;
  }

  return <Message>{element}</Message>;
};

export default connect(
  ({ info }: StoreState) => ({
    fanclub: info.get('fanclub'),
  }),
  null,
)(ResultContainer);
