import React, { FC } from 'react';
import Message from '../Message/Message';

//Style
import styles from './TempPasswordMessage.module.scss';
import classNames from 'classnames/bind';
import { useLocation, Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

const cx = classNames.bind(styles);

interface Props {}

const TempPasswordMessage: FC<Props> = () => {
  const { state: locationState, search } = useLocation();
  const intl = useIntl();
  return (
    <Message>
      {locationState ? (
        <div className={cx('wrapper')}>
          <div className={cx('title')}>
            <span>
              {intl.formatMessage({ id: 'tempPasswordLoginMessage' })}
            </span>
          </div>
          <div className={cx('message')}>
            <span>
              {intl.formatMessage(
                { id: 'tempPasswordCompleteMessage' },
                { email: locationState.email },
              )}
            </span>
          </div>
          <span className={cx('message')}>
            {intl.formatMessage({ id: 'checkSpamMailMessage' })}
          </span>
          <Link to={`/${search}`} className={cx('button-emphasis')}>
            {intl.formatMessage({ id: 'toiLoginPage' })}
          </Link>
        </div>
      ) : (
        <Redirect to={`/result/error${search}`} />
      )}
    </Message>
  );
};

export default TempPasswordMessage;
