import privacy from './term/privacy';
import terms from './term/terms';

const zh: Record<string, string> = {
  ...terms,
  ...privacy,
  emailInvalidMessage: '邮件格式不正确。',
  signupCompleteCongratulationsMessage: '{ name }， 恭喜您成为Star Maker。',
  signupComplete: '完成注册',
  login: '登录',
  required: '必选',
  termsAllMessage: '只有同意服务条款才能正常使用Makestar服务。',
  acceptTerms: '同意条款',
  findPasswordMessageOne: '请输入您的注册邮箱。',
  findPasswordMessageTwo: '我们将发送临时密码至您的注册邮箱。',
  email: '邮箱地址',
  signUp: '注册会员',
  loginFailMessage: '请再次确认邮箱或密码。',
  password: '密码',
  findPassword: '找回密码',
  failureMessage: '',
  failureSuccessMessage: '认证邮件过了有效期或者邮件地址错误。',
  tempSuccessMessage1: '已成功为您设置临时密码。',
  tempSuccessMessage2: '请先使用临时密码登录后再重置您的密码。',
  DOBInvalidMessage: '日期格式错误。',
  DOBAgeRestrictionMessage: '未满13岁不能进行注册。',
  passwordInvalidMessage: '密码必须在6~20字符以内。',
  passwordConfirmNotMatchMessage: '密码不一致,请再次输入。',
  nameInvalidMessage: '名字必须2个字以上。',
  nicknameInvalidMessage: '昵称必须3个字以上。',
  emailConflictMessage: '此邮件已被注册。',
  passwordConfirm: '确认密码',
  name: '姓名',
  birth: '出生日期',
  nickname: '用户名',
  nicknameConflictMessage: '此昵称已被使用。',
  genderMale: '男',
  genderFemale: '女',
  genderOther: '其他',
  genderRNS: '不想公开',
  gender: '性别',
  country: '居住地区',
  noSearchResultMessage: '很抱歉，没有符合条件的结果。',
  loginUpperCase: '登录',
  signUpUpperCase: '注册会员',
  tempPassword: 'Temporary password.',
  verifyFanclub: '认证粉丝俱乐部',
  //new
  toiLoginPage: '前往登录页面',
  tempPasswordCompleteMessage:
    '向您的{邮箱}发送了临时密码\n如果没有收到邮件，请到垃圾邮件处查看',
  checkSpamMailMessage: '该邮箱未注册',
  notUserMailMessage: '请使用目前使用中的MAKESTAR帐号登录',
  fanclubVerifySuccessMessage: '{fanclub}认证已完成。',
  inputValidMakestarIdMessage: '您还没有MAKESTAR帐号吗',
  signupMessage: '忘了密码？',
  forgotPasswordMessage: '认证失败',
  reLoginMessage: '为了参与项目\n请您在MAKESTAR再次登录',
  tempPasswordLoginMessage: '请通过临时密码登录。',
  requestErrorMessage: '请求中发生错误\n请再次尝试',
  invalidValueMessage: '请准确输入',
  allFieldIsRequiredMessage: '所有选项都必须填写',
  reLoginForFanclubAuthMessage: '请登录并完成官咖会员认证',
  termsConfirm: '确认条款',
  allAgreeTerms: '同意全部条款',
  tempPasswordSend: '发送临时密码',
  toMakestar: '点击去MAKESTAR主页',
  toProject: '点击去KNK项目',
};

export default zh;
