import privacy from './term/privacy';
import terms from './term/terms';
import { fanclub } from '../../modules/Request/Uri/uri';

const ko: Record<string, string> = {
  ...terms,
  ...privacy,
  emailInvalidMessage: '올바른 이메일 형식이 아닙니다.',
  signupCompleteCongratulationsMessage:
    '{ name }님 스타메이커가 되신 것을 축하합니다.',
  signupComplete: '가입 완료',
  login: '로그인',
  required: '필수',
  termsAllMessage:
    '약관에 동의하셔야 메이크스타 서비스를 정상적으로 이용하실 수 있습니다.',
  acceptTerms: '약관 동의',
  findPasswordMessageOne: '가입하신 메일주소를 입력해 주세요.',
  findPasswordMessageTwo: '해당 이메일로 임시 비빌번호가 발송됩니다.',
  email: '이메일',
  signUp: '회원가입',
  loginFailMessage: '이메일 또는 비밀번호를 다시 확인하세요.',
  password: '비밀번호',
  findPassword: '비밀번호 찾기',
  failureSuccessMessage:
    '인증 메일의 유효기간이 만료되었거나, 올바르지 않은 주소 입니다.',
  tempSuccessMessage1: '임시 비밀번호로 변경이 완료되었습니다.',
  tempSuccessMessage2: '임시 비밀번호로 로그인 후 비밀번호를 변경해 주세요.',
  DOBInvalidMessage: '잘못 된 날짜입니다.',
  DOBAgeRestrictionMessage: '14세 미만은 서비스에 가입할 수 없습니다.',
  passwordInvalidMessage: '비밀번호는 6~20자까지 입력해주세요.',
  passwordConfirmNotMatchMessage: '비밀번호와 불일치합니다. 다시 입력해주세요.',
  nameInvalidMessage: '이름은 2자 이상 입력해주세요.',
  nicknameInvalidMessage: '닉네임은 3자 이상 입력해주세요.',
  emailConflictMessage: '이미 사용중인 이메일입니다.',
  passwordConfirm: '비밀번호 확인',
  name: '이름',
  birth: '생년월일',
  nickname: '닉네임',
  nicknameConflictMessage: '이미 사용중인 닉네임입니다.',
  genderMale: '남성',
  genderFemale: '여성',
  genderOther: '그외',
  genderRNS: '밝히고 싶지 않습니다.',
  gender: '성별',
  country: '거주국가',
  noSearchResultMessage: '검색 결과가 없습니다.',
  loginUpperCase: '로그인',
  signUpUpperCase: '회원가입',
  tempPassword: '임시 비밀번호 발급',
  verifyFanclub: '팬클럽 인증하기',

  // new
  toiLoginPage: '로그인 페이지로',
  tempPasswordCompleteMessage: '{email}으로 임시 비밀번호를 발송하였습니다.',
  checkSpamMailMessage: '도착하지 않은 경우 스팸함을 확인해주세요.',
  notUserMailMessage: '가입된 이메일이 아닙니다.',
  fanclubVerifySuccessMessage: '{fanclub} 인증이 완료되었습니다.',
  inputValidMakestarIdMessage:
    '기존에 사용중인 메이크스타 아이디로 로그인해주세요.',
  signupMessage: '아직 메이크스타 계정이 없으신가요?',
  forgotPasswordMessage: '비밀번호를 잊으셨나요?',
  tempPasswordLoginMessage: '임시 비밀번호로 로그인해주세요.',
  failureMessage: '인증에 실패하였습니다.',
  reLoginForFanclubAuthMessage: '로그인하여 팬클럽 인증을 완료해주세요.',
  reLoginMessage:
    '프로젝트에 참여하기 위해서,\n메이크스타에서 다시 로그인해주세요.',
  requestErrorMessage: '요청 중 오류가 발생하였습니다.\n다시 시도해주세요.',
  invalidValueMessage: '정확하게 입력해주세요.',
  allFieldIsRequiredMessage: '모든 항목은 필수입니다.',
  termsConfirm: '약관 확인',
  allAgreeTerms: '전체 약관 동의',
  tempPasswordSend: '임시 비밀번호 보내기',
  toMakestar: '메이크스타 메인으로 가기',
  toProject: 'KNK 프로젝트로 가기',
};

export default ko;
