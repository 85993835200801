import { makestar, fanclub } from './domain';
import * as uri from './uri';

export { makestar };
export const warmup = `${makestar}${uri.warmup}`;
export const login = `${makestar}${uri.login}`;
export const findPassword = `${makestar}${uri.findPassword}`;
export const checkEmail = `${makestar}${uri.checkEmail}`;
export const checkNickname = `${makestar}${uri.checkNickname}`;
export const signup = `${makestar}${uri.signup}`;
export const countries = `${makestar}${uri.countries}`;
export const userCheck = `${makestar}${uri.userCheck}`;
//fanclub
export const verifyFanclub = `${fanclub}${uri.fanclub}`;
