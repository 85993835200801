import React, { SFC, ReactNode } from 'react';

import styles from './Message.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface Props {
  children: ReactNode;
}

const Message: SFC<Props> = ({ children }) => {
  return <div className={cx('wrapper')}>{children}</div>;
};

export default Message;
