import React, { FC, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../reducers';
import { bindActionCreators } from 'redux';

import {
  login,
  makestar,
  postRequest,
  putRequest,
} from '../../modules/Request';

//Actions
import {
  actionCreators as infoActions,
  SetLocalePayload,
  SetFanclubPayload,
} from '../../actions/info';
import InputText from '../../components/InputText/InputText';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Record } from 'immutable';
import { verifyFanclub } from '../../modules/Request';

//Styles
import classNames from 'classnames/bind';
import styles from './LoginContainer.module.scss';
import { useIntl } from 'react-intl';

const cx = classNames.bind(styles);

interface Props {
  locale: SetLocalePayload;
  InfoActions: typeof infoActions;
  redirect: string;
  fanclub?: Record<SetFanclubPayload>;
}

const LoginContainer: FC<Props> = ({ fanclub }) => {
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();

  const hash = new URLSearchParams(location.search).get('hash');

  if (!hash) {
    window.location.href = makestar;
  }

  const [isFailed, setIsFailed] = useState<boolean>(false);
  //const [autoLogin, setAutoLogin] = useState<boolean>(false);

  const formRef = useRef<HTMLFormElement>(null);

  const onFocus = (): void => {
    setIsFailed(false);
  };

  /*const onChangeAutoLogin = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked } = e.target;
    setAutoLogin(checked);
  };*/

  const onLogin = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { current } = formRef;
    if (current) {
      const formData = new FormData(current);
      if (hash) formData.append('hash', hash);

      e.preventDefault();
      postRequest(
        login,
        formData,
        {},
        response => {
          const {
            content: { idx },
          } = response.data;
          putRequest(
            verifyFanclub,
            { userId: idx },
            {
              params: { hash: hash },
            },
            () => {
              history.push(`/result/fanclub${location.search}`);
            },
            () => {
              history.push(`/result/error`);
            },
          );
        },
        error => {
          setIsFailed(true);
        },
      );
    }
  };

  return (
    <div className={cx('wrapper')}>
      <div className={cx('title')}>
        <span>{intl.formatMessage({ id: 'verifyFanclub' })}</span>
        <div className={cx('fan-club-info')}>
          {fanclub && <span>{`${fanclub.get('fanclub').name}`}</span>}
        </div>
        <span className={cx('warning')}>
          {intl.formatMessage({ id: 'inputValidMakestarIdMessage' })}
        </span>
      </div>
      <form className={cx('form')} ref={formRef} action={login} method={'post'}>
        <InputText
          onFocus={onFocus}
          type="email"
          placeholder={intl.formatMessage({ id: 'email' })}
          name={'email'}
          defaultValue={fanclub ? fanclub.get('email') : ''}
          forcedStatus={
            isFailed
              ? {
                  status: 'invalid',
                }
              : undefined
          }
        />
        <InputText
          type="password"
          placeholder={intl.formatMessage({ id: 'password' })}
          name={'passwd'}
          forcedStatus={
            isFailed
              ? {
                  status: 'invalid',
                  message: intl.formatMessage({ id: 'loginFailMessage' }),
                }
              : undefined
          }
        />
        <Link to={`/password${location.search}`}>
          {intl.formatMessage({ id: 'forgotPasswordMessage' })}
        </Link>
        <button className={cx('confirm')} onClick={onLogin}>
          {intl.formatMessage({ id: 'loginUpperCase' })}
        </button>
        {/*
        <div className={cx('checkbox')}>
          <input
            type="checkbox"
            name={'autoLogin'}
            //onChange={onChangeAutoLogin}
          />
          <input
            type="checkbox"
            name={'redirect'}
            checked
            style={{ visibility: 'hidden', width: 0 }}
          />
          <span>{'로그인 유지'}</span>
        </div>
          */}
      </form>
      <div className={cx('signup')}>
        {intl.formatMessage({ id: 'signupMessage' })}
        <Link to={`/signup${location.search}`}>
          {intl.formatMessage({ id: 'signUpUpperCase' })}
        </Link>
      </div>
    </div>
  );
};

export default connect(
  ({ info }: StoreState) => ({
    redirect: info.get('redirect'),
    fanclub: info.get('fanclub'),
  }),
  dispatch => ({
    InfoActions: bindActionCreators(infoActions, dispatch),
  }),
)(LoginContainer);
