import { createAction } from 'redux-actions';

export const SET_LOCALE = 'info/SET_LOCALE';
export const SET_REDIRECT = 'info/SET_REDIRECT';
export const SET_FANCLUB = 'info/SET_FANCLUB';

export type SetLocalePayload = 'ko' | 'ja' | 'zh';
export type SetRedirectPayload = string;
export type SetFanclubPayload = {
  email: string;
  name: string;
  projectUrl: string;
  birthday: string;
  fanclub: {
    id: string;
    name: string;
  };
};

export const actionCreators = {
  setLocale: createAction<SetLocalePayload | string>(SET_LOCALE),
  setRedirect: createAction<SetRedirectPayload>(SET_REDIRECT),
  setFanclub: createAction<SetFanclubPayload>(SET_FANCLUB),
};
