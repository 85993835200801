import React, {
  FC,
  useState,
  ReactNode,
  CSSProperties,
  useEffect,
  isValidElement,
} from 'react';

// Style
import classNames from 'classnames/bind';
import styles from './InputText.module.scss';
import { useIntl } from 'react-intl';

const cx = classNames.bind(styles);

interface ValidateResult {
  result: ValidType;
  afterValue?: string;
  message?: string | ReactNode;
}

interface ForcedStatus {
  status: ValidType;
  message?: string;
}

export type EventResultType = ValidateResult | void;
export type ValidType = 'valid' | 'normal' | 'invalid';

interface Props {
  type?: 'text' | 'password' | 'number' | 'email';
  name?: string;
  defaultValue?: string;
  placeholder?: string;
  required?: boolean;
  readOnly?: boolean;
  disable?: boolean;
  forcedStatus?: ForcedStatus;
  className?: string;
  style?: CSSProperties;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): EventResultType;
  onFocus?(e: React.FocusEvent<HTMLInputElement>): EventResultType;
  onBlur?(e: React.FocusEvent<HTMLInputElement>): EventResultType;
  onKeyPress?(e: React.KeyboardEvent<HTMLInputElement>): void;
}

const InputText: FC<Props> = ({
  type = 'text',
  name,
  defaultValue = '',
  placeholder,
  required,
  disable = false,
  readOnly = false,
  forcedStatus,
  style,
  className,
  onChange: onChangeFromParent,
  onFocus: onFocusFromParent,
  onBlur: onBlurFromParent,
  onKeyPress: onKeyPressFromParent,
}) => {
  const [message, setMessage] = useState<ReactNode | string>(null);
  const [value, setValue] = useState<string>(defaultValue);
  const [valid, setValid] = useState<ValidType>(readOnly ? 'valid' : 'normal');

  const intl = useIntl();
  useEffect(() => {
    if (type === 'email') {
      if (value !== '') {
        const isValid =
          value.match(
            /^([0-9a-zA-Z]?[-_.]?)*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,4}/i,
          ) !== null;

        setMessage(
          isValid ? null : intl.formatMessage({ id: 'emailInvalidMessage' }),
        );
        setValid(isValid ? 'valid' : 'invalid');
      } else {
        setValid('normal');
        setMessage(null);
      }
    }
  }, [value]);

  useEffect(() => {
    if (forcedStatus) {
      const { status, message } = forcedStatus;

      setValid(status);
      if (message) {
        setMessage(message);
      }
    } else {
      setValid('normal');
      setMessage(null);
    }
  }, [forcedStatus]);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
      setValid('valid');
    }
  }, [defaultValue]);

  const eventCallback = (callBackResult: ValidateResult | string): void => {
    if (typeof callBackResult === 'string') {
      setValue(callBackResult);
    } else {
      const { result, message, afterValue } = callBackResult;

      setValid(result);
      setMessage(message);
      if (afterValue) setValue(afterValue);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setValue(value);

    if (onChangeFromParent) {
      const result = onChangeFromParent(e);
      if (result) eventCallback(result);
    }
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    if (onBlurFromParent) {
      const result = onBlurFromParent(e);
      if (result) eventCallback(result);
    }
  };

  const onFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
    if (onFocusFromParent) {
      const result = onFocusFromParent(e);
      if (result) eventCallback(result);
    }
  };

  const messageElement = isValidElement(message) ? (
    message
  ) : (
    <span className={cx('message')}>{message}</span>
  );

  return (
    <div
      className={cx(
        'wrapper',
        { 'wrapper-invalid': valid === 'invalid' },
        className,
      )}
      style={style}
    >
      <input
        className={cx('input', {
          valid: valid === 'valid',
          invalid: valid === 'invalid', //&& validationFunc,
          disabled: readOnly || disable,
        })}
        name={name}
        type={type}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyPress={onKeyPressFromParent}
        value={value}
        readOnly={readOnly}
        disabled={disable}
      />
      {message && <>{messageElement}</>}
    </div>
  );
};

export default InputText;
