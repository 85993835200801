import React, { FC, useState, ReactNode, useRef } from 'react';
import { List } from 'immutable';
//Styles
import classNames from 'classnames/bind';
import style from './Terms.module.scss';
//Components
import Term from './Term';
import { useIntl } from 'react-intl';

const cx = classNames.bind(style);

interface Props {
  children: ReactNode;
  titles: string[];
  onChange?(isAllChecked: boolean): void;
}

type CheckedState = List<boolean>;

// 해당 컴포넌트는 list형 state의 불변성 유지를 위해 immutable을 사용합니다.
const TermsWrapper: FC<Props> = ({
  children,
  titles,
  onChange: onChangeFromParent,
}) => {
  const intl = useIntl();
  const [checkeds, setCheckeds] = useState<CheckedState>(
    // children의 수 만큼 체크 항목이 있을거란 가정. 필요시, 마운트 다음 시점에 세팅 고려중..
    List([...Array(Array.isArray(children) ? children.length : 1).fill(false)]),
  );

  const checkRef = useRef<HTMLInputElement>(null);

  // list 넘겨주면 해당 list로 사용, 없으면 현재 checkeds로 체크
  const isCheckedAll = (list?: CheckedState): boolean => {
    let result = true;
    const checkedList = list || checkeds;

    checkedList.forEach(checked => {
      // 루프 중, 체크가 하나라도 되어있지 않으면 루프종료.
      if (!checked) {
        result = false;
        return;
      }
    });

    return result;
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked } = e.target;
    if (onChangeFromParent) onChangeFromParent(checked);
    setCheckeds(checkeds.map(_ => checked));
  };

  const onChecked = (index: number, checked: boolean): void => {
    const { current } = checkRef;
    const list = checkeds.set(index, checked);
    // ref 체크 및 전체 동의 체크 변경.
    if (current && current.checked !== isCheckedAll(list)) current.click();

    setCheckeds(list);
  };

  const terms = Array.isArray(children)
    ? children.map((element, i) => {
        return (
          <Term
            title={titles[i]}
            key={i}
            checked={checkeds.get(i)}
            index={i}
            onChecked={onChecked}
          >
            {element}
          </Term>
        );
      })
    : children;

  return (
    <div className={cx('wrapper')}>
      <div className={cx('check-all')}>
        <input type={'checkbox'} onChange={onChange} ref={checkRef} />
        {intl.formatMessage({ id: 'allAgreeTerms' })}
      </div>
      {terms}
      <span className={cx('terms-message')}>
        {intl.formatMessage({ id: 'termsAllMessage' })}
      </span>
    </div>
  );
};

export default TermsWrapper;
