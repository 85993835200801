// pre
// export const makestar = 'https://pre.m2076.co.kr';
// export const fanclub = 'https://fanclub.makestarapis.com/dev';

// staging
export const makestar = 'https://m2076.co.kr';
export const fanclub = 'https://fanclub.makestarapis.com/staging';

// live
// export const makestar = 'https://www.makestar.co';
// export const fanclub = 'https://fanclub.makestarapis.com/v1';
