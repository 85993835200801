const terms = {
  'terms.title': 'Terms of use',
  'terms.html': '',
  'terms.markdown': `
  #### Article 1 General Rules

  ##### Section 1 Purpose
  The purpose of these terms of use is to define the company and user’s rights, responsibilities, and the user’s service use procedure in regards to using internet related service (the “Service”) such as crowdfunding provided by internet website, makestar.co (“Site”), operated by Makestar Co. (“Company”).\n

  ##### Section 2 Definition
  The definitions of terms used in these Terms of Use are as following.\n
  1. ”Company” means Makestar Co.
  1. ”Site” refers to makestar.co, the website operated by the “Company.”
  1. ”Service” means the various service provided by the “Company” which can be used by the user 1. regardless of what kind of device is used (including various wire-wireless devices such as PC, TV, 1. portable device).
  1. ”Crowdfunding” is publicly raising pledges or investment for project opened by project creator from unspecified number of users/members through the service provided by the Company.
  1. ”User” is member and non-member who connect to the site and receive service provided by the Company according to the terms.
  1. ”Member” is individual or corporate customer who connects to the site, enter into service contract according to these terms, and use the service provided by the Company.
  1. ”Non-member” is individual or corporate customer who use limited service provided by the Company without registering as a member.
  1. ”Post” means text, photos, video, and other files and links in the informational form of signs•text•sound•audio•video uploaded/posted by User in using the Service
  1. ”Project” is the service opened in the space provided by the Company in order to receive contribution or investment from other members.
  1. ”Project creator” means “member” who opens a reward-based project or investment project through the service provided by the Company.
  1. ”Pledger” means “member” who pledges to the Project creator by choosing a Project which is open through service provided by the Company and pledge amount.
  1. ”Reward” is goods promised by the Project creator to be provided to the Pledger after completion of the project.
  1. ”Investor” means member who chooses a Project which is open through services provided by the Company, and chooses an amount to invest in the Project creator.
  1. Besides what has been specified in each rule above, the definition of words used in these terms will follow the transaction customs and related laws.\n

  ##### Section 3 Display of terms and revision
  1. ”Company” will display the contents of terms on the first screen of website so the user can easily check. (It will be posted on the website.)
  1. ”Company” can revise the terms within the range which does not violate the law related to financial investment business, laws related to consumer protection in electronic transactions, laws regarding regulation of terms, laws regarding door-to-door sales, and consumer protection laws.
  1. In case the Company revises terms, the revision will be made, effective date will be determined, then they will be posted on the website on a designated date, at least 7 days before the effective date. Also, for existing members, besides the announcement on the website, they will be notified via the email that they entered at registration as well.
  1. When the Company makes revision in terms and notify the users following rule 3 above, if the user does not express intention to disagree within 7 days after the effective date, it will be assumed that he/she agreed to the revised terms.
  1. In case a Member does not agree to the revised Terms, he/she may apply for cancellation of Membership to the Company.\n

  ##### Section 4 Not Specified By the Terms
  What has not been specified in these Terms or the interpretation will follow the related laws or the custom of trade.\n

  ##### Section 5 The nature and purpose of Service
  1. The Service provided by the company is online platform that helps the trade/transaction between Members to happen smoothly and the company does not lend money to or borrow money from the Member.
  1. The responsibility related to the transaction made between Members, shall be taken by the Members themselves. However, the documents needed in order to resolve the problems that occurred between the Members, can be provided within the range permitted by related laws.\n
  &nbsp;\n
#### Article 2 Membership registration, etc.

  ##### Section 6 Membership Registration
  1. Service contract is entered into when a person who desires to become a Member (Membership Applicant), agrees to Terms, applies for membership, and the Company approves.
  1. When “Membership Applicant” reads Terms and privacy policy of the Company, and takes action such as pressing “Agree” button or checking next to “OK,” it will be assumed that he/she has agreed to the Terms.
  1. A person who did not register with his/her real name cannot claim the rights as a Member. The account made through someone else’s name will be deleted and he/she may be punished according to related laws.
  1. It is principle rule that one Member has only one account and if a Member has more than one account, his/her account(s) will be deleted, membership cancelled, or he/she may be banned from applying for Membership again. Also, the Member who has more than one account cannot claim any rights to those other accounts.
  1. Upon sign up, the user will be classified as an 'Unauthenticated Member'. The user must authenticated their account by email or other means to be upgraded to a 'Full Member' status in order to make full use of the services on the "Site".
  1. Should the account retain the 'Unauthenticated Member' status for a period of 30 days, the "Company" retains the right to terminate the account without further notice and delete all data associated with it.\n

  ##### Section 7 The Approval and Limitation of Application to Use Service
  1. As long as there is no special reason not to, the Company will approve the Membership Applicant’s application. However, in following cases, it may be denied or even after it is approved, the Company may cancel the Membership at any time.\n
      A. When someone applies for Membership with a name that is not his/her real name, or by using someone else’s personal information\n
      B. When the Membership Applicant has lost eligibility to apply for Membership following these Terms before, but the Company will give an exception if he/she has been given approval for re-applying for the Membership\n
      C. When the User entered false information or did not enter the information requested by the Company\n
      D. When the User applied for Membership with intention to harm the public peace, order, and traditional customs.\n
      E. When he/she intends to use the Service for ill purposes\n
      F. When he/she threatens the order within electronic transactions by disrupting someone else from using the Service, or stealing someone else’s information\n
      G. When a User has breached the terms of service or does not adhere to the Company's regulations.\n
      H. When a User who is in the competitive position with the Service applies\n
      I. When the approval is impossible due to attributable reasons or in case he/she applied violating other specified various terms\n
      J. The account applicant is under 13 years of age\n
      K. A non-permanent, temporary email service was used at sign up\n
  1. The Company may limit or postpone the application’s approval in following cases\n
      A. When there is no room in the Company’s service related system\n
      B. When there’s technical problem in providing service\n
      C. When the applicant is underage\n
      D. When it is acknowledged that there is a reason that comes close to the other cases above\n
  1. The Company may divide Members into different levels and according those levels, usage time, usage numbers, and service content, etc. may differ.\n

  ##### Section 8 Duty to protect personal information
  The Company shall try best to protect Member’s personal information according to related laws such as information network law. About the protection and use of personal information, related laws and the Company’s personal information handling regulations will apply. However, for websites outside the Company’s official website, the Company’s personal information handling regulations do not apply.\n

  ##### Section 9 Member’s account and password management
  1. The responsibility of managing Member’s account and password belongs to the Member him/herself, and he/she shall not let a third party use it.
  1. The Member may not change the account given by the Company, and may not have more than one account. In case he/she desires to change the account for unavoidable reasons, he/she will need to cancel the membership and register again.
  1. In following cases, the Company may request change of account to the Member, and in this case, the Member has to cancel Membership and register again.\n
      A. In case that the User’s account is registered with phone number or identification number, etc. so the invasion of privacy is worried\n
      B. In case the User’s account causes repulsion from others or it is against traditional customs\n
  1. The Company may limit an account’s use, when there’s risk of personal information to leak or if the account has possibility of getting mistaken as an account that belongs to the Company or Company’s administrator.
  1. In case a Member finds out his/her account and/or password has been used by someone else, the Member shall notify the Company and follow their instructions.
  1. In case of Clause 5 above, if the Member does not notify the Company or has notified the Company but did not follow the instructions, the Company is not responsible for any loss.
  1. The account of Service provided by the Company may be linked to account on a different site operated by the Company under the Member’s agreement.
  1. Parts regarding other member information management or change shall follow what is decided by directions for different services.\n

  ##### Section 10 Changing member information
  1. Member may view or modify his/her personal information through personal information management screen. However, he/she cannot modify real name, account, and date of birth, which are needed for service management.
  1. When any of information entered at the time of registration changes, the Member shall notify the company of the change of information by updating the information online or sending an email, etc.
  1. The Company is not responsible for any loss occurred from not notifying the Company of change of information from 2).\n

  ##### Section 11 Leaving the site, etc.
  1. Member may leave and request cancellation of use contract at any time through cancellation menu within the Site, and the Company has to process it immediately according to the related laws.
  1. When a Member leaves site and cancels use contract – except in case the Company keeps Member information according to related laws and personal information handling regulations – all of member’s information is deleted upon cancellation.\n
  &nbsp;\n
#### Article 3 Duty and responsibility

  ##### Section 12 Company’s duty
  1. The Company does not take any action that is not allowed by these Terms or any action against traditional customs and will do best to provide continuous and stable service.
  1. The company will make a security system to protect personal information protection so the Member can use Service safely, and follow personal information handling regulations.
  1. In case there is an error in electronic transaction, the Company shall produce and keep a record that this can be checked
  1. In providing electronic financial transaction service, the user’s personal information, bank account information, account information, and contents of electronic financial transaction shall not be provided, leaked, or used by third party, without following related laws, or acquiring the user’s agreement.
  1. The Company will process immediately if the opinion or complaint from Member recognized as reasonable. However, when it’s impossible to process it immediately, the reason and the processing period will be notified
  1. The Company does not send email with commercial purpose unless the Member has agreed to receive it.
  1. When there is membership cancellation request, the Company will process the request immediately after member verification.\n

  ##### Section 13 Company’s responsibility
  1. In case an accident occurs when the Company is the issuer of verification tool or user/management and forging or falsifying information happens, the user’s loss in the process of transaction or transaction order will be compensated by the Company
  1. While the previous rule is still valid, in the following cases, the Company is not responsible for loss compensation for the User.\n
      A. In case the Company is not the issuing agent of verification method, and the loss occurred to the User from an accident from forging or falsification of the verification method\n
      B. In case the User authorize a third party to lend/transfer or use the verification method, provide it as a collateral purpose, or the User giveaway, expose, or neglect the verification method even though he/she knew or could find out that the third party could use it for electronic financial transaction without authority\n
      C. In case loss occurred to corporation (Small businesses stated in the Section 2, Clause 2 of “Standard Small and Medium Business Law,” are exceptions) user, in which the Company has done enough duty of care which is reasonably requested such as setting up a security procedure to prevent accidents, and thoroughly complying with them\n
      D. In case the service cannot be used because of problem in public communication line which is not the Company’s area of management\n
      E. In other cases with problem in communication service in which there is no reason for fault for the Company\n
  1. Even though there was a transaction request from the User, the processing was impossible or delayed because of blackout, fire, communication line problem, and other uncontrollable reasons but the reason why it was impossible or delayed was notified by the Company(the cases in which the financial institution or payment method issuing agent/telemarketing business has notified the User are included), the Company is not obligated to the User
  1. The Company shall notify the User of suspension schedule and suspension reason beforehand through company’s website in case the providing of service is temporarily suspended because of reasons such as need for repair, inspection, or replacement of communication facilities such as computers, have occurred.\n

  ##### Section 14 Duty of Member
  1. Members shall not do the following.\n
      A. Entering false information at the time of membership registration or when updating information\n
      B. Using other member’s account, password, etc.\n
      C. Selling the account to someone else\n
      D. Pretending to be the Company’s operating staff, employee, or related personnel\n
      E. Changing the company’s client program without being given special authority from the Company, hack the company’s server, or change the part or whole of the website or posted information\n
      F. Harm service or disrupt on purpose\n
      G. Using the information acquired through this service for other purposes besides using the service, such as processing, selling, duplicating, transmitting, distributing, using the information for publishing or broadcasting, or providing it to the third party\n
      H. Invading the intellectual property right of the Company or the third party, or sending content that invades these rights to transmit, upload, email, or distribute it to others through other methods\n
      I. Sending, uploading, emailing information, sentences, shapes, sound, or videos that violate public order and traditional customs\n
      J. Sending, uploading, emailing contents that could violate someone else’s honor or privacy because it is insulting or it is personal information\n
      K. Harassing or threatening other member, or continuously giving pain or inconvenience to specific user\n
      L. Collecting or saving other member’s personal information without the company’s approval\n
      M. Any action that is objectively considered to be associated with crime\n
      N. Any action that disrupts company’s tasks or that damages the reputation of the company\n
      O. Providing the right to use service or other position from the use contract by handing over, giving away, or providing as collateral without expression of consent from the Company\n
      P. Any action that violates the various rules or use conditions given by the company including these Terms\n
      Q. Any action that violates other laws\n
  2. Member shall follow related laws, these Terms, other various rules made by the Company, service use instruction, announcements and others that are notified by the Company
  3. Member cannot have sales activities by suing the Service without approval from the Company beforehand. Any transaction which occurred between members or through a third party service is unrelated with the Company and the Company has no responsibility regarding it.
  4. In case a Member has caused loss to the Company by violating rule 3 above, the Member shall compensate for that loss to the Company.\n

  ##### Section 15 Notice for Members
  1. When the Company makes a notice to the Member, they can use email address or message within the Service unless there is a separate rule about it in these Terms.
  1. In case a Member expressly refused receiving of emails, the Company may send an email in case they need to fulfill the responsibility stated in the related laws or these Terms.
  1. In case there is a notice for all the Members, the Company may change from the rule 1 by posting the notice on the bulletin board of website for over 7 days.\n
  &nbsp;\n
#### Article 4 The use of the service

  ##### Section 16 Provision and change of Service
  1. The Company may provide reward type, investment type, loan type, and combination type crowdfunding service to Members. At this time, in regards to the design of service, laws related to capital market and financial investment business shall be followed.
  1. The Company provides all services such as contents provided by the Company and events in progress to the Member. However, there may be level difference in service according to the level of Members stated in the Article 6 Rule 3.
  1. When there is a service change, the Company shall notify the Member of the service which will be changed and the provision date through the methods specified in the Article 15.\n

  ##### Section 17 Management of uploaded/posted material
  1. The Company shall try to prevent the material uploaded/posted by Member from falsification, damage, and deletion
  1. The Company may reject registering, or delete, move, or change the material that includes the following contents\n
      A. Content that severely insults or damages reputation of another Member or a third party\n
      B. Content that violates public order and traditional custom or which includes link through which someone can connect to such site\n
      C. Explicit content is uploaded/posted or link to site with explicit contents has been added\n
      D. Content that encourages piracy or hacking\n
      E. When an advertisement with commercial purpose over when the posting is a spam\n
      F. Content that is objectively considered to be associated with crime\n
      G. Content that violates the copyright or other rights of the Company, other Member, or a third party\n
      H. Content that does not suit the characteristic of the board that User desires to register on\n
      I. When commercial software or a crack file has been uploaded/posted\n
      J. When the purpose of uploading is not met by uploading same content several times\n
      K. Content that violates other company’s various rules or related laws\n
  1. The Company may set detailed use regulations for separate services, and Members shall upload contents (forwarding between members included) according to these regulations.
  1. The Company may delete the content registered by a Member when it is different from the direction of service.\n

  ##### Section 18 Where the right of uploaded/posted content belongs
  1. The copyright and intellectual property right of all the uploaded/posted content by the Company belongs to the Company
  1. The copyright of contents that are uploaded/posted by a Member within service screen belongs to the Member who uploaded/posted it
  1. About the content that the Member has the copyright, the company may use them (It includes providing Meta data to external website that is operated by other company that is affiliated with the Company) and regardless of whether it is for commercial purpose or not, it can be used freely within the website operated by the Company. In case the Member agrees to the Terms, it will be considered that intellectual property rights, etc. need for the uses stated above has been given to the Company and it is valid even after the Member cancels the membership.
  1. In case the Company desires to use the contents uploaded/posted by a Member besides the ways stated in the rule 3 above, they shall get Member’s agreement beforehand through methods such as phone call, fax, electronic mail, etc.

  ##### Section 19 Posting Advertisement
  1. The Company may up load on website or send the contents related to operating the service via email, message, SMS, etc. However, when sending email, message, SMS, etc., the Company shall check if members have agreed to receiving email, message, SMS, etc., and only send to members who have agreed.
  1. The user using the ad posted within the service or communicating or having transaction through engaging in the advertiser’s promotion activity, etc., is completely a matter between the user and the advertiser. Even if a problem occurs between them, the user and advertiser themselves shall resolve it, and the Company does not take any responsibility related to it.

  ##### Section 20 Service use time
  1. The Company starts service at the point the Member’s use contract has been approved. However, for some services, the service begins on the specified date.
  1. The Company may divide the service into certain ranges, and may specify the time it may be used depending on the range. In this case, this information will be announced to the members.
  1. This service is provided 24 hours a day, 365 days a year. However, when there is task or technical problem, or technical backup and site’s improvement is needed, the service may be suspended momentarily. In this case, the Company will announce it beforehand or afterward.\n

  ##### Section 21 Service use limit or cancellation of Membership
  In the following cases, the Company may limit or suspend Member’s service use or cancel his/her membership.\n
  1. When Member disrupts the Company’s operation of service on purpose or by mistake
  1. When Member violated the Member’s duty defined in the Section 12.
  1. When a member has more than one account
  1. When it’s inevitable due to regular checkup of communication facilities or system, or maintenance related reasons such as expansion and replacement, repair and checkup, failure, etc.
  1. When the communication service has been interrupted by the key telecommunications service provider set by Telecommunications Business Act
  1. When using the service is interrupted by state of national emergency, service facility problem, or service use overload
  1. When an account or nickname that violates traditional customs has been used
  1. When there is correction request from related administrative office or public institution
  1. When the service has not been used for over 3 months
  1. When using the board as file archive by uploading data without index file, etc.
  1. When content or data mentioned in the section 15, clause 2, has been uploaded or registered
  1. When someone else’s payment information has been stolen and used or a transaction happens through dishonest method\n

  ##### Section 22 Application of a project
  1. The opener of project shall choose the type of crowdfunding from sponsor type, investment type, loan type, or combination type, and also choose if they will be receiving the funds only when target amount is met or receiving the funds regardless of how much is raised then apply for opening a project. Member who wishes to open a project may apply to do so by following the form and procedure provided by the Company. At this time, the opener may enter a contract separate from the Company regarding the opening of a project.
  1. The submitted project will be evaluated to see if it is in accord with the following criteria and operation direction set by the Company and only the approved projects are posted on the website.\n
      A. Whether the regulation and procedure for opening a project has been followed\n
      B. Whether the project opening form has been filled out\n
      C. whether the files requested have been provided\n
      D. Whether it violates related laws, public order, or traditional customs\n
      E. Whether there is a purpose to damage someone else’s reputation or give disadvantage to someone else\n
      F. whether it suits various project evaluation criteria set by the Company\n
      G. Whether other requests necessary for opening a project has been met\n
  1. There shall be no false information, omission of entry, or spelling error in the contents of project that Member applied to be opened, and the Member is responsible for any disadvantage that occurs from not following this term.
  1. All the rights and responsibilities for project contents and files made and provided by Member, belong to the Member and when it is considered that the contents of project that Member registered fit any of the following, it may be deleted without notice beforehand and the Company does not take any responsibility regarding it. Also depending on the case, the Company may request to the Member who registered the project for explanation.\n
      A. When is confirmed that the member information or project information provided by the Member was false\n
      B. When it is about invading a third party’s intellectual property rights or other rights\n
      C. When contents not approved by the Company beforehand, has been posted\n
      D. When a Member posts contents that are explicit, harassing, cursing, damaging reputation, or illegally revealing personal information, or links a website related to such purpose\n
      E. When a Member revised or manipulated project information illegally\n
      F. When it is considered that related laws have been violated\n
  1. Project approved to be registered can be posted on the website or service related website even after the application accepting period is over.\n

  ##### Section 23 Revision of Project
  1. Before the project is approved by the project, the revision can only made after requesting the revision to the Company and within the range that is approved by the Company.
  1. After the project is approved, part of project page cannot be changed, cancelled, or deleted any more. In case the project applicant Member revises, changes, or deletes the part which is not allowed to be revised, Member who pledged to the project can take legal action such as filing for a claim for the damage/loss, and the Company is not responsible for any disadvantage caused by this.\n

  ##### Section 24 Project Cancellation
  1. Before the project is approved, the project may be cancelled freely, and it may be cancelled by making a request to the Company. However, the fee used until the point of cancellation shall be paid to the Company.
  1. After the project has been approved, the application to open project cannot be freely cancelled by the opener before the deadline of the project.
  1. The project cannot be cancelled after the project ends as a success. However, in case there is a special objective reason that the Company acknowledges, the cancellation is possible but a fee set by Section 33 below has to be provided.
  1. The revision of project will be notified through update, and Member can check it at any time.\n

  ##### Section 25 Payment Method
  Member may make payment within the website through methods set by the Company (credit card, real time account transfer, virtual account, etc.) and for accurate and secure payment processing, customer’s information is provided to a pay gate company in accordance with related laws.\n

  ##### Section 26 Cancellation and refund of payment
  1. Payment cancellation and refund regulations shall follow related laws such as law related to protection of consumer in electronic transaction, etc.
  1. According to nature of different services, the Company may set cancellation and refund regulations following separate terms or use conditions, and in this case, cancellation and refund regulations of separate terms and use conditions will be applied first.
  1. For other cancellation and refund related matter not specified in these Terms or use instruction of website, it will follow what has been set in the consumer loss compensation regulations.\n

  ##### Section 27 Refund in case of failure for project type in which funds are received only when target amount is reached
  1. In case the project type in which funds are received only when target amount is reached is withdrawn in the middle or it failed because it didn’t reach the goal amount that was applied for, all the pledge amount payment made within 10 days from the date that the (cancellation) reason occurred, will be processed for cancellation.
  1. The processing time of refund following an actual payment cancellation despite Clause 1 above, may differ depending on financial institution such as the card company.\n

  ##### Section 28 Cancellation and refund fee
  1. In case of cancellation just from simple change of mind, the fee that occurs in processing refunds shall be paid by the Member.
  1. Cancellation and refund fees will be processed differently depending on the payment method, and the way of charging for cancellation and refund fee will be following separated payment cancellation and refund regulations.\n

  ##### Section 29 Project success
  1. For the contribution collection result for project type in which the funds are received only when goal amount is met, it is a success if the raised amount is over the goal amount set by the Member based on the total pledge amount that is made at the time of project deadline set by the Member, and this goal amount is not met, the project is considered a failure.
  1. For project in which funds are received regardless of how much is raised, success and failure are not separately specified, and the amount raised will be passed on to the Project creator.
  1. For project’s final contribution amount, cancellation and revision application before the contributing period deadline can be reflected.
  1. In case the project’s contribution collection has failed, the Project creator cannot claim any right for the funds raised until that point, and according to the procedure previously notified, the Company will cancel all of amounts pledged by the pledgers.\n

  ##### Section 30 Passing on the funds
  1. Regards to the passing on of pledge amount that has been raised, the Company may make request to the Project creator for documents that can be proof of identification such as copy of identification, copy of bank book, copy of resident registration, and business license, etc., and besides these, documents that are requested have to be submitted to the Company in order for the contribution amount to be passed on normally.
  1. It is rule that the raised funds/contribution amount must be deposited to the Project creator’s account, and depositing to any other account can be authorized based on criteria set by the Company.
  1. The Company shall pay the amount excluding fees to the Project creator, and about the fee amount, they shall issue tax receipt. The Project creator shall honestly fulfill the duty of paying taxes about the amount received.
  1. The raised funds shall be deposited to the account requested by the opener within 15 days since the request for payment is made after the deadline of the project.
  1. The Company and Project creator must put all efforts into passing on the raised funds transparently, and it being used for purpose of the project.\n

  ##### Section 31 Use of contributions
  The Project creator shall use the funds received from the success of project only for executing and completing the promised project, and for expenses related to fulfillment and shipping of promised goods, and he/she will be completely responsible for any disadvantage occurred from not fulfilling them.\n

  ##### Section 32 Provision of rewards
  1. The Project creator has responsibility to fulfill the promise between a Member and another Member, or Member and a third party, and providing pledged goods option, and the said Member is responsible for disadvantage that occurred from not fulfilling them.
  1. The Project creator shall post the content about the goods which will be provided, quantity, provision schedule on the website for the contributors, and when goods cost collection is successful, he/she is responsible to fulfill them. If the Project creator does not fulfill what has been promised, it has been differently or partially fulfilled from the content posted on purpose, or when defective goods are provided, contribution amount will be withdrawn by the Company and said member is responsible for claim for loss compensation by the contributor.
  1. The Project creator has duty to honestly reply and respond to inquiries about the project, and goods that will be provided to the contributor.\n

  ##### Section 33 Fees
  1. Member’s website registration, and opening and pledging to a project through service, is free, and in case the project is a success, a fee will be charged to the Project creator.
  1. Different fee rates are applied for project type in which the funds are received only when the goal amount is met, and for project type in which the funds are received regardless of how much is raised.
  1. The Company and opener may enter a separate contract to decide on the fee rate.
  1. In case the Company wants to charge fee for service use for other services, the standard of assessment and other details on the website 7 days before it is enforced.\n
  
##### Section 34 Translation service
  THIS SERVICE MAY CONTAIN TRANSLATIONS POWERED BY GOOGLE. GOOGLE DISCLAIMS ALL WARRANTIES RELATED TO THE TRANSLATIONS, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF ACCURACY, RELIABILITY, AND ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT.\n
  &nbsp;\n
  #### Article 5 Others

  ##### Section 35 Limit of Responsibility
  1. In case the Company limits or suspends service, or cancels membership because of reasons stated in the Section 17, Clause 2, and Section 21, Clause 1, the Company does not have responsibility to compensate for Member’s loss from it.
  1. The Company is not responsible for loss occurred from the Member poorly filling out personal information or did not fulfill the revision responsibility stated in Section 9, Clause 2.
  1. The honesty, accuracy, and reliability of the information, data, facts, etc., that member posted on the website, are not guaranteed by the Company. Whether to make use of the posted material or not, depends on each Member’s responsibility and judgment, and the Company is not responsible at all for the results.
  1. The Company does not have responsibility to be involved in the dispute that occurred from the service between the members or between Member and a third party. The Company does not take responsibility for the loss of Member or a third party caused intentionally or negligently by a Member related using the Service.\n

  ##### Section 36 Loss Compensation
  In case a loss occurs to the Company because of the user violating the duties from these Terms, the user has to compensate for the Company’s loss.\n

  ##### Section 37 Applicable Law and the Competent Court
  1. The Company and Member shall make necessary efforts to amicably resolve dispute that occurred in regards to the Service.
  1. In case a lawsuit is filed due to the dispute despite the previous clause, it shall be resolved at the court that has jurisdiction where the Company is located.
  1. The dispute between the Company and Member will be handled in accordance with Korean laws.\n

  #### Additional Clause
  **These Terms of Use are effective starting on October 14, 2015.**
  #### Additional Clause
  **These Terms of Use are effective starting on October 20, 2016.**
  #### Additional Clause
  **These Terms of Use are effective starting on January 05, 2017.**
  #### Additional Clause
  **These Terms of Use are effective starting on August 01, 2017.**
  `,
};

export default terms;
