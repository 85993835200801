const terms = {
  'terms.title': '利用規約',
  'terms.html': '',
  'terms.markdown': `

  #### 第1章総則

  ##### 第1条 目的
  本利用規約は株式会社Ｍakestarが運営するmakestar.coを通じて提供されるクラウドファンディングまたインターネット関連サービスを利用することによって会社と利用者の権利と義務、責任事項また会員のサービス利用に関する注意事項やガイドラインを定めるものです。\n

  ##### 第2条 定義
  1. 本利用規約において使用する用語の定義は以下のようです。
  1. ‘当社‘とは、株式会社Ｍakestarのことを言います。
  1. ‘サイト‘とは、当社が運営するクラウドファンディングサイトmakestar.coのことを言います。
  1. ‘本サービス‘とは、PC，TV，携帯電話など(各種の有無線装置を含める)と関係なく利用できる‘Makestar‘が提供するサービスのことを言います。
  1. ‘クラウドファンディング‘とは、会社が提供するサービスを通じて不特定多数の会員からプロジェクト実行者が開設したプロジェクトに対する金額または投資金を公募することを意味します。
  1. ‘利用者‘とは、サイトに接続しこの利用規約によって会社が提供するサービスを利用する会員また、非会員のことを言います。
  1. ‘会員‘とは、当社のサービスに接続してこの利用規約によって会社と利用契約を締結して会社が提供するサービスを利用する個人または法人顧客のことを言います。
  1. ‘非会員‘とは、会員登録をしないで会社が提供する制限されたサービスを利用する個人または法人顧客のことを言います。
  1. ‘掲示物‘とは、‘利用者‘がサービスを利用するに当たってサービス上に掲示した符号・文字・音声・音響・画像・動画などの情報の形の文や写真，動画，各種ファイルとリンクなどを意味します。
  1. ‘プロジェクト‘とは、プロジェクト実行者が他の会員から参加または投資を受けるため当社が提供する空間に開設したサービスのことを言います。
  1. ‘開設者‘とは、‘当社‘が提供するサービスを通じて補償プロジェクトまた、投資プロジェクトを開設する‘会員’のことを言います。
  1. ‘参加者‘とは’当社’が提供するサービスを通じて開設されてある‘プロジェクト‘と金額を決めてプロジェクト実行者に決済する‘会員‘のことを言います。
  1. ‘リターン‘とは開設者がプロジェクトを完了した後、参加者に提供することを約束した‘物‘のことを意味します。
  1. ‘投資家‘とは’会社‘が提供するサービスを通じて開設されてあるプロジェクトと金額を決めて開設者に投資する会員のことを言います。
  1. この利用規約で使用する用語の定義は上記の内容で決めたことを除いては取引慣行および関連法令に従います。\n

  ##### 第3条 利用規約のお知らせと改正
  1. ‘当社‘はこの利用規約の内容を利用者が簡単に確認できるようにサイトに掲示します。
  1. ‘当社‘は資本市長と金融投資に関する法律、電子商取引で電子署名法、情報通信法の利用促進に関する法律、訪問販売などに関する法律、消費者保護法など関連した法を違反しない範囲でこの利用規約を改正することができます。
  1. ‘当社‘は利用規約を改正する場合改正された利用規約の内容と実行日を決めて実行日から最初7日前から改正内容をサイトに通知します。また、既存の会員にはサイトに告知すること以外に会員が入力したメールアドレスにメールを送ります。
  1. 当社が第3項によって利用者に通知し公知・告知日から改正利用規約の施行日7日後まで拒否の意思を表してない場合、変更された利用規約を承認したと見なします。
  1. 会員は、改正した利用規約に同意しない場合、会社に脱退申請をすることができます。\n

  ##### 第4条 利用規約で定めない事項
  本利用規約で定めない事項や、解釈に関しては関係法令または商慣習に従います。\n

  ##### 第5条 サービスの性質と目的
  1. ‘当社‘が提供するサービスは‘会員‘と‘会員‘相互の取引が円滑に行えるよう支援するオンラインプラットフォームとして’当社’は’会員’から資金を貸したり、貸してもらえません。
  1. ‘会員‘の間で成立された取引に対する関連責任は取引当事者である会員自ら取らなければなりません。 ただし、’会社’は会員の間で発生した問題解決のため必要書類などを関係法令が認める範囲内で提供することができます。\n
&nbsp;\n
#### 第2章 会員登録

  ##### 第6条 会員登録
  1. 利用契約は‘会員’になろうとする者(以下’加入申請者’)が利用規約の内容について同意をした後に入会の申し込みをし当社が申請に対する承諾をすることによって締結されます。
  1. ‘加入申請者’がこの利用規約または会社の個人情報取扱方針を読んで’同意’ボタンを押すか’確認’などにチェックするとこで利用規約に同意したものと見なします。
  1. 他人の名義で会員登録した人は会員としての権利を一切主張できません。 他人の名義を利用して会員加入した人のアカウントは削除され関係法令によって処罰を受けることができます。
  1. 一人当り一つのアカウントを持つことを原則とし一つ以上のアカウントを持った’会員’のアカウントは削除されます。（該当’会員’を脱退や再加入を禁止させることができます。）また、一つ以上のアカウントを持った’会員’はアカウントに対してどんな権利主張することもできません。
  1. この'サイト'の会員登録申し込みを完了した場合、申込者の会員レベルは'未認証会員'で分類され、
メールまたは別途の手段に転送された認証完了手続きを終えてから正会員としてこの'サイト'のすべてのサービスを利用することが出来ます。
  1. 'サイト'登録後、'未認証会員'として30日を過ぎる場合、会員登録の申し込みは自動的にキャンセルされ、アカウント情報は削除されます。\n

  ##### 第7条 利用申請の承諾と制限
  1. ‘当社’は加入申請者の申請に対して特別な事情がない限りサービス利用を承諾します。ただし、当社は次の各号に該当する申請に対しては承諾しなかったり承諾した後にも任意に脱退させることができます。\n
  A. 実名ではない場合、他人の名義を利用して申請する場合。\n
  B. 加入申請者が利用規約によって以前、会員資格を喪失したことがある場合。‘会社‘から再加入の承認を貰う場合は例外。\n
  C. 虚偽情報を記載したり会社が要求する内容を記載していない場合。\n
  D. 当社の秩序や公序良俗を阻害する目的で申請した場合。\n
  E. 不正な用途で本サービスを利用しようとする場合。\n
  F. 他人のサービス利用を妨害するかその情報を盗用するなど電子商取引の秩序を脅威する場合。\n
  G. 利用規約または会社の規定を違反して申請する場合。\n
  H. 本サービスと競争関係である利用者が申請する場合。\n
  I. 利用者の帰責事由によって承諾出来ない場合、またはその他の利用規定事項を違反して申し込む場合\n
  J. 会員登録の際、申込者の年齢が13歳未満の場合、\n
  K. 一定期間だけ有効なメールアカウントで会員登録した場合\n
  1. ‘当社‘は次の各号の内容に該当する場合に利用申請に対する承諾を制限したり留保することができます。\n
      A. ‘当社’のサービス関連設備に余裕がない場合。\n
      B. サービスの提供に技術上の問題がある場合。\n
      C. 未成年者の場合。\n
      D. 他にA、B、Cに準する事由がある場合。\n
  1. 当社は本サービスを利用する会員を等級別に区分して利用時間、利用回数、サービス内容などを細分化し等級別に利用の差をおくことができます。\n

  ##### 第8条 個人情報の保護義務
  ’当社’は情報通信網法など関係法令が定めるところにより’会員’の個人情報を保護するために努力します。 個人情報の保護および利用については関連法および会社の個人情報取扱方針が適用されます。 但し、会社の公式サイト以外にリンクされたサイトでは会社の個人情報取扱方針が適用されません。\n

  ##### 第9条 ‘会員’のアカウント、パスワード管理
  1. ‘会員’のアカウントとパスワードに関する管理責任は’会員’本人にありこれを第3者が利用するようにしてはいけません。
  1. 会員’は’会社’から付与されたアカウントを変更することはできません。一つ以上のアカウントを持つこともできません。 やむを得ない事由で‘変更したい場合には、’会員’脱退後、再加入しなければなりません。
  1. ‘会社’は’会員’のアカウントが次の各号に該当する場合’会員’にアカウントの変更を要請することができます。この場合、会員は会員脱退した後、再加入の手続きを踏まなければなりません。
      A. アカウントが利用者の電話番号などで登録されてプライバシーの侵害が懸念される場合。
      B. 他人に嫌悪感を与えたり公序良俗に反する場合。
  1. ‘会社’は’会員’のアカウントが個人情報流出の恐れがある場合、’当社’及び’当社の運営者’と勘違いする恐れがある場合には該当アカウントの利用を制限することができます。
  1. ‘会員’はアカウント及びパスワードが盗用されたり第3者が使用していることを認知した場合は直ち’当社’に通知して会社の案内に従わなければなりません。
  1. 第5項の場合に該当’会員’が当社へその事実を通知しない場合、通知した場合にも’当社’の案内に従わない場合に発生する不利益について’当社’は責任を負いません。
  1. ‘当社’が提供するサービスのアカウントは会員本人の同意のもとに’当社’が運営する外部サイトのアカウントと連結されることがあります。
  1. その他、会員の個人情報の管理および変更に関する事項はサービス別の案内によります。\n

  ##### 第10条 会員情報の変更
  1. ‘会員’は個人情報管理画面を通じていつでも本人の個人情報を閲覧、修正することができます。 但し、サービス管理のため必要な実名、アカウント、生年月日などは修正できません。
  1. ‘会員’は会員入会の申し込みの際、記載した事項が変更された場合はオンラインで修正することができます。また、電子メールか他の方法で’会社’にその変更の内容を知らせなければなりません。
  1. 第2項の変更事項を会社に知らせなかった場合に発生する不利益に対して当社は責任を負いません。\n

  ##### 第11条 サイトの脱退など
  1. ‘会員’はいつでもサイト内の脱会メニューを通じて脱退や利用契約の解約申請をすることができます。会社は関連法によってすぐ処理しなければなりません。
  1. ‘会員’の脱退または利用契約を解約をする場合、個人情報取引方針によって’当社’が’会員情報’を保有する場合を除き全てのデータを削除します。\n
&nbsp;\n
#### 第3章 義務と責任

  ##### 第12条 当社の義務
  1. ’当社’は関連の法令や利用規約が禁じる行為または公序良俗に反する行為をしないで安定的なサービスを提供するため努力します。
  1. ‘当社’は’会員’が安全にサービスを利用できるように’会員‘の個人情報の保安システムを構築し個人情報取引方針をお知らせします。
  1. ‘当社’は利用者が利用した電子金融取引にミスが発生する場合を備えそれを確認できる記録を作成/保存しなければなりません。
  1. ‘当社’は電子金融取引サービスを提供することによって取得した個人情報また資料を法的に使用するかまたは利用者の同意を得ずに第3者に提供、業務上の目的以外に使用しません。
  1. ‘当社’はサービスを利用する会員が提起する意見や不満事項が正当だと認められれば、即刻処理します。 但し、即時処理が不可能な場合には会員にその事由及び処理に要する期間を通知します。
  1. ‘当社’は’会員’が受信に同意をしなかった場合、営利目的の広告性電子メールを送信しません。
  1. ‘会員’の脱退申請がある場合、会社は本人の確認後、即刻脱退の処理をします。\n

  ##### 第13条 当社の責任
  1. ‘当社’は、アクセス媒体の偽造や変造で発生した事故(但し、当社がアクセス媒体の発給主体であったり使用、管理主体である場合)によって利用者に損害が発生した場合にはその損害を賠償する責任を負います。
  1. ‘当社’は前項の場合にもかかわらず次の場合、利用者に対する損害賠償の責任を負いません。\n
      A. ’当社’がアクセス媒体の発給主体では場合、アクセス媒体の偽造や変造で発生した事故によって利用者に損害が発生した場合。\n
      B. ‘利用者’がアクセス媒体を第3者に貸与したり使用を委任したり譲渡又は担保の目的で提供したり第3者が権限なく利用者のアクセス媒体を利用して電子金融取引ができることを知っていたり知ることができたにもかかわらず利用者が自分のアクセス媒体を漏洩したり露出したり放置した場合。\n
      C. ‘利用者’に損害が発生した場合、会社’が事故を防止するためにセキュリティを徹底的に遵守するなど十分な注意義務を果たした場合。\n
      D. ‘当社’の管理領域ではなく公共の通信線路の障害によるサービスの利用が不可能な場合。\n
      E. 他、‘当社‘の帰責事由がない通信サービスなどの障害による場合。\n
  1. ‘当社’は、利用者からの取引があるにもかかわらず天災地変や当社の帰責事由がない停電、火災、通信障害、その他の不可抗力的な事由で処理が不可能だったり遅延された場合には利用者に処理不可能または延期の理由を通知した場合(金融機関又は決済手段の発行会社や通信販売業者が通知した場合を含める)には利用者に対する責任を負いません。
  1. ‘当社’はパソコンなど情報通信設備のメンテナンスが発生しサービスの提供を一時的に中止する場合には会社のホームページを通じて利用者に中止日程および中断の事由を事前にお知らせします。\n

  ##### 第14条 ’会員‘の義務
  1. ‘会員’は次のような行為をしてはならないです。\n
      A. 会員登録の申請または変更の時、虚偽内容を登録する行為。\n
      B. 他の会員のアカウント、パスワードなどを盗用する行為。\n
      C. アカウントを他人と取引する場合。\n
      D. ‘当社’の管理者、職人または関係者を詐称する行為。\n
      E. ‘当社’から特別な権利を付与されていなくて当社のクライアントプログラムを変更したり当社のサーバーをハッキングしたりウェブサイトまたは掲示された情報の一部・全部を任意で変更する行為。\n
      F. 本サービスに危害を加えたり、故意に妨害する行為。\n
      G. 本サービスを通じて得た情報を会社の事前承諾なくサービスの利用目的以外に任意加工、販売、複製、送信、配布したりこれを出版および放送などに使用したり第3者に提供する行為。\n
      H. ‘当社’又は第3者の著作権など知的財産権を侵害したり侵害する内容を伝送、掲示、電子メールまたはその他の方法で他人に流布する行為。\n
      I. 公共秩序や公序良俗に反する卑属な内容の情報や文章、図形、音響、動画などを伝送、掲示、電子メールまたはその他の方法で他人に流布する行為。\n
      J. 侮辱的な内容、個人的な内容として他人の名誉やプライバシーを侵害する内容を伝送、掲示、電子メールまたはその他の方法で他人に流布する行為。\n
      K. 他の会員を威嚇したり、特定の利用者に嫌がらせを与える行為。\n
      L. ‘当社’の承認を受けず、ほかの会員の個人情報を収集または保存する行為。\n
      M. 犯罪と結びついたと客観的に判断される行為。\n
      N. ‘当社’の業務に妨害される行為および会社の名誉に傷つける行為。\n
      O. ‘当社’の同意なしでサービスの利用権限や利用契約上の権利を他人に譲渡、贈与、担保で提供する行為。\n
      P. この利用規約を含めてその他の当社が定める諸規定または利用条件を違反する行為。\n
      Q. 他、法令に反する行為。\n
  1. ‘会員’は関係している法令、この利用規約、その他‘会社’が定めた規定、サービス利用案内など‘当社’が通知する事項を守ります。
  1. ‘会員’は事前に’当社‘の承諾なくサービスを利用して営業活動をすることはできません。’会員’の間または‘会員’と第3者の間でサービスを媒介にして成した取引は‘当社’は無官であり’当社‘はこれに対するどんな責任も負いません。
  1. ‘会員が第3項を違反して’当社’に損害を起こした場合’会員’は’会社’にその損害を賠償しなければなりません。\n

  ##### 第15条 ’会員‘に対する通知
  1. ’当社’が会員に通知をする場合、この利用規約に別の規定がない限りメールアドレスなどで通知することができます。
  1. ‘当社’は会員が電子メール受信を拒否した場合は関連法令や本利用規約で定めた通知義務を果たすため必要な場合は電子メールを送信することができます。
  1. 当社は会員の全体に通知をする場合、7日以上会社の掲示板に掲示することにより、第1項の通知と同じことと見なします‘。\n
&nbsp;\n
  #### 第4章 サービスの利用

  ##### 第16条 サービスの提供と変更
  1. ‘当社’は’会員’に「補償型」「寄付型」「持分型」「混合型」クラウドファンディングサービスなどを提供することができます。サービスに関しては資本市長と金融投資業に関する法律などに遵守します。
  1. ‘当社’は’当社’が提供するコンテンツと進行しているイベントなど全てのサービスを’会員’に提供します。但し、第6条の3項によって会員の等級別に提供されるサービスに差等を付けます。
  1. ‘当社’はサービス変更の場合、変更されるサービスの内容また提供される日付を第15条によって会員に通知します。\n

  ##### 第17条 掲示物の管理
  1. ‘当社’は’会員’が登録した掲示物また資料が変調、毀損、削除されないように努力します。
  1. ‘当社’は次の各号に該当する内容が含まれた掲示物や資料について会員の登録を拒否したり事前通知なく削除、移動、変更することができます。\n
      A. 他の’会員’または第3者にひどい侮辱を与えたり名誉を損傷させる内容。\n
      B. 公共秩序や公序良俗に反する内容とそのような内容を含めているサイトにアクセスできるようにリンクさせた内容。\n
      C. わいせつ物を掲示したりわいせつ物を紹介しているサイトに接続できるようにリンクさせた場合。\n
      D. 不法コピーまたはハッキングを助長する内容。\n
      E. 営利を目的とする広告や迷惑性の掲示物の場合。\n
      F. 犯罪に関連されてると客観的に認められる内容。\n
      G. ‘当社’他の会員’第3者’の著作権の権利を侵害する内容。\n
      H. 登録しようとする掲示板の性格に合致しない場合。\n
      I. 常用ソフトウェアやクラックファイルを載せた場合。\n
      J. 同一な内容を重複して掲示するなど掲示の目的と違う場合。\n
      K. その他の当社の諸般規定や関係法令に反する内容。\n
  1. ‘当社’は個別サービス別に掲示物に係る詳細利用の指針を別途に決めて施行することができ’会員’はその指針によって掲示物(会員間の伝達を含む)を掲載しなければなりません。
  1. ’当社’は’会員’が登録した掲示物がサービスの方向と違う場合削除することができます。\n

  ##### 第18条 掲示物に関する権利の帰属
  1. ‘当社’が作成した著作物に対する著作権また知的財産権は’会社’に帰属されます。
  1. ‘会員’がサービスの画面内に掲示した掲示物の著作権は掲示した’会員’に帰属されます。
  1. ‘会員’が著作権を持つ掲示物に対して’会社’はこれを利用することができ営利の目的とは無官に’当社’が運営するサイト内でご自由に利用できます。’会員’が本利用規約に同意する場合上記の内容と同じ利用に必要な著作権などを’会員’が’当社’に与えることだと考えられます。これは’会員‘が脱退した後にも有効とされます。
  1. ‘当社’は’会員’が著作権を持つ掲示物に対して第3項で決めた方法以外の方法で営利の目的で利用しようとする電話、FAX, 電子メールなどの方法と通じて事前に’会員’の同意を得なければなりません。\n

  ##### 第19条 広告の掲載
  1. ’当社’はサービスの運営に関してサービス画面、ホームページに掲載したりメール、SMSなどを通じて送信することができます。 但し、’当社’は’会員’にメール、SMSなどの発送をする場合は受信同意を必ず確認し受信に同意した会員に限って送信します。
  1. 利用者がサービス上に掲載されている広告を利用したりサービスを通じた広告主の販促活動に参加するなどの方法で交信または取引をするのは全面的に利用者と広告主間の問題です。 もし利用者と広告主の間に問題が発生する場合には利用者と広告主が直接解決しなければならずこれと関連して会社はいかなる責任も負いません。\n

  ##### 第20条 サービスの利用時間
  1. 当社は会員の利用申請を承諾した時からサービスを開始します。 但し、一部のサービスの場合には指定された日からサービスを開始します。
  1. 当社はサービスを一定の範囲に分割して各範囲別に利用可能時間を別に指定することができます。 この場合その内容を会員に通知します。
  1. 本サービスを1日24時間、年中無休で提供します。 但し、会社は、業務又は技術上の障害、技術的補完およびサイトの改善を理由とする場合などにはサービスが一時停止されることもあります。 このような場合、当社は事前または事後にこれを告知します。\n

  ##### 第21条 サービスの利用制限、中止、’会員’の脱退\n
  ‘当社’は次の各号に該当する事由が発生する場合、’会員’のサービス利用を制限または中止させたり会員を脱退させることができます。\n
  1. ’会員’が’当社’のサービス運営を故意に妨害する。
  1. ‘会員’が第12条で規定した’会員’の義務を違反した場合。
  1. 一人の会員が一つ以上のアカウントを持つ場合。
  1. 情報通信設備またはシステムの定期点検、増設および交代、保守点検、故障など工事によって仕方ない場合。
  1. 電気通信事業法に規定された期間通信事業者が電気通信サービスを中断した場合。
  1. 国家の非常事態、サービス設備の障害、サービス利用の輻輳などでサービス利用に支障がある場合。
  1. 公序良俗を阻害する低俗なアカウントまたはニックネームを使用した場合。
  1. 関連行政官庁又は公共機関の是正要求などがある場合。
  1. 3ヵ月以上サービスを利用しない場合。
  1. 掲示板にインデックスファイルなしで資料だけ載せるなど、掲示板をファイル資料室として利用する場合。
  1. 第15条第2項で規定する掲示物や資料を掲示、登録した場合。
  1. 他人の決済情報などを盗用したり、不正な行為で取引をする場合。\n

  ##### 第22条 プロジェクトの申請
  1. 開設者は、補償型、寄付型、持分型、混合型などクラウドファンディングの性格と目標金額の成功時にだけ資金を受ける方式といくらが集まっても集まっただけに資金を受け取る方法の中でどちらかを選択してプロジェクトの開設を申請しなければなりません。 プロジェクトを開設しようとする’会員’は’会社’で提供する様式と手続きによって、プロジェクトの開設を申請することができます。
  1. 申請されたプロジェクトは’会社’が定めた次の基準と運営方向に合致するかどうかを審査した後、承認されたプロジェクトをサイト上に掲示されます。\n
      A. ‘当社’のプロジェクトの開設による規定と手続き遵守しているのか。\n
      B. ‘当社’が提供したプロジェクトの開設様式によって記載しているのか。\n
      C. ‘当社’が要求したプロジェクト関連ファイル(イメージ、動画、音声ファイル等)を提供しているのか。\n
      D. 関係法令に違反されたり公共秩序や公序良俗を阻害してはないのか。\n
      E. 他人の名誉を損傷させたり不利益を与える目的ではないのか。\n
      F. 当社が定めた各種のプロジェクト審査条件に合致しているのか。\n
      G. その他のプロジェクトの開設のため必要とする要請に応じたのか。\n
  1. ’会員’が登録を申請したプロジェクトの内容に虚偽、記載漏れ、誤記があってはなりません。’会員’はこれを遵守せず發生した不利益については’会員’がすべての責任を負担しなければなりません。
  1. ’会員’が作成および提供したプロジェクトの内容やファイルに対するすべての権利および責任は当該’会員’が取ります。’当社’は’会員’が登録したプロジェクトの内容が次の各号に該当すると判断される場合、事前通知なしに削除することができます。これに対して’会社’はいかなる責任も負いません。また、事案によってプロジェクトを登録した’会員’に釈明を要請することができます。\n
      A. ‘会員’が提供した会員情報またはプロジェクトの情報が虚偽であることが判明された場合。\n
      B. 第3者の知的財産権及びその他の権利を侵害する内容である場合。\n
      C. ‘当社’から事前承認されていない内容を掲示した場合。\n
      D. ‘会員’が’当社’のサイトや掲示板にわいせつ、誹謗、中傷、名誉毀損、個人情報の無断公開などそれに準ずる掲示物を掲載したり関連サイトをリンクする場合る。\n
      E. ‘会員’がプロジェクト情報を不法で変更または操作した場合。\n
      F. その他関係法令に違反すると判断される場合。\n
  1. 登録が認めたプロジェクトは募集期限が終わった後、サイトまたはサービスに関連したウェブサイトに掲示される場合もあります。\n

  ##### 第23条 プロジェクトの修正
  1. ‘当社’がプロジェクトを承認する前には修正事項を’当社’に要請した後、会社が承認した範囲内だけで修正することができます。
  1. プロジェクトが承認された後、プロジェクトのページの一部項目を変更するかキャンセルまたは削除することができません。 変更が禁止された事項をプロジェクト申請’会員’が任意に修正、変更、削除した場合、プロジェクトに参加した会員が損害賠償請求など法的対応をすることができ、これによる不利益について’当社’は責任を負いません。\n

  ##### 第24条 プロジェクトのキャンセル
  1. プロジェクトが承認される前にご自由にプロジェクトをキャンセルすることができます。キャンセルは’当社’に要請することによって成り立ちます。但し、プロジェクトをキャンセルする前まで使用した費用は’会社’にお支払いしなければなりません。
  1. プロジェクトが承認された後、締め切りされる前までは開設者がご自由にプロジェクト開設申請をキャンセルすることはできません。
  1. プロジェクトが成功で終わった場合には取り消しすることは不可です。但し、当社が認める客観的で特別な理由があればキャンセルすることが可能です。この場合にも当社に33条で定めた規定によって手数料を払わなければなりません。
  1. プロジェクトの修正された内容はアップデートを通じて告知されます。会員はいつでも確認することが可能です。\n

  ##### 第25条 決済の手段
  会員は当社が定めた方法(クレジットカード、リアルタイム口座振替、仮想口座など)を通じてサイト内で決済が可能であり正確で安全な決済処理のため関係法令に基づいて消費者情報を決済代行社に提供します。\n

  ##### 第26条 決済のキャンセル、払い戻し
  1. 決済のキャンセル及び払い戻し規定は電子商取引などでの消費者保護に関する法律などの関連法令を順守します。
  1. 個別サービスの性格によって会社は利用規約と利用条件に従ったキャンセルと払戻し規制を定めることができます。この場合、個別の利用規約と利用条件上のキャンセルと払い戻し規定が優先されます。
  1. その他、本利用規約とサイトの利用案内に規定されていないキャンセルや払い戻しに関する事項については、消費者被害補償規定に定められたところによります。\n

  ##### 第27条 目標金額の達成時のみ資金を受ける方式のプロジェクト失敗の際、払い戻し
  1. ‘当社’は’会員’が参加した金額が目標金額を達成時のみ資金を受ける方式のプロジェクトがプロジェクトの中途の撤回または申請した目標金額を満たすことができなくて後援失敗になった場合は営業日を基準にして10日以内に既存に行われた参加金額の決済について一括にキャンセル処理を行います。
  1. 第1項にもかかわらず実際に決済取消による払い戻し処理はカード会社など金融機関によって完了される期間が変わる可能性があります。\n

  ##### 第28条 キャンセル、払い戻しの手数料
  1. 会員の単純な心変わりによるキャンセルの場合、払い戻し処理に発生する手数料は会員が負担します。
  1. キャンセルや払い戻しの手数料は決済手段によって異なります。キャンセ、払い戻しの手数料の賦課方法は別の決済のキャンセルや払い戻し規定に従います。\n

  ##### 第29条 プロジェクト成功
  1. 目標金額の達成する時のみ資金を受ける方式のプロジェクトのファンディング金額募集の結果は、会員が指定したプロジェクトの期限が締め切られた時点で決済されたファンディング金額を基準に会員が指定した目標金額以上なら成功、未満であれば失敗と判断します。
  1. いくらが集まっても集まっただけ資金を受ける方式のプロジェクトは成功と失敗が区別されておらず集められた金額は開設者に該当金額が支給されます。
  1. プロジェクトの最終のファンディング金額にはファンディング期限が終わる前のキャンセル及び変更申請が反映されることがあります。
  1. プロジェクトの金額募集が失敗した場合、その間募集された金額についてプロジェクトの開設者は何らかの権利主張することもできず、会社は事前に告知した手続きによって参加者から決済された金額すべてを一括キャンセルします。\n

  ##### 第30条 ファンディング金額の伝達
  1. 募集された金額の支給に関して’当社’は開設者の身分証のコピー、通帳のコピーなど身分を確認できる資料を要請することができ、この他に会社が要請した資料を提出してから精算金額を正式にもらえることができます。
  1. 清算金額は必ず開設者の口座に振り込みされることを原則としそのほかの口座への振込みは’会社’が決めた基準によって承認されことができます。
  1. ‘当社’は手数料を除いた金額を開設者に支給し手数料の金額について税金計算書を発給します。 開設者は受領した 清算金額 について税金納付の義務を誠実に履行しなければなりません。
  1. 募金が終了された以降支払い要請があった15日以内に開設者が要請した口座に入金します。
  1. ‘当社’は開設者にファンディング金額が透明に渡します。‘開設者‘は目的によってファンディング金額をを使うように努力します。\n

  ##### 第31条 ファンディング金額の使用
1.	プロジェクトの開設者は、ファンディング金額募集成功により受領した金額を開設者が約束したプロジェクトのため使用します。また約束したリターン(商品)の準備やそれの配送の経費などで使用します。これを履行しないため発生する不利益に対してすべての責任を負担しなければなりません。\n

  ##### 第32条 リターンの提供
  1. プロジェクト開設者はプロジェクトの登録によって発生する’会員’と他の’会員’、’会員’と第3者間の約束、リターン(商品)オプション提供等に関して履行の義務があります。これを履行しないため発生する不利益に対する責任は当該’プロジェクト開設者’にあります。
  1. プロジェクトの開設者は、プロジェクト登録の際、参加者に提供する商品の内容、数量、提供の日程をサイトに掲示して、商品金額の募集成功する時に、掲示した内容によって履行する義務があります。 これを履行しなかったり故意に掲示した内容と異なる場合、あるいは一部だけを履行する場合には当該‘プロジェクト開設者’は会社による金額の回収や参加者による損害賠償請求などすべての法的な責任を負います。
  1. プロジェクトの開設者は、プロジェクトの参加者に提供するリターンに関して参加者からの問い合わせがある場合誠実に答える義務があります。\n

  ##### 第33条 手数料
  1. ‘会員’のサイトへの加入やサービスを通じたプロジェクトの開設や参加は無料であり、プロジェクトが成功した場合には開設者に手数料が賦課されます。
  1. 目標金額の達成時のみ資金を受ける方式のプロジェクトといくらが集まっても集まっただけに資金を受ける方式のプロジェクトの手数料率はそれぞれ異なって適用されます。
  1. 手数料率の決定のために’当社’と開設者は別途の契約を締結することができます。
  1. その他のサービスにおいて’当社’がサービス利用による手数料を賦課しようとする場合には賦課基準及びその他詳細な内容を施行7日前にサイトに告知します。\n
  
  ##### 第34条 翻訳サービス提供
  このサービスには、Googleが運営する翻訳が含まれている場合があります。 Googleは、商品性、特定目的適合性および非侵害性に対する正確性、妥当性、黙示の保証を含め、明示的にも黙示的にも、翻訳に関連するすべての保証を否認します。\n
&nbsp;\n
  #### 第5章 その他

  ##### 第35条 責任制限
  1. ‘当社‘が第17条第2項、第21条第1項で規定した事由によってサービス提供を制限または中止させたり’会員’を脱退させた場合、’当社’はこれによって発生した’会員’の損害を賠償する責任がありません。
  1. ‘当社’は’会員’のメールアドレスなど個人情報を間違って記載したり第9条2項による個人情報変更の義務を果たさない場合に発生した損害に対して賠償する責任がありません。
  1. ‘会員’がサイトに掲載した情報、資料、事実などの真実性、正確性、信頼性は’当社’が保障しません。 会員が掲示した資料の活用可否は会員各自の責任と判断に委ねられており彼によってもたらされる結果について会社は一切の責任を負いません。
  1. ‘当社’は’会員’間及び’会員’と第3者間にサービスを媒介に発生した紛争に対して介入する義務がありません。当社はサービスの利用と関連して’会員’の故意あるいは過失により’会員’または第3者に発生した損害に対して責任を負担しません。ただ、関連法令で特に規定している事項があれば彼に従います。\n

  ##### 第36条 損害賠償
  利用者が本利用規約の上、義務を違反することによって会社に損害が発生した場合、利用者は会社にその損害を賠償しなければなりません。\n

  ##### 第37条 準拠法と管轄裁判所
  1. ’当社’と’会員’はサービスに関して発生した紛争を円満に解決するために必要な努力をします。
  1. 前項の場合にもかかわらず紛争による訴訟が提起された場合、会社の所在地の裁判所を管轄裁判所として解決するようにします。
  1. 当社と利用者間に発生した紛争に関しては大韓民国法を準拠法とします。\n

  #### 付則
  **この利用規約は2015年10月14日から効力を発生します。**
  #### 付則
  **この利用規約は2016年10月20日から効力を発生します。**
  #### 付則
  **この利用規約は2017年1月5日から効力を発生します。**
  #### 付則
  **この利用規約は2017年8月1日から効力を発生します。**
  `,
};

export default terms;
