const terms = {
  'terms.title': '이용약관',
  'terms.html': '',
  'terms.markdown': `
  #### 제1장 총칙\n
  ##### 제1조 목적
  이 약관은 ㈜메이크스타(이하 ‘회사’)가 운영하는 인터넷사이트 makestar.co(이하 ‘사이트’)에서 제공하는 크라우드펀딩 등 인터넷 관련 서비스(이하 ‘서비스’)를 이용함에 있어 회사와 이용자의 권리와 의무, 책임사항 및 회원의 서비스 이용절차에 관한 사항을 규정함을 목적으로 합니다.\n
  
  ##### 제2조 정의
  이 약관에서 사용하는 용어의 정의는 다음과 같습니다.\n
  1. ‘회사’는 ㈜메이크스타를 말합니다.
  1. ‘사이트’는 회사가 운영하는 사이트로 makestar.co를 말합니다.
  1. ‘서비스’라 함은 구현되는 단말기(PC, TV, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이 이용자가 이용할 수 있는 ‘회사’가 제공하는 관련 제반 서비스를 의미합니다.
  1. ‘크라우드펀딩’이란 회사가 제공하는 서비스를 통하여 불특정 다수의 회원으로부터 개설자가 개설한 프로젝트에 대한 금액 또는 투자금을 공모하는 것을 의미합니다.
  1. ‘이용자’는 사이트에 접속하여, 이 약관에 따라 회사가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
  1. ‘회원’이라 함은 회사의 서비스에 접속하여, 이 약관에 따라 회사와 이용계약을 체결하여 회사가 제공하는 서비스를 이용하는 개인 또는 법인 고객을 말합니다.
  1. ‘비회원’이라 함은 회원 가입을 하지 않고 회사가 제공하는 제한된 서비스를 이용하는 개인 또는 법인 고객을 말합니다.
  1. ‘게시물’이라 함은 ‘이용자’가 서비스를 이용함에 있어 서비스 상에 게시한 부호•문자•음성•음향•화상•동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.
  1. ‘프로젝트'라 함은 회원이 다른 회원에게서 펀딩 또는 투자를 받기 위하여 회사가 제공한 공간에서 개설한 서비스를 말합니다.
  1. ‘개설자’라 함은 ‘회사’가 제공한 서비스를 통하여 보상 프로젝트 또는 투자 프로젝트를 개설하는 ‘회원’을 말합니다.
  1. ‘참여자’라 함은 ‘회사’가 제공한 서비스를 통하여 개설되어 있는 ‘프로젝트’와 금액을 정하여 개설자에게 결제하는 ‘회원’을 말합니다.
  1. ‘리워드’라 함은 개설자가 프로젝트의 완료 후 참여자에게 제공하기로 약속한 물품을 말합니다.
  1. ‘투자자’라 함은 ‘회사’가 제공한 서비스를 통하여 개설되어 있는 프로젝트와 금액을 정하여 개설자에게 투자하는 회원을 말합니다.
  1. 이 약관에서 사용하는 용어의 정의는 위 각 항에서 정한 것을 제외하고는 거래 관행 및 관계 법령을 따릅니다.
  \n
  ##### 제3조 약관의 명시와 개정
  1. ‘회사’는 이 약관의 내용을 이용자가 쉽게 확인할 수 있도록, 사이트 초기 화면에 게시합니다. (사이트에 게시합니다.)
  1. ‘회사’는 자본시장과 금융투자업에 관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한 법률, 전자문서 및 전자거래기본법, 전자서명법, 정보통신망이용촉진 등에 관한 법률, 방문판매 등에 관한 법률, 소비자보호법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
  1. ‘회사’는 약관을 개정하는 경우 개정된 약관의 내용과 시행일을 정하여 시행일로부터 최소 7일 전(단, 이용자의 권리와 의무에 중대한 영향을 미치는 사항에 대하여는 30일전)부터 개정내용을 명시하여, 사이트에 공지합니다. 또한, 기존 회원에게는 공지 외에 회원이 입력한 전자 우편주소를 통해 고지합니다.
  1. 회사가 제3항에 따라 이용자에게 통지하면서 공지•고지일로부터 개정 약관 시행일 7일 후까지 거부 의사를 표시하지 아니하면 승인한 것으로 본다는 뜻을 명확하게 통지하였음에도 이용자가 거부 의사를 표시하지 않은 경우 변경된 약관을 승인한 것으로 봅니다.
  1. 회원은 개정 약관에 동의하지 않을 경우 회사에 탈퇴신청을 할 수 있습니다.
  \n
  ##### 제4조 약관이 정하지 않은 사항
  1. 본 약관에서 정하지 아니한 사항이나, 해석에 관하여는 관계법령 또는 상관례에 따릅니다.
  \n
  ##### 제5조 서비스의 성질과 목적
  1. ‘회사’가 제공하는 서비스는 ‘회원’과 ‘회원’ 상호간의 거래가 원활히 이루어질 수 있도록 돕는 온라인 플랫폼으로, ‘회사’는 ‘회원’에게서 자금을 빌려주거나 빌려 받지 않습니다.
  1. ‘회원’ 간 성립된 거래에 대한 관련 책임은 거래당사자인 회원들 스스로가 부담하여야 합니다. 다만, ‘회사’는 회원들 간에 발생한 문제 해결을 위한 필요 서류 등은 관계 법령이 허용하는 범위 내에서 제공할 수 있습니다.\n
  &nbsp;\n
  
#### 제2장 회원가입 등
  \n
  ##### 제6조 회원가입
  1. 이용계약은 ‘회원’이 되고자 하는 자(이하 ‘가입신청자’)가 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.
  1. ‘가입신청자’가 이 약관 및 회사의 개인정보취급방침을 읽고 ‘동의’버튼을 누르거나 ‘확인’등에 체크하는 방법을 취한 경우 이 약관에 동의한 것으로 간주합니다.
  1. 타인의 명의로 회원 가입을 한 사람은 회원으로서의 권리를 일체 주장할 수 없습니다. 타인의 명의를 이용하여 회원가입 한 사람의 계정은 삭제되며, 관계법령에 따라 처벌을 받을 수 있습니다.
  1. 한 ‘회원’ 당 하나의 계정을 갖는 것을 원칙으로 하며, 하나 이상의 계정을 가진 ‘회원’의 계정은 삭제되거나 임의로 해당 ‘회원’을 탈퇴 및 재가입을 금지 시킬 수 있습니다. 또한 하나 이상의 계정을 가진 ‘회원’은 하나 이상의 다른 계정에 대하여 어떠한 권리도 주장할 수 없습니다.
  1. '사이트' 의 가입 신청을 완료할 경우 신청자의 회원 등급은 '미인증회원' 으로 분류되며, 이메일 또는 별도의 수단으로 전송된 가입 후 인증완료 처리를 완료해야 정회원으로 '사이트'의 모든 서비스를 이용할 수 있습니다.
  1. 계정이  '사이트' 가입 후 '미인증회원'으로 30일이 경과할 경우 가입신청은 자동으로 해지되며 계정 정보는 삭제됩니다.\n
  
##### 제7조 이용 신청의 승낙과 제한
  1. ‘회사’는 가입신청자의 신청에 대하여 특별한 사정이 없는 한 서비스 이용을 승낙합니다. 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 승낙 후에도 임의로 탈퇴시킬 수 있습니다.\n
      A. 실명이 아니거나 타인의 명의를 이용하여 신청하는 경우\n
      B. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 회사는 ‘회원’ 재가입 승낙을 얻은 경우에는 예외로 함.\n
      C. 허위의 정보를 기재하거나 회사가 요구하는 내용을 기재하지 않은 경우\n
      D. 사회의 안녕과 질서, 미풍양속을 저해할 목적으로 신청한 경우\n
      E. 부정한 용도로 본 서비스를 이용하고자 하는 경우\n
      F. 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우\n
      G. 약관 또는 회사의 제반 규정을 위반하여 신청하는 경우\n
      H. 본 서비스와 경쟁관계에 있는 이용자가 신청하는 경우\n
      I. 이용자의 귀책사유로 인하여 승낙이 불가능하거나 기타 규정한 제반 사항을 위반하여 신청하는 경우\n
      J. 가입신청시 연령이 14세 미만인 경우\n
      K. 일정 기간 동안만 유효한 이메일 계정을 사용해 가입을 신청한 경우\n
  1. ‘회사’는 다음 각 호의 내용에 해당하는 경우 이용신청에 대한 승낙을 제한하거나 유보할 수 있습니다.\n
      A. ‘회사’의 서비스 관련 설비에 여유가 없는 경우\n
      B. 서비스 제공에 기술상 문제가 있는 경우\n
      C. 미성년자의 경우\n
      D. 기타 위 각 호에 준하는 사유가 있다고 인정되는 경우\n
  1. 회사는 본 서비스를 이용하는 회원을 등급별로 구분하여 이용시간, 이용횟수, 서비스 내용 등을 세분하여 등급별로 그 이용에 차등을 둘 수 있습니다.\n

  ##### 제8조 개인정보 보호의무
  ‘회사’는 정보통신망법 등 관계 법령이 정하는 바에 따라 ‘회원’의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 회사의 개인정보취급방침이 적용됩니다. 다만, 회사의 공식 사이트 이외의 링크된 사이트에서는 회사의 개인정보취급방침이 적용되지 않습니다.

  ##### 제9조 ‘회원’의 계정 및 비밀번호 관리
  1. ‘회원’의 계정과 비밀번호에 관한 관리 책임은 ‘회원’ 본인에게 있으며, 이를 제3자가 이용하도록 하여서는 안됩니다.
  1. ‘회원’은 ‘회사’로 부터 부여 받은 계정을 변경할 수 없으며, 하나 이상의 계정을 가질 수 없습니다. 부득이한 사유로 변경하고자 하는 경우에는 ‘회원’ 탈퇴 후 재가입하여야 합니다.
  1. ‘회사’는 ‘회원’의 계정이 다음 각 호에 해당하는 경우 ‘회원’에게 계정의 변경을 요청할 수 있으며, 이 경우 회원은 회원 탈퇴 후 재가입 절차를 밟아야 합니다.
      A. 계정이 이용자의 전화번호 또는 주민등록번호 등으로 등록되어 사생활 침해가 우려되는 경우
      B. 타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우
  1. ‘회사’는 ‘회원’의 계정이 개인정보 유출 우려가 있거나, ‘회사’ 및 ‘회사’의 운영자로 오인한 우려가 있는 경우, 해당 계정의 이용을 제한할 수 있습니다.
  1. ‘회원’은 계정 및 비밀번호가 도용되거나, 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 ‘회사’에 통지하고 회사의 안내에 따라야 합니다.
  1. 제5항의 경우에 해당 ‘회원’이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 ‘회사’의 안내에 따르지 않아 발생한 불이익에 대하여 ‘회사’는 책임지지않습니다.
  1. ‘회사’가 제공하는 서비스의 계정은 회원 본인의 동의 하에 ‘회사’가 운영하는 다른 사이트의 계정과 연결될 수 있습니다.
  1. 기타 회원 개인정보 관리 및 변경 등에 관한 사항은 서비스별 안내에 정하는 바에 따릅니다.

##### 제10조 회원정보의 변경
  1. ‘회원’은 개인정보 관리화면을 통하여 언제든지 본인의 개인정보를 열람 및 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 실명, 계정, 생년월일 등은 수정할 수 없습니다.
  1. ‘회원’은 회원 가입 신청 시 기재한 사항이 변경되었을 경우, 온라인으로 수정하거나 전자우편 기타 방법으로 ‘회사’에 대하여 그 변경사항을 알려야 합니다.
  1. 제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.

##### 제11조 사이트의 탈퇴 등
  1. ‘회원’은 언제든지 사이트 내의 탈퇴 메뉴를 통하여 탈퇴 및 이용계약 해지 신청을 할 수 있으며, 회사는 관련법이 정하는 바에 따라 이를 즉시 처리하여야 합니다.
  1. ‘회원’이 탈퇴 및 이용 계약을 해지할 경우, 관련법 및 개인정보취급방침에 따라 ‘회사’가 ‘회원’ 정보를 보유하는 경우를 제외하고, 해지 즉시 회원의 모든 데이터를 삭제합니다.\n
  &nbsp;\n
  
#### 제3장 의무와 책임
  ##### 제12조 회사의 의무
  1. ‘회사’는 관련 법령이나 이 약관이 금지하는 행위 또는 미풍양속에 반하는 행위를 하지 않으며, 지속적이고 안정적인 서비스를 제공하기 위하여 최선을 다하여 노력합니다.
  1. ‘회사’는 ‘회원’이 안전하게 서비스를 이용할 수 있도록 ‘회원’의 개인정보보호를 위한 보안시스템을 구축하며, 개인정보취급방침을 공시하고 준수합니다.
  1. ‘회사’는 이용자가 이용한 전자금융거래에 오류가 발생할 경우를 대비하여, 이를 확인할 수 있는 기록을 생성/보존하여야 합니다.
  1. ‘회사’는 전자금융거래서비스를 제공함에 있어, 취득한 이용자의 인적 사항,계좌정보, 계정정보 및 전자금융거래의 내용과 실적에 관한 정보를 법령에 의하거나, 이용자의 동의를 얻지 아니하고 제3자에게 제공,누설하거나 사용해서는 안됩니다.
  1. ‘회사‘는 서비스를 이용하는 회원이 제기하는 의견이나 불만사항이 정당하다고 인정되면 즉시 처리합니다. 다만, 즉시 처리가 불가능한 경우에는 회원에게 그 사유와 처리에 소요되는 기간을 통지 합니다.
  1. ‘회사’는 ‘회원’이 수신동의 하지 않은 영리 목적의 광고성 전자우편을 발송하지 않습니다.
  1. ‘회원’의 탈퇴신청이 있는 경우 ‘회사’는 본인 확인 후 즉시 탈퇴 처리합니다.

##### 제13조 회사의 책임
  1. '회사'가 인증수단의 발급 주체이거나 사용, 관리 주체인 경우에 인증수단의 위조나 변조로 발생한 사고로, 계약체결 또는 거래지시의 전자적 전송등 처리과정에서 발생한 이용자의 손해는 '회사'가 그 손해를 배상할 책임을 집니다.
  1. ‘회사’는 전항에도 불구하고 다음 각호의 경우에는 이용자에 대하여 손해배상책임을 지지 않습니다.
      A. 회사가 인증수단의 발급주체가 아닌 경우로서 인증수단의 위조나 변조로 발생한 사고로 인하여 이용자에게 손해가 발생한 경우
      B. ‘이용자가’ 인증수단을 제3자에게 대여/양도 및 사용을 위임하거나 또는 담보 목적으로 제공하거나, 제3자가 권한 없이 이용자의 인증수단을 이용하여 전자금융거래를 할 수 있음을 알았거나 알 수 있었음에도 불구하고, 이용자가 자신의 인증수단을 누설 또는 노출하거나 방치한 경우
      C. 법인(‘중소기업기본법’ 제2조 제2항에 의한 소기업을 제외한다)인 ‘이용자’에게 손해가 발생한 경우로서 ‘회사’가 사고를 방지하기 위하여 보안절차를 수립하고, 이를 철저히 준수하는 등 합리적으로 요구되는 충분한 주의의무를 다한 경우
      D. ‘회사’의 관리 영역이 아닌 공중통신선로의 장애로 서비스 이용이 불가능한 경우
      E. 기타 ‘회사’의 귀책 사유가 없는 통신서비스 등의 장애로 인한 경우
  1. ‘회사’는 이용자로부터의 거래지시가 있음에도 불구하고 천재지변, 회사의 귀책사유가 없는 정전, 화재, 통신장애 기타의 불가항력적인 사유로 처리 불가능하거나 지연된 경우로서, 이용자에게 처리 불가능 또는 지연 사유를 통지한 경우(금융기관 또는 결제수단 발행업체나 통신판매업자가 통지한 경우를 포함합니다.)에는 이용자에 대하여 이로 인한 책임을 지지 아니합니다.
  1. ‘회사는 컴퓨터 등 정보통신설비의 보수점검, 교체의 사유가 발생하여 서비스의 제공을 일시적으로 중단할 경우에는 회사의 홈페이지를 통하여 이용자에게 중단 일정 및 중단 사유를 사전에 공지합니다.

##### 제14조 ‘회원’의 의무
  1. ‘회원’은 다음의 행위를 하여서는 아니 됩니다.
      A. 회원가입신청 또는 변경 시 허위내용을 등록하는 행위
      B. 다른 회원의 계정, 비밀번호 등 도용하는 행위
      C. 계정을 타인과 거래하는 행위
      D. ‘회사’의 운영진, 직원 또는 관계자를 사칭하는 행위
      E. ‘회사’로부터 특별한 권리를 부여 받지 않고 회사의 클라이언트 프로그램을 변경하거나, 회사의 서버를 해킹하거나, 웹사이트 또는 게시된 정보의 일부분 또는 전체를 임의로 변경하는 행위
      F. 서비스에 위해를 가하거나 고의로 방해하는 행위
      G. 본 서비스를 통해 얻은 정보를 회사의 사전 승낙 없이 서비스 이용 외의 목적으로 임의가공, 판매, 복제, 송신, 배포하거나, 이를 출판 및 방송 등에 사용하거나, 제3자에게 제공하는 행위
      H. 회사 또는 제3자의 저작권 등 지적재산권을 침해하거나, 침해하는 내용을 전송, 게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위
      I. 공공질서 및 미풍양속에 위반되는 저속, 음란한 내용의 정보, 문장, 도형, 음향, 동영상을 전송, 게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위
      J. 모욕적이거나 개인신상에 대한 내용이어서 타인의 명예나 프라이버시를 침해할 수 있는 내용을 전송, 게시, 전자우편 또는 기타의 방법으로 타인에게 유포하는 행위
      K. 다른 회원을 희롱 또는 위협하거나, 특정 이용자에게 지속적으로 고통 또는 불편을 주는 행위
      L. 회사의 승인을 받지 않고 다른 회원의 개인정보를 수집 또는 저장하는 행위
      M. 범죄와 결부되었다고 객관적으로 판단되는 행위
      N. ‘회사’의 업무에 방해되는 행위 및 회사의 명예를 손상시키는 행위
      O. ‘회사’의 명시적인 동의 없이 서비스의 이용권한, 기타 이용계약상의 지위를 타인에게 양도, 증여, 담보로 제공하는 행위
      P. 이 약관을 포함하여 기타 회사가 정한 제반 규정 또는 이용 조건을 위반하는 행위
      Q. 기타 법령에 위배되는 행위
  1. ‘회원’은 관계법령, 이 약관의 규정, 기타 ‘회사’가 정한 제반 규정, 서비스 이용안내, 공지사항 등 ‘회사’가 통지하는 사항 등을 준수하여야 합니다.
  1. ‘회원’은 ‘회사’의 사전 승낙 없이 서비스를 이용하여 영업활동을 할 수 없습니다. ‘회원’ 간 또는 ‘회원’과 제3자 간에 서비스를 매개로 하여 이루어진 거래는 ‘회사’와는 무관하며 ‘회사’는 이에 대해 어떠한 책임도 지지 않습니다.
  1. ‘회원’이 제3항을 위반하여 ‘회사’에 손해를 야기한 경우 ‘회원’은 ‘회사’에 그 손해를 배상해야 합니다.

##### 제15조 회원에 대한 통지
  1. ‘회사’가 회원에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 서비스 내 전자우편주소, 전자쪽지 등으로 할 수 있습니다.
  1. ‘회사’는 회원이 명시적으로 전자우편 수신을 거부한 경우에는 관련법령이나 본 약관에서 정한 통지의무를 다하기 위해 필요한 경우에는 전자우편을 발송할 수 있습니다.
  1. 회사는 회원 전체에 대한 통지의 경우 7일 이상 회사의 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.\n
  &nbsp;\n

#### 제4장 서비스의 이용

  ##### 제16조 서비스의 제공 및 변경
  1. ‘회사’는 ‘회원’에게 보상형, 투자형, 대출형, 혼합형 크라우드펀딩 서비스 등을 제공할 수 있습니다. 이때 서비스의 설계와 관련하여, 자본시장과 금융투자업에 관한 법률 등 관련 법규를 준수합니다.
  1. ‘회사’는 ‘회사’가 제공하는 콘텐츠와 진행하는 이벤트 등의 모든 서비스를 ‘회원’에게 제공합니다. 다만, 제6조 제3항에 따라 회원의 등급별로 제공하는 서비스에 차등을 둘 수 있습니다.
  1. ‘회사’는 서비스 변경 시 그 변경될 서비스의 내용 및 제공일자를 제15조에 정한 방법으로 회원에게 통지합니다.\n

  ##### 제17조 게시물의 관리
  1. ‘회사’는 ‘회원’이 등록한 게시물 및 자료가 변조, 훼손, 삭제되지 않도록 노력합니다.
  1. ‘회사’는 다음 각 호에 해당하는 내용이 포함된 게시물이나 자료에 대해 회원의 등록을 거부하거나, 사전통지 없이 삭제, 이동, 변경할 수 있습니다.\n
      A. 다른 ‘회원’ 또는 제3자에게 심한 모욕을 주거나 명예를 손상시키는 내용\n
      B. 공공질서 및 미풍양속에 위반되는 내용 또는 그러한 내용을 담고 있는 사이트에 접속할 수 있도록 링크시킨 내용\n
      C. 음란물을 게시하거나 음란물을 담고 있는 사이트에 접속할 수 있도록 링크시킨 경우\n
      D. 불법복제 또는 해킹을 조장하는 내용\n
      E. 영리를 목적으로 하는 광고나 스팸성 게시물인 경우\n
      F. 범죄에 결부된다고 객관적으로 인정되는 내용\n
      G. ‘회사’, 다른 ‘회원’, 제3자의 저작권 기타 권리를 침해하는 내용\n
      H. 등록하고자 하는 게시판 성격에 부합하지 않는 경우\n
      I. 상용 소프트웨어나 크랙 파일을 올린 경우\n
      J. 동일한 내용을 중복하여 다수 게시하는 등 게시의 목적에 어긋나는 경우\n
      K. 기타 회사의 제반 규정이나 관계법령에 위배되는 내용\n
  1. ‘회사’는 개별 서비스 별로 게시물과 관련된 세부이용지침을 별도로 정하여 시행할 수 있으며, ‘회원’은 그 지침에 따라 게시물(회원간 전달 포함)을 게재하여야 합니다.
  1. ‘회사’는 ‘회원’이 등록한 게시물이 서비스의 방향과 다를 경우 삭제할 수 있습니다.\n

  ##### 제18조 게시물에 관한 권리의 귀속
  1. ‘회사’가 작성한 저작물에 대한 저작권 및 기타지적재산권은 ‘회사’에 귀속됩니다.
  1. ‘회원’이 서비스 화면 내에 게시한 게시물의 저작권은 게시한 ‘회원’에게 귀속됩니다.
  1. ‘회원’이 저작권을 갖는 게시물에 대해서 ‘회사’는 이를 이용할 수 있으며,(‘회사’가 제휴를 맺고 있는 다른 ‘회사’가 운영하는 외부 사이트에 무상으로 메타 데이터를 제공하는 것을 포함합니다), 영리목적 유무와 무관하게 ‘회사’가 운영하는 사이트 내에서는 자유롭게 이용할 수 있습니다. ‘회원’이 본 약관에 동의할 경우 위와 같은 이용에 필요한 저작재산권 등을 ‘회원’이 ‘회사’에 부여한 것으로 간주하며, 이는 ‘회원’이 탈퇴한 후에도 유효합니다.
  1. ‘회사’는 ‘회원’이 저작권을 갖는 게시물에 대해서 제3항에서 정한 방법 이외의 방법으로 영리 목적으로 이용하고자 하는 경우 전화, 팩스, 전자우편 등의 방법을 통해 사전에 ‘회원’의 동의를 얻어야 합니다.

  ##### 제19조 광고게재
  1. ‘회사’는 서비스의 운영과 관련하여 서비스 화면, 홈페이지에 게재하거나, 메일, 쪽지, SMS등을 통하여 발송할 수 있습니다. 다만, ‘회사’는 ‘회원’에게 메일, 쪽지, SMS등의 발송을 하는 경우 수신 동의 여부를 확인하여, 수신 동의한 회원에 한하여 이를 발송합니다.
  1. 이용자가 서비스상에 게재되어 있는 광고를 이용하거나 서비스를 통한 광고주의 판촉활동에 참여하는 등의 방법으로 교신 또는 거래를 하는 것은 전적으로 이용자와 광고주간의 문제입니다. 만약, 이용자와 광고주간에 문제가 발생할 경우에도 이용자와 광고주가 직접 해결하여야 하며, 이와 관련하여 회사는 어떠한 책임도 지지 않습니다.\n

  ##### 제20조 서비스 이용시간
  1. 회사는 회원의 이용신청을 승낙한 때부터 서비스를 개시합니다. 다만, 일부 서비스의 경우에는 지정된 일자부터 서비스를 개시합니다.
  1. 회사는 서비스를 일정 범위로 분할하여 각 범위 별로 이용가능시간을 별도로 지정할 수 있습니다. 이 경우 그 내용을 회원에게 공지합니다.
  1. 본 서비스를 1일 24시간, 연중무휴로 제공합니다. 다만, 회사는 업무 또는 기술상 장애 또는 기술적 보완 및 사이트의 개선을 이유로 하는 경우 등에는 서비스가 일시 중지될 수 있습니다. 이러한 경우 회사는 사전 또는 사후에 이를 공지합니다.\n

  ##### 제21조 서비스 이용 제한 또는 중지 및 ‘회원’ 탈퇴
  ‘회사’는 다음 각 호에 해당하는 사유가 발생할 경우 ‘회원’의 서비스 이용을 제한 또는 중지시키거나, 회원을 탈퇴시킬 수 있습니다.\n
  1. ‘회원’이 ‘회사’의 서비스 운영을 고의 또는 과실로 방해하는 경우
  1. ‘회원’이 제12조에서 규정한 ‘회원’의 의무를 위반한 경우
  1. 한 회원이 하나 이상의 계정을 가지는 경우
  1. 정보통신설비 또는 시스템의 정기점검, 증설 및 교체, 보수 점검, 고장 등 공사로 인해 부득이 한 경우
  1. 전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를 중단한 경우
  1. 국가비상사태, 서비스 설비 장애, 서비스 이용 폭주 등으로 서비스 이용에 지장이 있는 경우
  1. 미풍양속을 저해하는 비속한 계정 또는 별명을 사용한 경우
  1. 관련 행정관청 또는 공공기관의 시정 요구 등이 있는 경우
  1. 3개월 이상 서비스를 이용하지 않는 경우
  1. 게시판에 인덱스 파일 없이 자료만 올려 놓는 등 게시판을 파일 자료실로 전용하는 경우
  1. 제15조 제2항에서 규정하는 게시물이나 자료를 게시, 등록한 경우
  1. 타인의 결제정보 등을 도용하거나 부정한 행위로 거래를 하는 경우\n

  ##### 제22조 프로젝트의 신청
  1. 개설자는 보상형, 기부형, 지분형, 혼합형 등 크라우드펀딩의 성격과 또한 목표금액 성공 시에만 자금을 받는 방식과 얼마가 모이든 모인 만큼 자금을 받는 방식 중 선택하여 프로젝트 개설을 신청하여야 합니다. 프로젝트를 개설하고자 하는 ‘회원’은 ‘회사’에서 제공하는 양식과 절차에 따라 프로젝트 개설을 신청할 수 있습니다. 이때 개설자는 프로젝트의 개설과 관련하여 회사와 별도의 계약을 체결할 수 있습니다.
  1. 신청된 프로젝트는 ‘회사’가 정한 다음의 기준과 운영 방향에 부합한지 여부를 심사한 후 승인된 프로젝트만 사이트 상에 게시됩니다.\n
      A. ‘회사’의 프로젝트 개설에 따른 규정과 절차 준수 여부\n
      B. ‘회사’가 제공한 프로젝트 개설 양식 기재 여부\n
      C. ‘회사’가 요구한 프로젝트 관련 파일 (이미지, 동영상, 음성 파일 등) 제공 여부\n
      D. 관계법령에 위배되거나 공공질서 혹은 미풍양속 저해 여부\n
      E. 타인의 명예를 손상시키거나 불이익을 주려는 목적이 있는지 여부\n
      F. 회사가 정한 각종의 프로젝트 심사 조건에 부합하는지 여부\n
      G. 기타 프로젝트 개설을 위해 필요에 따른 요청을 이행했는지 여부\n
  1. ‘회원’이 등록을 신청한 프로젝트 내용에 허위, 기재누락, 오기가 있어서는 안되며, ‘회원’이 이를 준수하지 않아 발생한 불이익에 대해서는 ‘회원’이 모든 책임을 부담해야 합니다.
  1. ‘회원’이 작성 및 제공한 프로젝트 내용 및 파일에 대한 모든 권리 및 책임은 해당 ‘회원’에게 있으며, ‘회사’는 ‘회원’이 등록한 프로젝트의 내용이 다음 각 호에 해당한다고 판단되는 경우 사전통지 없이 삭제할 수 있고, 이에 대하여 ‘회사’는 어떠한 책임도 지지 않습니다. 또한 사안에 따라 프로젝트를 등록한 ‘회원’에게 소명을 요청할 수 있습니다.\n
      A. ‘회원’이 제공한 회원정보 또는 프로젝트의 정보가 허위임이 판명된 경우\n
      B. 제3자의 지적 재산권 및 기타 권리를 침해하는 내용인 경우\n
      C. '회사'로부터 사전승인 받지 아니한 내용을 게시한 경우\n
      D. ‘회원’이 ‘회사’ 사이트와 게시판에 음란, 비방, 욕설, 명예훼손, 개인정보 무단공개 등 이에 준하는 게시물을 게재하거나 관련사이트를 링크하는 경우\n
      E. ‘회원’이 프로젝트 정보를 불법적으로 변경 또는 조작한 경우\n
      F. 기타 관계법령에 위반된다고 판단되는 경우\n
  1. 등록 승인된 프로젝트는 모집 기한이 마감된 후에도, 사이트 혹은 서비스 관련 웹사이트에 게시될 수 있습니다.\n

  ##### 제23조 프로젝트의 수정
  1. ‘회사’가 프로젝트를 승인하기 이전에는, 수정사항을 ‘회사’에 요청한 후, 회사가 승인한 범위 내에서만 수정이 가능합니다.
  1. 프로젝트가 승인된 이후에는 프로젝트 페이지의 일부 항목을 더 이상 변경하거나 취소 또는 삭제할 수 없습니다. 변경이 금지된 사항을 프로젝트 신청 ‘회원’이 임의로 수정, 변경하거나 삭제한 경우, 프로젝트에 참여한 회원이 손해배상 청구 등 법적 대응을 할 수 있으며, 이로 인한 불이익에 대하여 ‘회사’는 책임을 지지 않습니다.\n

  ##### 제24조 프로젝트의 취소
  1. 프로젝트가 승인되기 이전에는 자유로이 프로젝트의 취소가 가능하며, 취소는‘회사’에 요청함으로써 이루어집니다. 단, 프로젝트의 취소 이전까지 사용된 비용은 ‘회사’에 지불하여야 합니다.
  1. 프로젝트가 승인된 이후부터 마감되기 전에는 개설자가 자유로이 프로젝트 개설 신청을 취소할 수 없습니다.
  1. 프로젝트가 성공 마감된 후 취소는 불가합니다. 다만, 회사가 인정하는 객관적인 특별한 사유가 있는 경우에는 취소가 가능하지만, 이때에도 회사에 아래 제33조에 규정한 수수료를 지급해야 합니다.
  1. 프로젝트의 수정된 내용은 업데이트 통해 고지되며, 회원은 언제든 확인이 가능합니다.\n

  ##### 제25조 결제수단
  회원은 회사가 정한 방법(신용카드, 실시간 계좌이체, 가상계좌 등)을 통해 사이트 내에서 결제가 가능하며, 정확하고 안전한 결제 처리를 위해 관계법령에 따라 소비자 정보를 결제 대행사에 제공합니다.\n

  ##### 제26조 결제 취소 및 환불
  1. 결제의 취소 및 환불 규정은 전자상거래 등에서의 소비자 보호에 관한 법률 등 관련 법령을 준수합니다.
  1. 개별 서비스의 성격에 따라 회사는 별도 약관 및 이용조건에 따른 취소 및 환불 규정을 정할 수 있으며, 이 경우 개별 약관 및 이용조건 상의 취소 및 환불규정이 우선 적용됩니다.
  1. 기타 본 약관 및 사이트의 이용안내에 규정되지 않은 취소 및 환불에 대한 사항에 대해서는 소비자피해보상규정에서 정한 바에 따릅니다.\n

  ##### 제27조 목표금액 달성시에만 자금을 받는 방식의 프로젝트 실패 시 환불
  1. ‘회사’는 ‘회원’이 참여한 금액이 목표금액 달성시에만 자금을 받는 방식의 프로젝트가 프로젝트의 중도 철회 또는 신청한 목표 금액을 충족시키지 못해, 실패가 된 경우에는, 사유 발생일로부터 영업일 기준 10일 이내에 기존에 이루어진 참여 금액 결제에 대해 일괄적으로 취소 처리를 시행합니다.
  1. 위 제1항에도 불구하고 실제로 결제취소에 따른 환불 처리는 카드사 등 금융기관에 따라 완료되는 기간이 달라질 수 있습니다.\n

  ##### 제28조 취소 및 환불 수수료
  1. 회원의 단순 변심에 의한 취소일 경우 환불 처리에 발생하는 수수료는 회원이 부담합니다.
  1. 취소 및 환불 수수료는 결제 수단에 따라 다르게 처리되며, 취소 및 환불 수수료 부과 방법은 별도의 결제취소 및 환불규정을 따릅니다.\n

  ##### 제29조 프로젝트의 성공
  1. 목표 금액 달성시에만 자금을 받는 방식의 프로젝트의 펀딩금액 모집 결과는 회원이 지정한 프로젝트 기한이 마감된 시점의 결제된 펀딩금액을 기준으로 회원이 지정한 목표 금액 이상이면 성공, 미만이면 실패로 판단합니다.
  1. 얼마가 모이든 모인 만큼 자금을 받는 방식의 프로젝트는 성공과 실패가 구분되어 있지 않으며, 모인 금액만큼 개설자에게 해당 금액이 전달됩니다.
  1. 프로젝트의 최종 펀딩 금액에는 펀딩기한 마감 전의 취소 및 변경 신청이 반영될 수 있습니다.
  1. 프로젝트의 금액 모집이 실패한 경우, 그 동안 모집된 금액에 대해서 프로젝트 개설자는 어떠한 권리도 주장할 수 없으며, 회사는 사전에 고지한 절차에 따라 참여자들로부터 결제된 금액 전부를 일괄 취소합니다.\n

  ##### 제30조 펀딩금액 전달
  1. 모집된 금액의 지급과 관련하여 ‘회사’는 개설자의 신분증 사본, 통장사본, 주민등록등본, 사업자 등록증등 신분을 확인 할 수 있는 자료를 요청할 수 있으며, 이 밖에 회사가 요청한 자료를 제출해야만 정산금액을 정상적으로 전달 받으실 수 있습니다.
  1. 정산금액은 반드시 개설자의 계좌로 전달됨을 원칙으로 하며, 이 외의 계좌로의 전달은 ‘회사’가 정한 기준에 따라 승인될 수 있습니다.
  1. ‘회사’는 수수료를 제외한 금액을 개설자에게 지급하며, 수수료 금액에 대하여 세금계산서를 발급합니다. 개설자는 수령한 정산금액에 대하여 세금납부의 의무를 성실히 이행해야 합니다.
  1. 모금이 종료된 이후 지급요청이 있은 지 15일 이내에 개설자가 요청한 계좌로 입금합니다.
  1. ‘회사’와 개설자는 펀딩 금액이 투명하게 전달되고, 목적에 맞게 사용될 수 있도록 모든 노력을 기울여야 합니다.\n

  ##### 제31조 펀딩금액의 사용
  프로젝트의 개설자는 펀딩 금액 모집 성공에 따라 수령한 금액을 개설자가 약속한 프로젝트의 수행 및 완료, 약속한 상품의 이행 및 발송을 위한 경비 등으로만 사용하여야 하며, 이를 이행하지 않아 발생하는 불이익에 대해 모든 책임을 부담해야 합니다.\n

  ##### 제32조 리워드의 제공
  1. 프로젝트 개설자는 프로젝트 등록에 따라 발생하는 ‘회원’과 다른 ‘회원’, ‘회원’과 제 3 자 간의 약속, 참여자 상품 옵션 제공 등에 관하여 이행의 의무가 있으며, 이를 이행하지 않아 발생하는 불이익에 대한 책임은 해당 ‘회원’에게 있습니다.
  1. 프로젝트 개설자는 프로젝트 등록 시 참여자에 제공할 상품의 내용, 수량, 제공 일정을 사이트에 게시하고, 상품 금액 모집 성공 시 게시한 내용에 따라 이행할 의무가 있습니다. 이를 이행하지 않거나 고의로 게시한 내용과 다르게 혹은 일부만 이행하는 경우와 하자 있는 상품을 제공하는 경우에는 당해 회원은 회사에 의한 참여 금액의 회수 및 참여자에 의한 손해배상청구 등 모든 법적 책임을 부담합니다.
  1. 프로젝트 개설자는 프로젝트 및 참여자에게 제공할 상품과 관련한 참자들의 문의사항에 성실하게 답변 및 응대할 의무가 있습니다.\n

  ##### 제33조 수수료
  1. ‘회원’의 사이트 가입 및 서비스를 통한 프로젝트 개설 및 참여 무료이며, 프로젝트 성공 시에는 개설자에게 수수료가 부과됩니다.
  1. 목표금액 달성시에만 자금을 받는 방식의 프로젝트와 얼마가 모이든 모인 만큼 자금을 받는 방식의 프로젝트의 수수료율은 각각 다르게 적용됩니다.
  1. 수수료율의 결정을 위하여 ‘회사’와 개설자는 별도의 계약을 체결할 수 있습니다.
  1. 기타 서비스에 있어서, ‘회사’가 서비스 이용에 따른 수수료를 부과하고자 할 경우에는 부과기준 및 기타 상세한 내용을 시행 7일 전에 사이트에 고지합니다.\n
  
  ##### 제34조 번역서비스제공
  이 서비스에는 Google이 제공하는 번역본이 포함될 수 있습니다. Google은 상품성, 특정 목적의 적합성 및 비 침해에 대한 정확성, 신뢰성 및 묵시적인 보증을 포함하여 모든 명시 적 또는 묵시적 보증과 관련된 보증을 부인합니다.\n
  &nbsp;\n

#### 제5장 기 타

  ##### 제35조 책임제한
  1. ‘회사’가 제17조 제2항, 제21조 제1항에서 규정한 사유로 인해 서비스 제공을 제한 또는 중지시키거나 ‘회원’을 탈퇴시킨 경우, ‘회사’는 이로 인해 발생한 ‘회원’의 손해를 배상할 책임이 없습니다.
  1. ‘회사’는 ‘회원’이 전자우편 등 개인정보를 부실하게 기재하거나 또는 제9조 제2항에 따른 개인정보 변경의무를 다하지 아니하여, 발생한 손해에 대해 배상할 책임이 없습니다.
  1. ‘회원’이 사이트에 게재한 정보, 자료, 사실 등의 진실성, 정확성, 신뢰성은 ‘회사’가 보장하지 않습니다. 회원이 게시한 자료의 활용 여부는 회원 각자의 책임과 판단에 맡겨져 있으며, 그로 인해 야기되는 결과에 대해 회사는 일체의 책임을 지지 않습니다.
  1. 회사는 ‘회원’간 및 ‘회원’과 제3자간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없습니다. 회사는 서비스 이용과 관련하여 ‘회원’의 고의 또는 과실로 인하여 ‘회원’ 또는 제3자에게 발생한 손해에 대하여 책임을 부담하지 않습니다. 다만 관련법령에서 달리 규정하고 있는 사항이 있으면 그에 따릅니다.

  ##### 제36조 손해배상
  이용자가 본 약관상의 의무를 위반함으로 인하여 회사에 손해가 발생한 경우 이용자는 회사에 그 손해를 배상하여야 합니다.

  ##### 제37조 준거법과 관할법원
  1. ‘회사’와 ‘회원’은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 노력을 합니다.
  1. 전항의 경우에도 불구하고 분쟁으로 인한 소송이 제기될 경우 회사의 소재지를 관할하는 법원을 관할 법원으로 하여 해결토록 합니다.
  1. 회사와 이용자간에 발생하는 분쟁에 관하여는 대한민국법을 준거법으로 합니다.

  #### 부칙
  **이 약관은 2015년 10월 8일부터 효력을 발생합니다.**
  #### 부칙
  **이 약관은 2016년 10월 20일부터 효력을 발생합니다.**
  #### 부칙
  **이 약관은 2017년 1월 05일부터 효력을 발생합니다.**
  #### 부칙
  **이 약관은 2017년 8월 01일부터 효력을 발생합니다.**
  `,
};

export default terms;
