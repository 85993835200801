const privacy = {
  'privacy.title': 'プライバシーポリシー',
  'privacy.html': '',
  'privacy.markdown': `
  #### 1. 総則
  Makestar.coサービスを提供する(株)メイクスター(以下’当社’)はサービス利用のため会員か提供された個人情報を非常に重要に考え個人情報保護方針を樹立してこれを厳しく遵守しています。当社の個人情報保護方針は関連法令および韓国の情報通信部が制定した個人情報保護指針を遵守しており個人情報が流出されて被害を受けないように個人情報保護に最善をつくすつもりです。
  会員の皆さんはいつでもこの個人情報保護方針を閲覧して確認することができます。個人情報保護方針は法令また、政府の指針の変更やよりよいサービスの提供のためその内容が変更される場合があります。


  #### 2. 個人情報利用目的の特定
  当社が会員から個人情報を収集する理由と個人情報の利用目的は当社が運営するサイト(makestar.co)を通して会員に最適のサービスを提供するための目的で必要とする個人情報を収集しています。会員が提供した個人情報に有用な情報の提供や会員が要請したさまざまな意見に対する答えの提供などで使われています。


  #### 3. 個人情報の収集範囲または収集方法
  1. 収集の範囲
      * 当社は適法かつ公正な手続きと利用者の同意を受けた個人情報を収集します。そのため個人情報保護方針または利用規約の内容に対して「同意」をクリックできる手続きを用意して「同意」のボタンをクリックすると個人情報の収集について同意したと見なします。
      * 当社が収集する個人情報項目
        * 会員登録の際に収集する情報：氏名、メールアドレス、パスワード、ニックネーム、生年月日、性別、居住国、
        * プロジェクト参加の際に収集する情報：氏名、メールアドレス、住所、電話番号
  2. 収集の方法
      * ホームページを通じた会員登録また、変更、問い合わせメール、電話相談など。


  #### 4. 個人情報の利用及び提供
  1. 当社では収集した個人情報を次の目的のため利用することができます。
      * ホームページの会員管理：会員制サービスの利用などによる本人確認、個人識別、不良会員の否定、利用防止、使用防止、加入意思の確認、加入、加入回数の制限、告知事項の伝達、問い合わせの処理など。
      * メールサービスの提供。
      * アンケートやイベントのための利用。
      * コンテンツまた会員のニーズに合わせたサービスを提供、サービスの購買または決済、金融取引の本人認証また金融サービス、商品の注文による配送サービス。
      * 新規サービス（コンテンツ）の開発または特化、イベントなど広告情報の伝達、通計によるサービスの提供また広告の掲載、アクセス頻度の把握、会員のサービス利用に対する通計。
  2. 会員が提供した個人情報は本人の同意なしでは会社が告知した目的の範囲以外で利用したり第３者に提供することはできません。但し、次の場合には例外とします。
      * 捜査を目的として法令に定められた手続きと方法によって、捜査機関などの要求がある場合。
      * 韓国の金融実名取引及び秘密保障に関する法律、信用情報の利用及び保護に関する法律、電気通信基本法、電気通信事業法、地方税法、消費者保護法、韓国銀行法、刑事訴訟法など法令に特別な規定がある場合。
      * 韓国の法令の規定に基づき、統計目的、学術研究、市場調査などに特定の個人を識別できない形で個人情報を提供する場合。
  3. 会員の同意を貰う場合にも会社は次の事項を事前に明示するか告知するべきであり、会員はこれについて断る権利を持ちます。また、いつでも同意を撤回することができます。
      * 個人情報管理の責任者の身元（所属、名前、電話番号）
      * 個人情報の収集目的また、利用目的
      * 収集しようとする個人情報の項目
      * 収集しようとする個人情報の保有期間また利用期間
      * 第３者に対する情報提供の関連事項
      * 会員の同意撤回権や自分の情報に対する閲覧、訂正要求権またその行使方法
      * 他に韓国の 情報通信網利用促進に関する法律など関係法令が定めた事項
  4. メールアドレスなど個人情報が非正確でサービスの品質に支障を来たす場合にはホームページの運営者が該当の情報を削除するか修正することができます。
  5. 会員がオンライン上で自発的に提供する個人情報は第３者が収集して使用できますしその責任は会員が負います。
  6. また、業務遂行のため、個人情報を確認することはアクセス権限がある業務担当者(個人情報取扱者)に限定されています。


  #### 5. 個人情報の第3者提供
  1. 当社は会員の個人情報を「4.個人情報の利用及び提供」で告知した範囲内で使用し、 会員の事前同意なしに「4.個人情報の利用及び提供」範囲を超えて利用したり原則的に利用者の個人情報を第3者に提供しません。 但し、下記の場合は例外とします。
      * 利用者が事前に公開、または第3者提供に同意した場合。
      * 法令の規定に基づいたり捜査、調査の目的として法令に定められた手続きと方法によって捜査機関や監督当局の要求がある場合。
      * 料金の精算をするため必要な場合。
  2. そのほか個人情報の第3者提供が必要な場合には利用者の同意を得る法な手続きを通じて第3者に個人情報を提供することができます。 当社は利用者たちの取引の履行のために必要な場合利用者の同意を得るなど適法な手続きを通じて、次のように個人情報を提供することができます。
  **個人情報 第3者 提供**
      * 共有される者 : プロジェクト開設者
      * 共有する項目 : 名前、ID、携帯番号、メールアドレス、住所、電話番号
      * 共有する項目 : 名前、ID、携帯番号、メールアドレス、住所、電話番号
      * 保有及び利用期間 : 配送完了後1ヶ月


  #### 6. プライバシーポリシーに対する会員の権利または行使方法
  1. 当社は会員の個人情報に対する権利を最大に尊重します。
  2. 会員の訪問、電子署名または会員IDなどを利用して当社が持っている自分の個人情報に対する閲覧また誤りを訂正を要求するかすでに同意してことを撤退することは出来ます。当社はか会員確認をした上に必要な措置をとります。
  3. 会員は誤りの訂正を要求した場合に当社はその誤りを訂正するまで個人情報を利用せず、訂正措置を行った場合には、その事実を遅滞なく会員に通知します。
  4. 会員IDで個人情報を閲覧および訂正を希望する場合は、登録されたIDや暗証番号を使用し、ログイン(log-in)された状態で顧客センターの個人情報管理メニューを通じて、いつでも個人情報を閲覧・確認し訂正することができます。


  #### 7. 個人情報の保有また、利用期間
  原則的に個人情報収集及び利用目的が達成された後、(会員脱退、並びに電子メールサービスの解約)には、該当情報を遅滞なく破棄します。 但し、次の事由については会社および関連法令に明示されている期間の間に保有します。
  1. 当社の内部の方針による情報保有事由
      * メールアドレス
        * 保存理由 : 不良会員の不正利用防止と用途以外の使用防止及びサービス利用の混乱防止
        * 保存期間 : 1年
  2. 関係法令による情報保有事由
      * ウェブサイトのアクセス記録
        * 保存理由 : 韓国通信秘密保護法
        * 保存期間 : 3ヶ月
      * 本人確認に関する記録
        * 保存理由 : 韓国情報通信網利用促進及び情報保護に関する法律
        * 保存期間 : 6ヶ月
      * 表示・広告に関する記録
        * 保存理由 : 韓国電子商取引などでの消費者保護に関する法律
        * 保存期間 : 6ヶ月
      * 契約又は契約取り消しなどに関する記録
        * 保存理由 : 韓国電子商取引などでの消費者保護に関する法律
        * 保存期間 : 5年
      * 代金決済や物などの供給に関する記録
        * 保存理由 : 韓国電子商取引などでの消費者保護に関する法律
        * 保存期間 : 5年
      * 消費者の苦情又は紛争処理に関する記録
        * 保存理由 : 韓国電子商取引などでの消費者保護に関する法律
        * 保存期間 : 3年


  #### 8. 個人情報の保護に対する技術的管理対策
  1. 当社は個人情報が流出されないように技術的、管理的な対策を講じるため最善を尽くします。
  2. 当社は、利用者の個人情報を取り扱うことにおいて、個人情報が紛失、盗難、流出、変調または毀損されないよう安定性確保のために最善を尽くしています。 このためパスワードなどを利用したセキュリティ装置、ワクチンプログラムを利用したコンピュータウイルス防止装置、暗号アルゴリズムなどを利用してネットワーク上に個人情報を安全に転送できる保安システム、侵入遮断システムなどを利用した接近統制装置、その他の安全性確保のために必要な技術的装置を確保するなどの技術的対策を講じています。
  3. 当社は個人情報のアクセスが可能な職員を最小限に制限して、本政策の遵守および個人情報保護のため持続的な教育を実施しています。 また、個人情報接近のための運営者パスワードを定期的に更新し、不法な情報流出を防ぐために最善を尽くしています。


  #### 9. クッキ（cookie）運営に関する事項
  1. 当社は会員に対する情報を保存して、随時捜し出す”クッキー(Cookie)”を使用します。 クッキーはホームページが会員のコンピューターブラウザのみで伝送する少量の情報です。会員がホームページに接続し、当社のコンピューターは会員のブラウザにあるクッキーの内容を読んで、会員の追加情報を会員のコンピューターで探して接続による声明、ホームページのアドレスなどの追加入力でサービスを提供できるようにしています。
  2. クッキーは会員のコンピューターは識別し会員を個人的に識別することはありません。また、会員はウェブブラウザのオプションを調節することで全てのクッキを識別するかクッキが設置されるとき通知を送るか全てのクッキを拒否できる選択権を持ちます。
  3. クッキー設定を拒否する方法(インターネット・エクスプローラー)ウェブブラウザの上段のツール>インターネットオプション>個人情報のタブ>個人情報取扱いの水準で設定利用者が使用するウェブブラウザのオプションを設定することによりすべてのクッキーを許可したり、クッキーを保存するたびに確認を経たり、全てのクッキーの保存を拒否することができます。 但し、クッキーの保存を拒否する場合、ログインが必要な一部のサービスの利用に制限が生じかねないことを了承してください。


  #### 10. 広告性の情報伝送
  当社は会員の明示的な受信拒否の意思に反して営利目的の広告性情報を伝送しません。 当社メーリングサービスは、より良いサービス提供のためにニュースレターやサービス情報案内、アンケート調査とイベントを行うことができ、受信拒否の意思表現を容易にできるように下段に表示しています。この時、統計及びリターン発送のために収集した最小限の個人情報は景品発送/搬送終了後1ヵ月)以降に廃棄します。


  #### 11. Liiv Mate流入会員第3者情報同意
  1. 対象：‘Liiv Mate’ アプリケーション内 ‘一緒に後援’サービスからMakestarモバイルウェブページにアクセスした会員
  2. 提供を受ける者: (株)LG U+
  3. 提供する項目:
      * 1)Makestar会員登録の時記入した後援者の個人情報: 氏名、年齢、性別、連絡先、メールアドレス等
      * 2)プロジェクト及びファンディング（決済）関連後援者統計情報：ファンディングしたプロジェクト名、ファンディング金額、リターン等
      * 3)Makestarアクセス関連後援者統計情報：Makestarアクセス履歴、チェックしたプロジェクト名
  4. 提供を受ける者の個人情報活用目的：'一緒に後援'及びMakestarサービス利用に対する統計/分析、利用頻度把握及び統計学的特性によるサービス提供、サービス開発、特殊化、顧客関係管理、（告知事項の伝達、サービス利用状況確認等）、イベント等
  5. 保存及び利用期間: Liiv Mate アプリケーション内'一緒に後援'サービス利用期間

  #### 12. 個人情報の管理義務、責任者
  当社は、個人情報に対する意見収集および苦情処理を担当する個人情報管理担当者を指定しています。個人情報管理責任者は次のようです。

  [個人情報管理責任者]

  * 姓名: ユンイラン (Yoon Yirang)
  * 電話番号 : 02-6959-2076
  * E-MAIL : privacy@makestar.co
  `,
};

export default privacy;
