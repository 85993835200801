import info, { InfoType } from './info';
import { combineReducers } from 'redux';

export default combineReducers({
  info,
});

export interface StoreState {
  info: InfoType;
}
