import privacy from './term/privacy';
import terms from './term/terms';

const en: Record<string, string> = {
  ...terms,
  ...privacy,
  loginUpperCase: 'LOGIN',
  signUpUpperCase: 'SIGN UP',
  login: 'login',
  acceptTerms: 'Accept Terms',
  signUp: 'Sign Up',
  password: 'Password',
  passwordConfirm: 'Confirm Password',
  email: 'Your Email',
  name: 'Name',
  nickname: 'Nickname',
  birth: 'Birth',
  gender: 'Gender',
  genderMale: 'Male',
  genderFemale: 'Female',
  genderOther: 'Other',
  genderRNS: 'Rather Not Say',
  country: 'Country of residence',
  required: 'Required',
  tempPassword: 'Temporary password.',
  findPassword: 'Password Retrieval',
  signupComplete: 'Signed Up',
  emailInvalidMessage: 'Please enter a valid email address.',
  loginFailMessage:
    'There was a problem logging in.  Check your email and password and try again',
  termsAllMessage:
    "In order to proceed with the usage of Makestar's service, you need to agree to the terms of service.",
  DOBInvalidMessage: 'Please enter a valid date. ',
  DOBAgeRestrictionMessage:
    'Entry is restricted to anyone under the age of 13.',
  passwordInvalidMessage: 'Must be length of 6 to 20 characters.',
  passwordConfirmNotMatchMessage:
    'Password does not match. Please enter again.',
  nameInvalidMessage: 'Must be at least 2 characters or more.',
  nicknameInvalidMessage: 'Must be at least 3 characters or more.',
  emailConflictMessage: 'That email is already in use.',
  nicknameConflictMessage: 'That nickname is already in use.',
  noSearchResultMessage: 'No Results.',
  verifyFanclub: 'Verify Fan Club',
  findPasswordMessageOne:
    'Your temporary password will be sent to this address.',
  findPasswordMessageTwo: 'Enter the email address used on sign up.',
  failureSuccessMessage:
    'The 24 hour authentication period may be over,\nor a wrong authentication code was used.',
  fanclubVerifySuccessMessage: 'Verification for {fanclub} has been completed.',
  signupCompleteCongratulationsMessage:
    '{ name } Congratulations on\nbecoming a star maker',
  tempSuccessMessage1: 'Temporary password issued.',
  tempSuccessMessage2:
    "Login with your temporary password and don't forget to register a new password.",

  // new
  toiLoginPage: 'To login page',
  tempPasswordCompleteMessage: 'A temporary password has been sent to {email}.',
  checkSpamMailMessage: 'Please check the spam folder if it has not arrived.',
  notUserMailMessage: 'The email address you have entered is not registered.',
  inputValidMakestarIdMessage: 'Please log in with your existing MAKESTAR ID.',
  signupMessage: "Still don't have an account for MAKESTAR?",
  forgotPasswordMessage: 'Forgot your password?',
  failureMessage: 'Verification failed.',
  reLoginMessage: 'To participate in the project,\nplease log in again.',
  tempPasswordLoginMessage: 'Log in with the temporary password.',
  requestErrorMessage:
    'An error occurred during a request.\nPlease try again. ',
  invalidValueMessage: 'Enter the field accurately. ',
  allFieldIsRequiredMessage: 'All fields must be entered.',
  reLoginForFanclubAuthMessage:
    'Please complete the fan club verification process by logging in.',
  termsConfirm: 'Terms and Conditions',
  allAgreeTerms: 'Agree to all Terms and Conditions',
  tempPasswordSend: 'Send a temporary password',
  toMakestar: 'Go to MAKESTAR',
  toProject: 'Go to KNK project',
};

export default en;
