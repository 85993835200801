const terms: Record<string, string> = {
  'terms.title': '服务条款',
  'terms.html': '',
  'terms.markdown': `
  #### 第一章 总则

  ##### 第1条【目的】
   本协议旨在规定在使用麦克星达有限公司(以下简称“公司”)运营的网站makestar.co提供的众筹等互联网相关服务（以下简称“服务”）时，关于公司与用户的权利及义务、责任，会员使用服务步骤的有关事项。\n

  ##### 第2条【定义】
   本条款使用的用语定义如下。\n
  1. “公司”是指麦克星达有限公司。
  1. “网站”是指公司运营的网站makestar.co。
  1. “服务”是指由“公司”提供的与接收终端形式(包括电脑,电视,携带终端等各种有线无线装置)无关,用户们可以使用的各种服务。
  1. “众筹”是指通过公司提供的服务，不特定的多数会员对项目发起人发起的项目进行资金支持或投资从而达到集资的目的。
  1. “用户”是指使用网站，根据本协议获得公司所提供服务的会员或非会员。
  1. “会员”是指使用公司的服务，根据本协议与公司签订使用合同并使用公司提供的服务的个人或法人。
  1. “非会员”是指不加入会员,只使用公司所提供的部分服务的个人或法人。
  1. “发布内容”是指用户在使用服务时发布在网站的所有符号、文字、音乐、音频、图片、视频等各种文件和链接等。
  1. “项目”是指会员为了获得其他会员的资金支持或投资而在公司提供的平台开设的服务。
  1. “发起人”是指通过“公司”提供的服务发起资金支持项目或者投资项目的“会员”。
  1. “参与者”是指通过“公司”提供的服务决定要支持的“项目”和支持金额，对发起人进行支持的“会员”。
  1. “回报”是指在“项目”达到成功后，发起人承诺于参与者的礼物。
  1. “投资者”是指通过“公司”提供的服务决定要投资的“项目”和金额，对发起人进行投资的“会员”。
  1. 在本服务协议未经定义的用语，均遵守交易惯例或相关法律法规。\n

  ##### 第3条 【条款的明示和修改】
  1. “公司”为了让用户更方便地阅读本服务条款，本条款将明示于网站。
  1. “公司”在不违反《资本市场和金融投资业相关法律》、《电子交易等消费者保护相关法律》、《条款规定相关法律》、《电子文件及电子交易基本法》、《电子署名法》、《促进情报通信网使用的相关法律》、《访问贩卖等相关法律》、《消费者保护法》等相关法律的范围内可以对本条款进行修改。
  1. “公司”修改条款的情况下，修改的内容从指定的执行日开始最少7天前（但，对于用户的权利和义务有重大影响的事项需在30天前）对修改的内容进行明示并且公告在网站上。另外，现有会员则通过会员注册时登记的电子邮件通知。
  1. 根据第3项，在公司向用户通知·公告日起，条款的修改日后7天内为止没有表示不承认、拒绝意向的用户的情况下，修改的条款通过并开始执行。
  1. 会员若不同意修改的条款，则可向公司申请注销会员。\n

  ##### 第4条【条款未定事项】
   关于在本服务条款中未定的事项或解释，均依据相关法律法规和商业条例。\n

  ##### 第5条 【服务的性质与目的】
  1. “公司”提供的服务是为了帮助“会员”与“会员”相互间的交易能够顺利进行而提供的网上平台，“公司”不会向“会员”提供借款或接受借款。
  1. 与“会员”之间成立的交易相关的责任由作为交易当事人的会员自己承担。但是，“公司”可以在法律允许范围内向会员提供解决会员之间发生的问题所需要的相关文件。\n
&nbsp\n\n
  #### 第二章 会员注册等

  ##### 第6条 【会员注册】
  1. 想要成为“会员”的人（下称“申请加入者”）在本网站注册成为“会员”即视作同意协议内容，注册申请若通过“公司”审核 ，即成功注册为“会员”。
  1. “申请加入者”在仔细阅读本《服务条款》和公司的《隐私权政策》后点击“同意”按键或者选择“确认”等情况下表示同意本条款。
  1. 匿名，假名等不用实名注册的用户无法作为会员进行统一管理。借用他人的名义注册会员的用户将删除账号，并且根据相关法律法规给予一定的处罚。
  1. 我们的原则是一个“会员”只能拥有一个账号，对于拥有一个以上账号的“会员”，我们有权利删除账号或对该“会员”采取注销及禁止再注册措施。 而且拥有一个以上账号的“会员”，不能对一个以上的其他账号实行任何权利。
  1. 完成网站注册后，申请者的会员等级为‘未认证会员’，必需通过邮箱或特殊手段进行注册认证，才能成为正式会员，使用网站的所有服务。
  1. 帐号申请注册网站后，如果‘未认证会员’状态超过30日，注册申请会被自动取消，帐号信息也会被删除。
\n
  ##### 第7条 【使用申请的通过与限制】
  1. “公司”对于申请加入者的申请在没有特殊原因的情况下，一般通过申请。但是，在包括但不限于下列行为时，公司将不予通过或者即使通过了也可以要求其注销。\n
      A. 非实名或借他人名义申请的情况\n
      B. 依据本条款，申请者之前有丧失会员资格的情况，但，公司同意“会员”再加入的情况除外\n
      C. 填写虚假信息，或未填写公司所要求的内容的情况\n
      D. 为了妨碍社会的安定、秩序和公序良俗而申请加入的情况\n
      E. 为了不正当的用途而使用本服务的情况\n
      F. 妨碍他人使用服务或者盗用他人信息等威胁电子商务秩序的情况\n
      G. 违反条款或者公司的各项规定下申请的情况\n
      H. 与本服务处于竞争关系的用户申请的情况\n
      I. 由于用户的责任事由而无法通过或违反其他规定时申请的情况\n
      J. 申请注册时申请者的年龄不满13岁时\n
      K. 使用在一定时期内有效的邮箱进行注册时\n
  1. “公司”在包括但不限于以下行为时，对于使用申请可以进行限制或者保留。
      A. 与“公司”的服务相关的设备没有足够空间的情况\n
      B. 提供的服务存在技术问题的情况\n
      C. 未成年的情况\n
      D. 其他承认包含于上述各项所称述的事由的情况。
  1. 公司将使用本服务的会员进行等级划分。按使用时间、使用次数、服务内容等细分项目进行等级划分。\n

  ##### 第8条 【个人隐私保护义务】
   “公司” 依据《信息通信网法》等相关法令努力保护“会员”的个人信息。个人信息的保护和使用，依据相关法律及公司的《隐私权政策》。但是，除公司的正式网站以外所链接的网站上，公司的《隐私权政策》不适用。\n

  ##### 第9条 【“会员”的账号及密码管理】
  1. “会员”的账号和密码的管理责任在于“会员”本人，第三方不得使用。
  1. “会员”不得变更“公司”提供的账号，不得拥有多个账号。因不得已的原因需要进行账号变更时，需注销“会员”后再注册。
  1. “公司”在“会员”账号出现下列情况时，有权要求“会员”变更账号。这种情况下，会员必须先注销账号再重新注册。\n
      A. 账号使用了用户的电话号码或者身份证号码等有可能侵害私生活的情况\n
      B. 带给他人厌恶感或违反公序良俗的情况。\n
  1. “公司”对于“会员”账号有可能泄露个人信息，或有可能被误认为是“公司”或者“公司”的运营者的情况，有权限制该账号的使用。
  1. “会员”在得知账号及密码被盗用或被第三方使用的情况下，必须立即通知公司并在公司的引导下行动。
  1. 符合第5项条款的“会员”，未通知公司或者通知了公司却未按照“公司”的引导行动而产生的损失，公司不承担任何责任。
  1. “公司”提供的服务账号在会员本人的同意下可与“公司”经营的其他网站的账号连用。
  1. 会员个人信息管理、变更等其他相关事项，依据服务中的相关规定。\n

  ##### 第10条 【会员信息的变更】
  1. “会员”通过个人信息管理画面，可随时浏览及修改本人的个人信息。但是，服务管理所必需的姓名、账号、出生日期等不可修改。
  1. “会员”在变更注册申请当时登记的事项时，须在线修改或者通过电子邮件等其他方法告知“公司”变更的事项。
  1. 第2项所陈述的会员变更事项却未告知公司的情况下所产生的损失，公司不承担任何责任。\n

  ##### 第11条 【账号注销等】
  1. “会员”可随时通过网站内“我的注销菜单”注销及解除使用合同，公司必须立即根据相关法律法规进行处理。
  1. “会员”在注销及解除使用合同的情况，依据相关法律及《隐私权政策》，“公司”保留“会员”信息的情况除外，公司须在解除的同时立即删除会员的所有数据。\n
&nbsp;\n
  #### 第3章 义务与责任

  ##### 第12条 【公司的义务】
  1. “公司”不会进行相关法律法规和本条款禁止的或者违反公序良俗的行为，并且会尽一切努力提供持续、稳定的服务。
  1. “公司”为了“会员”能安心地使用服务，为保护“会员”的个人信息而建构了安全系统，明示了《隐私权政策》并严格遵守。
  1. “公司”为了解决用户在进行电子交易中遇到的问题时，需生成或保存可以确认问题的记录。
  1. “公司”提供电子金融交易服务时取得的用户个人信息、用户银行账户、用户账号和电子金融交易的内容或者资料，相关法律、法规或法院、政府机关要求除外，未得用户的同意不得向第三方提供、泄露。
  1. “公司”觉得使用服务的会员提出的意见或不满事项合理时，将承认并立即进行处理。但，无法立即处理的情况下，会通知会员无法立即处理的理由和所需的时间。
  1. “公司”不会向未选择收信同意的“会员”发送以营利为目的的广告邮件。
  1. 在“会员”提交注销申请的情况下，公司在进行本人确认后将立即处理。\n

  ##### 第13条 【公司的责任】
  1. 因仿造或伪造认证手段而发生的事故（但，由公司发行或管理认证手段的情况除外），在签合同过程或者交易指示的电子传送或处理过程中发生的事故对用户造成损失的情况下,公司不承担任何责任。
  1. 不仅限于以上条款，对于下列各种情况对用户造成损失时，“公司”不承担任何责任。\n
      A. 非公司发行的认证手段，因伪造或仿造认证手段而发生事故对用户造成损失的情况\n
      B. ‘用户’ 向第三方用户转让/租赁,委任或以担保为目的提供认证手段, 在知道第三方用户可能在无权限的情况下使用“用户”的认证手段进行电子金融交易却放任不管或泄露用户本人的认证手段的情况\n
      C. 用户是法人 (《中小企业基本法》第2条第2项所规定的小企业除外)的情况, 为防止发生事故对其造成损失, “公司”会履行建立并严格遵守安全程序等合理要求的义务\n
      D. 不属于“公司”的管理领域而是由公众通信线路的障碍引起服务无法使用的情况\n
      E. 其他不归咎于“公司”的通信服务等障碍引起的无法使用服务的情况\n
  1. “公司”即使受到来自用户的交易指示, 但因自然灾害、责任不在公司的停电、火灾、通信障碍等其他不可抗力引起推迟或无法处理，并且通知了用户推迟或无法处理的理由的情况，用户的损失，公司不承担任何责任。
  1. 公司电脑等通信设备的检查、维修、更换等引起提供的服务将暂时中断的情况，公司会在官网上提前通知用户中断的日程及理由。\n

  ##### 第14条 【“会员”的义务】
  1. “会员”不得进行包括但不限于以下的行为。\n
      A. 申请会员或变更会员信息时登记虚假信息的行为\n
      B. 盗用其他会员的账号、密码等行为\n
      C. 与他人进行“账号”交易的行为\n
      D. 冒充“公司”的运营团队、职员及相关负责人的行为\n
      E. 未得到“公司”赋予的特别权利而更改公司的客户端软件，或对公司的服务器进行黑客攻击，任意更改网站信息，或变更部分或者全部已上传信息的行为\n
      F. 危害或故意妨碍服务的行为\n
      G. 通过本服务获得的信息在事前未得到公司许可的情况下，用于服务使用以外的目的，任意加工、销售、复制、传送、发布、出版及播放等，或者向第三方提供的行为\n
      H. 侵害公司或第三方的著作权等知识产权，或传送、发布、通过电子邮件等媒介或者其他方法向他人传播侵害知识产权内容的行为\n
      I. 传送、发布、通过电子邮件等媒介或其他方法向他人传播违反公共秩序及公序良俗的包含低俗、淫秽内容的信息、文章、图片、音频、视频等的行为\n
      J. 传送、发布、通过电子邮件等媒介或者其他方法向他人传播侮辱或有可能侵害他人名誉或隐私权内容的行为\n
      K. 骚扰或威胁其他会员，给特定用户造成持续困扰或不便的行为\n
      L. 未得公司批准，收集或储存其他会员的个人信息的行为\n
      M. 被判定犯罪的行为\n
      N. 妨碍“公司”业务及损失“公司”名誉的行为\n
      O. 没有“公司”明确的同意，向他人转让、赠予、抵押或担保服务的使用权限和其他使用合同上的权利的行为\n
      P. 违反包括本条款在内的公司制定的其他各类规定及使用条件的行为\n
      Q. 其他法律法规禁止的行为。\n
  1. “会员”必须遵守相关法律法规、本条款及“公司”制定的各种其他规定、服务使用合同和公告等由公司通知的事项。
  1. “会员”在未得到“公司”批准的情况下，禁止利用服务进行营利活动。“会员”与“会员”，或者“会员”与第三方之间以服务为媒介进行的交易，与“公司”无关，对此公司不承担任何责任。
  1. “会员”在违反第3项时，对“公司”造成损失的情况“会员”须对公司进行赔偿。\n

  ##### 第15条 【对会员的通知】
  1. 对于未收订于本条款的规定，“公司”有权在服务范围内通过电子邮件地址、站内信等方式通知会员。
  1. 会员已公开拒绝接受邮件的情况下，依据相关法律法规或为履行本条款规定的通知义务等情况所需时，“公司”有权向“会员”发送电子邮件。
  1. 需对全体会员进行通知的情况，公司有权在公司的公告栏上公告7天以上，来取代第1项陈述的通知方式。\n
&nbsp;\n
  #### 第4章 服务的使用

  ##### 第16条 【服务的提供及变更】
  1. “公司”会为“会员”提供回报型、贷款型、股份型及综合型等众筹服务。这时关于服务的内容，遵照《资本市场和金融投资业相关法律》等相关法律法规。
  1. “公司”会为“会员”提供所有公司能提供的服务，包括内容和进行的活动等。但，依据第6条第3项服务会根据会员的等级进行差别化提供。
  1. “公司”在变更服务时，要通过第15条所规定的方法通知会员变更的服务内容。\n

  ##### 第17条【发布内容的管理】
  1. “公司”尽力保护“会员”发表的内容及资料不被更改、损坏、删除。
  1. 若“会员”的发布内容出现包括但不限于以下内容时，“公司”有权拒绝发布或直接删除、移动和变更。\n
      A. 侮辱或诽谤其他“会员”或他人，损失他人名誉的\n
      B. 违反公共秩序和公序良俗，以及含有链接到发布有此类内容的网页链接的\n
      C. 散布淫秽、色情等内容，以及含有链接到发布有此类内容的网页链接的\n
      D. 包含违法复制、黑客行为的\n
      E. 以营利为目的的广告及垃圾内容\n
      F. 违法犯罪的\n
      G. 侵害“公司”，其他“会员”、他人的著作权及其他权利的\n
      H. 不符合网站版块规定的\n
      I. 上传商用软件或破解文件的\n
      J. 重复上传相同内容，不符合发布目的的\n
      K. 其他违反公司各类规定及相关法律法规的。
  1. “公司”有权制定并执行与个别服务的发布内容相关的详细方针，“会员”须按照方针发布内容（包括会员间的转发）。
  1. “公司”有权删除“会员”发布的与公司服务方向不符的内容。\n

  ##### 第18条 【关于发布内容的所有权】
  1. 由“公司”撰写的发布内容著作权及知识产权归“公司”所有。
  1. “会员”发布在服务页面上的内容，所有权归“会员”所有。
  1. 对于“会员”拥有所有权的发布内容，“公司”拥有可以免费的、永久有效的、不可撤销的、非独家的、可转授权的、在全球范围内对所发布内容进行使用、复制、修改等创造衍生性著作的权利，或可以将前述部分或全部内容加以传播展示或放入任何现在已知和未来开发出的任何形式的媒介（包括向与“公司”联盟的其他“公司”无偿提供数据）中。“会员”同意本条款，则公司同时也拥有知识产权，即使“会员”注销后依然有效。
  1. “公司”若想将“会员”拥有所有权的文章以营利为目的使用在第3项所规定的以外的地方的情况，需通过电话、传真、电子邮件等方法事前得到“会员”的同意。\n

  ##### 第19条 【广告刊登】
  1. “公司”有权在服务页面、主页刊登或通过邮件、站内信、SMS等发送与服务的运营有关的内容。但，“公司”在给“会员”发送邮件、站内信、SMS等情况时需事前确认会员是否同意收信。只能对同意收信的会员进行发送。
  1. 用户在使用服务页面上刊登的广告或者通服务参与广告主的促销活动等进行交流或交易，则全部责任在于用户和广告主。万一，用户与广告主之间发生问题，用户需和广告主直接解决，与此相关的，公司不承担任何责任。\n

  ##### 第20条 【服务使用时间】
  1. 公司在通过会员的使用申请同时开始提供服务。但，部分服务在指定日提供。
  1. 公司有权将服务分类，根据各个类别制定使用可能时间。当出现这种情况时公司有义务通知会员。
  1. 本服务一天24小时，全年无休。但，因公司的业务或技术上的障碍或技术性的维修以及网站的更新等原因，服务有可能一时中断。出现这种情况时，公司有义务提前通知会员。\n

  ##### 第21条 【限制服务使用或终止及注销“会员”】
   “公司”遇到包括但不限于以下内容时，有权限制或终止“会员”的服务使用以及注销“会员”账号。
  1. “会员”故意妨碍或因会员的过失妨碍“公司”服务运营的情况\n
  1. “会员”违反第12条规定的“会员”的义务的情况\n
  1. 一位会员拥有多个账号的情况\n
  1. 公司电脑等通信设备或系统的定期检查、维修、更换、障碍等引起不得已的情况\n
  1. 《电气通信事业法》规定的基干通信事业者中断电子通信服务的情况\n
  1. 国家紧急状态、服务设备障碍、服务瘫痪等引起无法使用服务的情况\n
  1. 使用妨害公序良俗的不文明账号或昵称的情况\n
  1. 相关行政官厅或公共机关要求更正的情况\n
  1. 3个月以上未使用服务的情况\n
  1. 在布告栏上没有索引，只上传了资料等将布告栏作为文件资料室占用的情况\n
  1. 发布或记载第15条第2项所规定的内容或资料的情况\n
  1. 盗用他人的付款信息或进行不正当交易行为的情况。\n

  ##### 第22条 【项目的申请】
  1. 发起人需要选择支持型、公益型、股份型、综合型等众筹的类型和只有目标金额达成时获得资金的方式还是不管集资多少都获得资金的方式来申请发起项目。想要开设项目的“会员”需按照“公司”提供的样式和程序申请项目。这时发起人可与公司缔结与项目相关的合同。\n
  1. 递交申请的项目由“公司”审核是否符合公司规定的以下基准和运营方向，通过审核的项目将在网站上发起。
      A. 是否遵守“公司”关于项目发起的规定和程序\n
      B. 是否按照“公司”提供的项目发起样式申请\n
      C. 是否递交“公司”要求的项目相关的文件（照片、视频、音频等）\n
      D. 是否违反相关法律法规、公共秩序或公序良俗\n
      E. 是否以损失他人名誉或利益为目的\n
      F. 是否符合公司规定的所有项目审核条件\n
      G. 其他是否递交了为发起项目所需的要求。\n
  1. “会员”在申请登记的项目中，不允许出现包含虚假内容、错误内容或者内容有遗漏的情况，“会员”因不遵守此规定而造生的损失,“会员”承担所有责任。
  1. “会员”制作及提供的项目内容及文件的所有权和责任归“会员”所有，若“公司” 判定“会员”登记的项目内容包含以下内容则有权在未通知会员的情况下删除项目，对此公司不承担任何责任。此外,有权根据个案登记，要求发起项目的“会员”进行解释。\n
      A. “会员”提供的会员信息或项目信息造假的情况\n
      B. 内容侵害了第3者知识产权或其他权利的情况\n
      C. 发布未得到“公司”批准的内容的情况\n
      D. “会员”在“公司”网站上发布色情、诽谤、谩骂、损失名誉以及无端公开个人信息等内容，以及含有链接到发布有此类内容的网页链接的情况\n
      E. 会员违法更改项目信息或造假的情况\n
      F. 其他被判定为违反相关法律法规的情况。\n
  1. 通过审核的项目在项目时间结束后，仍可发布于网站或者服务相关网站。\n

  ##### 第23条 【项目的修改】
  1. 在“公司”通过项目申请前，向“公司”申请需修改的事项后，只可在公司规定的范围内进行修改。
  1. 项目申请通过后项目页面的一部分内容不得再进行修改、取消或删除。禁止修改的事项，若申请项目的“会员”任意修改、更改或删除相关内容的情况，参与此项目的会员可提出要求赔偿等法律措施，由此引起的损失“公司”不承担任何责任。\n

  ##### 第24条 【项目的取消】
  1. 在公司通过项目申请前申请人可随意取消项目，取消需通过向“公司”递交申请进行。但，“会员”需向“公司”支付至项目取消为止产生的费用。
  1. 从项目通过申请开始至项目结束为止的时间内，发起人不可随意取消项目。
  1. 项目成功结束后不可取消。但，若存在公司认同的客观性特别原因的情况可取消项目，但仍然需向公司支付以下第33条规定的手续费。
  1. 项目修改的内容通过更新进行通知，会员可随时确认。\n

  ##### 第25条 【付款渠道】
   会员可通过公司规定的方法（信用卡、实时汇款、银行汇款等）在网站内付款，为了正确安全地进行付款，根据相关法律会员信息将提供给相关代理付款机构（银行、信用卡公司等）。\n

  ##### 第26条【取消付款及退款】
  1. 取消付款及退款，遵守《电子交易等消费者保护相关法律》。
  1. 根据个别服务的类型，公司有权根据本条款和使用条件制定取消及退款的相关规定，这种情况需先遵守个别条款及使用条件所规定的取消及退款规定。
  1. 其他本条款及网站的使用说明未规定的取消及退款相关事项则遵守《消费者损失赔偿规定》。\n

  ##### 第27条【采用只有在达成目标金额才获得资金的方式的项目失败时退款】
  1. 采用只有在“会员”参与的金额达到目标金额时才获得资金的方式发起的项目，因项目中途撤回或未达成申请的目标金额，而导致项目失败的情况，“公司”在事件发生的10个工作日内对已付款的会员进行取消付款服务。
  1. 虽然上述第1项规定了取消付款的处理时间，但是根据实际取消付款时处理退款的银行等金融机构的处理情况完成退款的时间会不同。\n

  ##### 第28条 【取消及退款手续费】
  1. 只因会员个人的原因取消付款的情况，产生的手续费由会员自己承担。
  1. 取消及退款手续费根据付款渠道的不同而不等，取消及退款手续费支付方式遵照相关的取消付款及退款规定。\n

  ##### 第29条 【项目的成功】
  1. 采用只有在达到目标金额时才获得资金的方式的项目，其众筹金额结果，以会员指定的项目期限结束时已进行付款的众筹金额为基准，自项目发起至项目众筹截止日期经过后，项目筹资总额低于众筹预期设定金额即视为项目众筹失败\n反之，项目筹资总额等于或大于众筹预期设定金额的，即视为众筹成功
  1. 不管集资多少都获得资金的方式的项目，没有成功和失败之说，获得的众筹总额全部转交给发起人。
  1. 项目的最终众筹总额在项目截止日之前可能因取消及变更申请而改变。
  1. 项目众筹失败的情况，对于项目进行期间筹集的资金，项目发起人没有任何权利，公司根据之前公告的程序将获得的众筹金额一一退还。\n

  ##### 第30条 【支持总额的转交】
  1. 关于集得的总额的转交，“公司”有权要求发起人递交身份证复印件、存折复印件、户口簿副本、营业执照等可以进行身份确认的资料。只有在递交公司要求的资料后才能获得清算总额的转交。
  1. 清算总额必须汇入发起人的账户，此外的账户需根据“公司”的规定获得相对应的认同。
  1. “公司”支付给发起人手续费除外的金额，对于手续费金额则发送相关税单。发起人需诚实地缴纳获得的清算总额需缴纳的税收。
  1. 清算总额在项目结束后，发起人申请转交的15个工作日内汇入发起人提供的账号。
  1. “公司”和发起人对于众筹总额的转交需透明化，必须将总额全部用于项目发起的目的上。\n

  ##### 第31条【众筹总额的使用】
   项目发起人必须将项目筹资总额用于承诺的项目实施与完成、回报的执行与发送等用途的经费，不得用于其他用途。对于未履行承诺时产生的损失，发起人须承担所有责任。\n

  ##### 第32条 【回报的提供】
  1. 项目发起人有义务履行因项目而产生的“会员”与其他“会员”，“会员”与第3者之间的约定，回报提供等相关义务，未履行此义务时发生的损失全部由项目发起人承担。
  1. 项目发起人发起项目时需在网站上公开为参与者提供的回报的相关内容、数量和发送时间，并且有义务在项目成功时根据公布的内容履行回报派送义务。未履行此义务，故意与公布的内容不同或只履行一部分义务的情况，以及派送存在瑕疵的回报的情况，受害会员收回参与金额以及参与者要求损失赔偿等所有法律责任全部由项目发起人承担。
  1. 项目发起人有义务为参与者诚实地解答项目及为参与者提供的回报相关的疑问。\n

  ##### 第33条【手续费】
  1. “会员”注册网站、通过服务发起项目及支持项目都免费，项目参与成功时需要对发起人支付手续费。
  1. 只有在达到目标金额时获得资金的方式的项目和不管集资多少都获得资金的方式的项目的手续费率各不相同。
  1. “公司”与发起人可签订其他有关手续费率的合约。
  1. 其他根据使用服务，“公司”要求另算手续费的情况，手续费收取标准及其他详细内容在执行日7天前公告在网站上。\n

  ##### 第34条【提供翻译服务】
   此服务可能包含由Google提供的翻译。GOOGLE不提供与明示或暗示的翻译相关的所有保证，包括任何关于适销性，适用于特定用途和非侵权的保证，包括准确性，可靠性和任何默示担保\n
  &nbsp;\n
  #### 第5章 其他

  ##### 第35条 【责任限制】
  1. “公司”因第17条第2项，第21条第1项规定的原因而造成服务提供的限制或终止或注销“会员”的情况，所造成的“会员”的损失，“公司”不承担任何责任。
  1. “会员”登记的电子邮件等个人信息为虚假信息或者未履行第9条第2项规定的个人信息变更义务造成的损失，“公司”不承担任何责任。
  1. “公司”不担保会员登录在网站上的信息、资料、事实等的真实性、正确性、信赖性。对于是否使用会员所登记的信息责任全在于会员本身，若因此造成不好的结果公司不承担任何责任。
  1. 公司没有义务介入“会员”与“会员”之间和“会员”与第3者之间以服务为媒介发生的纷争。对于与使用服务相关，“会员”故意或因会员本身的过失造成的“会员”或者第3者的损失，公司不承担任何责任。但，若包含相关法律法规除外另外规定的事项时则根据规定进行。\n

  ##### 第36条【损失赔偿】
   用户因违法本条款的义务而对公司造成损失的情况，用户需对公司进行赔偿。\n

  ##### 第37条【准据法和管辖法院】
  1. “公司”会尽全力圆满解决“公司”与“会员”之间因服务产生的纷争。
  1. 若上述第1项的情况下仍无法解决而提出诉讼的情况，则将递交公司所在管辖地区的法院进行解决。
  1. 公司与用户之间产生的纷争依据大韩民国法律进行解决。\n

  #### 附则
  **本条款于2015年10月14日开始生效。**
  #### 附则
  **本条款于2016年10月20日开始生效。**
  #### 附则
  **本条款于2017年1月5日开始生效。**
  #### 附则
  **本条款于2017年8月1日开始生效。**
  `,
};

export default terms;
