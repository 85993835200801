import { IntlShape } from 'react-intl';

const getMessage = (intl: IntlShape) => {
  return {
    dobInvalidMeaage: intl.formatMessage({ id: 'DOBInvalidMessage' }),
    dobAgeRestrictionMessage: intl.formatMessage({
      id: 'DOBAgeRestrictionMessage',
    }),
    passwordInvalidMessage: intl.formatMessage({
      id: 'passwordInvalidMessage',
    }),
    passwordConfirmNotMatchMessage: intl.formatMessage({
      id: 'passwordConfirmNotMatchMessage',
    }),
    nicknameInvalidMessage: intl.formatMessage({
      id: 'nicknameInvalidMessage',
    }),
    nameInvalidMessage: intl.formatMessage({
      id: 'nameInvalidMessage',
    }),
    emailConflictMessage: intl.formatMessage({
      id: 'emailConflictMessage',
    }),
    password: intl.formatMessage({
      id: 'password',
    }),
    passwordConfirm: intl.formatMessage({
      id: 'passwordConfirm',
    }),
    email: intl.formatMessage({ id: 'email' }),
    name: intl.formatMessage({
      id: 'name',
    }),
    nickname: intl.formatMessage({
      id: 'nickname',
    }),
    nicknameConflictMessage: intl.formatMessage({
      id: 'nicknameConflictMessage',
    }),
    birth: intl.formatMessage({
      id: 'birth',
    }),
    genderFemale: intl.formatMessage({
      id: 'genderFemale',
    }),
    genderMale: intl.formatMessage({
      id: 'genderMale',
    }),
    genderOther: intl.formatMessage({
      id: 'genderOther',
    }),
    genderRNS: intl.formatMessage({
      id: 'genderRNS',
    }),
    gender: intl.formatMessage({
      id: 'gender',
    }),
    noSearchResultMessage: intl.formatMessage({
      id: 'noSearchResultMessage',
    }),
    country: intl.formatMessage({
      id: 'country',
    }),
    signUp: intl.formatMessage({
      id: 'signUp',
    }),
    accecptTerms: intl.formatMessage({ id: 'acceptTerms' }),
    termTitle: intl.formatMessage({ id: 'terms.title' }),
    termMarkDown: intl.formatMessage({ id: 'terms.markdown' }),
    privacyTitle: intl.formatMessage({ id: 'privacy.title' }),
    privacyMarkDown: intl.formatMessage({ id: 'privacy.markdown' }),
    invalidValueMessage: intl.formatMessage({
      id: 'invalidValueMessage',
    }),
    termsConfirm: intl.formatMessage({ id: 'termsConfirm' }),
    allFieldIsRequiredMessage: intl.formatMessage({
      id: 'allFieldIsRequiredMessage',
    }),
  };
};
export default getMessage;
