import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

type SuccessCallback = (response: AxiosResponse) => void;
type FailCallback = (error: AxiosError) => void;

const defaultConfig: AxiosRequestConfig = {
  withCredentials: false,
};

const mergeConfig = (config: AxiosRequestConfig = {}): AxiosRequestConfig => {
  return Object.assign(config ? config : {}, defaultConfig);
};

export const getRequest = (
  url: string,
  config?: AxiosRequestConfig,
  successCallback?: SuccessCallback,
  failCallback?: FailCallback,
) => {
  if (config && config.params) {
    const { params } = config;
    for (const key of Object.keys(params)) {
      params[key] = params[key];
    }
  }

  axios
    .get(url, mergeConfig(config))
    .then((response: AxiosResponse) => {
      if (successCallback) successCallback(response);
    })
    .catch((error: AxiosError) => {
      if (failCallback) failCallback(error);
    });
};
export const deleteRequest = (
  url: string,
  config?: AxiosRequestConfig,
  successCallback?: SuccessCallback,
  failCallback?: FailCallback,
) => {
  axios
    .delete(url, mergeConfig(config))
    .then(response => {
      if (successCallback) successCallback(response);
    })
    .catch(error => {
      if (failCallback) failCallback(error);
    });
};

export const postRequest = (
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
  successCallback?: SuccessCallback,
  failCallback?: FailCallback,
) => {
  axios
    .post(url, data, mergeConfig(config))
    .then(response => {
      if (successCallback) successCallback(response);
    })
    .catch(error => {
      if (failCallback) failCallback(error);
    });
};
export const putRequest = (
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
  successCallback?: SuccessCallback,
  failCallback?: FailCallback,
) => {
  axios
    .put(url, data, mergeConfig(config))
    .then(response => {
      if (successCallback) successCallback(response);
    })
    .catch(error => {
      if (failCallback) failCallback(error);
    });
};
