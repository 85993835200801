import React, { FC, useState, useEffect } from 'react';
import InputText, {
  EventResultType,
} from '../../components/InputText/InputText';
import { checkEmail, findPassword, makestar } from '../../modules/Request/Uri';

//Styles
import styles from './FindPasswordContainer.module.scss';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import { StoreState } from '../../reducers';
import { useHistory, useLocation } from 'react-router';
import { getRequest } from '../../modules/Request';
import { useIntl } from 'react-intl';

const cx = classNames.bind(styles);

interface Props {
  email?: string;
}

const FindPasswordContainer: FC<Props> = ({ email }) => {
  const [value, setValue] = useState<string>(email ? email : '');
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isExist, setIsExist] = useState<boolean | null>(null);
  const history = useHistory();
  const location = useLocation();
  const intl = useIntl();
  if (!new URLSearchParams(location.search).get('hash')) {
    window.location.href = makestar;
  }

  useEffect(() => {
    if (isExist) {
      getRequest(
        findPassword,
        {
          params: {
            email: value,
          },
        },
        () => {
          history.push(`/result/password${location.search}`, { email: value });
        },
        () => {
          history.push(`/result/error`);
        },
      );
    }
  }, [isExist]);

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): EventResultType => {
    const { value } = e.target;
    const result =
      value.match(
        /^([0-9a-zA-Z]?[-_.]?)*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,4}/i,
      ) !== null;

    setValue(value);
    setIsValid(result);

    if (!result) {
      return {
        result: 'invalid',
        message: intl.formatMessage({ id: 'emailInvalidMessage' }),
      };
    }

    return {
      result: 'valid',
    };
  };

  const checkCallback = {
    success: () => {
      setIsValid(true);
      setIsExist(true);
    },
    fail: () => {
      setIsExist(false);
    },
  };

  const onClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    if (value !== '') {
      // 메일 중복체크 api, 현 기능에서는 가입 되어있는 메일인지 확인하므로 success, fail 반대로 적용
      getRequest(
        checkEmail,
        { params: { email: value } },
        checkCallback.fail,
        checkCallback.success,
      );
    }
  };

  const onfocus = () => {
    setIsExist(null);
  };

  return (
    <div className={cx('wrapper')}>
      <span className={cx('title')}>
        {intl.formatMessage({ id: 'findPassword' })}
      </span>
      <div className={cx('description')}>
        <span>{intl.formatMessage({ id: 'findPasswordMessageOne' })}</span>
        <span>{intl.formatMessage({ id: 'findPasswordMessageTwo' })}</span>
      </div>
      <InputText
        type={'text'}
        placeholder={intl.formatMessage({ id: 'email' })}
        onFocus={onfocus}
        onChange={onChange}
        forcedStatus={
          isExist !== null && !isExist && value !== ''
            ? {
                status: 'invalid',
                message: intl.formatMessage({ id: 'findPasswordMessageOne' }),
              }
            : undefined
        }
      />
      <button
        className={cx('button', { active: isValid })}
        onClick={
          isValid
            ? onClick
            : e => {
                e.preventDefault();
              }
        }
        title={intl.formatMessage({ id: 'emailInvalidMessage' })}
      >
        {intl.formatMessage({ id: 'tempPasswordSend' })}
      </button>
    </div>
  );
};

export default connect(
  ({ info }: StoreState) => ({
    //email: info.get('email'),
  }),
  null,
)(FindPasswordContainer);
