import ko from './ko';
import en from './en';
import ja from './ja';
import zh from './zh';

const messages = {
  ko,
  en,
  ja,
  zh,
};

export type localeType = 'ko' | 'en' | 'ja' | 'zh';

export default messages;
