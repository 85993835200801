import React, { FC } from 'react';

import styles from './SignupComplete.module.scss';
import classNames from 'classnames/bind';
import { ReactComponent as Icon } from '../../img/emoji.svg';
import { useLocation, Redirect, Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

const cx = classNames.bind(styles);

const SignupComplete: FC = () => {
  const location = useLocation();
  const intl = useIntl();

  let name;
  if (location.state && location.state.name) {
    name = location.state.name;
  }
  return (
    <div className={cx('wrapper')}>
      {!name && <Redirect to="/result/error" />}
      <Icon className={cx('icon')} />
      <span className={cx('title')}>
        {intl.formatMessage({ id: 'signupComplete' })}
      </span>
      {intl
        .formatMessage(
          { id: 'signupCompleteCongratulationsMessage' },
          { name: name },
        )
        .split('\n')
        .map((message, idx) => (
          <span className={cx('subtitle')} key={`message_${idx}`}>
            {message}
          </span>
        ))}
      <span className={cx('subtitle')}>
        {intl.formatMessage({ id: 'reLoginForFanclubAuthMessage' })}
      </span>
      {/*
      <div className={cx('message')}>
        <span>{'가입하신 메일로 인증코드가 발송되었습니다.'}</span>
        <span>{'프로젝트에 참여하기 위해서는 이메일 인증이 필요합니다.'}</span>
        <span className={cx('warning')}>
          {'24시간이 지나면 재발급이 필요합니다.'}
        </span>
      </div>
      <div className={cx('email')}>
        <Icon className={cx('email-icon')} />
        <div className={cx('contents')}>
          <span>{'이메일'}</span>
          <span>{email}</span>
        </div>
      </div>
      <div className={cx('login')}>
        <span>{'이메일을 못 받으셨나요?'}</span>
        <span>{'로그인 하시면 인증코드를 재발급 받을 수 있습니다.'}</span>
      </div>
      */}
      <Link to={`/${location.search}`} className={cx('button')}>
        {intl.formatMessage({ id: 'loginUpperCase' })}
      </Link>
    </div>
  );
};

export default SignupComplete;
