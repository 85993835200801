import React, { FC, useEffect } from 'react';
import { Route, useHistory, useLocation, Switch } from 'react-router-dom';
import './index.scss';
import { IntlProvider } from 'react-intl';
import { StoreState } from './reducers';
import { actionCreators as infoActions } from './actions/info';

import Header from './components/Header/Header';
import FindPasswordContainer from './container/FindPasswordContainer/FindPasswordContainer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import messages, { localeType } from './i18n';

//Containers
import LoginContainer from './container/LoginContainer/LoginContainer';
import SignupFormContainer from './container/SignupContainer/SignupContainer';
import Error from './components/Error/Error';
//Request
import { getRequest, verifyFanclub } from './modules/Request';
import { AxiosResponse, AxiosError } from 'axios';
import ResultContainer from './container/ResultContainer/ResultContainer';
import SignupComplete from './components/SignupComplete/SignupComplete';

interface Props {
  locale: localeType;
  InfoActions: typeof infoActions;
}

const App: FC<Props> = ({ locale, InfoActions }) => {
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    //const redirect = params.get('redirect');
    const hash = params.get('hash');

    /*if (redirect) {
      InfoActions.setRedirect(redirect);
    }*/
    if (hash) {
      getRequest(
        verifyFanclub,
        { params: { hash: hash } },
        successCallback.fanclub,
        failCallback.fanclub,
      );
    }
    try {
      const locale = window.navigator.language.split('-')[0];
      InfoActions.setLocale(locale);
    } catch {
      InfoActions.setLocale('ko');
    }
  }, []);

  const successCallback = {
    fanclub: (response: AxiosResponse) => {
      InfoActions.setFanclub(response.data);
      if (response.data.status && response.data.status === 'verify') {
        history.push(`/result/fanclub${location.search}`);
      }
    },
  };
  const failCallback = {
    fanclub: (error: AxiosError) => {
      history.push('/result/fail');
    },
  };
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <Route component={Header} />
      <div className={'contents'}>
        <Switch>
          <Route exact path="/" component={LoginContainer} />
          <Route exact path="/signup" component={SignupFormContainer} />
          <Route exact path="/signup/complete" component={SignupComplete} />
          <Route exact path="/password" component={FindPasswordContainer} />
          <Route path="/result/:type" component={ResultContainer} />
          <Route component={Error} />
        </Switch>
      </div>
    </IntlProvider>
  );
};

export default connect(
  ({ info }: StoreState) => ({
    locale: info.get('locale'),
    projectUrl: info.getIn(['fanclub', 'projectUrl']),
    fanclub: info.getIn(['fanclub', 'fanclub']),
  }),
  dispatch => ({
    InfoActions: bindActionCreators(infoActions, dispatch),
  }),
)(App);
