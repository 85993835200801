import privacy from './term/privacy';
import terms from './term/terms';

const ja: Record<string, string> = {
  ...terms,
  ...privacy,
  emailInvalidMessage: '正しい形式で入力されているか確認してください。',
  signupCompleteCongratulationsMessage:
    '{name}様はStarmakerになりました。おめでとうございます。',
  signupComplete: '会員登録完了',
  login: 'ログイン',
  required: '必須',
  termsAllMessage:
    'Makestarのサービスを利用するためには利用規約の同意が必要です。',
  acceptTerms: '利用規約同意',
  findPasswordMessageOne: '会員登録したメールアドレスを入力して下さい。',
  findPasswordMessageTwo: '該当のメールに臨時パスワードが発送されます。',
  email: 'メール',
  signUp: '会員登録',
  loginFailMessage: 'メールアドレスまた、パスワードをもう一度確認して下さい。',
  password: 'パスワード',
  findPassword: 'パスワード探し',
  failureSuccessMessage:
    '認証メールの「有効期限切れ」、または「正しくないアドレス」です。',
  tempSuccessMessage1: '臨時パスワードで変更が完了されました。',
  tempSuccessMessage2:
    '臨時パスワードでログイン後，パスワードを変更して下さい。',
  DOBInvalidMessage: '有効な日付を入力してください。',
  DOBAgeRestrictionMessage: '13歳未満の方はご登録いただけません。',
  passwordInvalidMessage: 'パスワードは6文字以上20文字以下で入力してください。',
  passwordConfirmNotMatchMessage:
    'パスワードが一致しま船。もう一度、入力して下さい。',
  nameInvalidMessage: '氏名は2文字以上で入力してください。',
  nicknameInvalidMessage: 'ニックネームは3文字以上で入力してください。',
  emailConflictMessage: 'すでに使われているメールアドレスです。',
  passwordConfirm: 'パスワード確認',
  name: '名前',
  birth: '生年月日',
  nickname: 'ニックネーム',
  nicknameConflictMessage: 'すでに使われているニックネームです。',
  genderMale: '男性',
  genderFemale: '女性',
  genderOther: 'その他',
  genderRNS: '非公開',
  gender: '性別',
  country: '居住国',
  noSearchResultMessage: '一致する情報を見つかりませんでした。',
  loginUpperCase: 'ログイン',
  signUpUpperCase: '会員登録',
  tempPassword: 'Temporary password.',
  verifyFanclub: 'ファンクラブを認証する',
  // new
  toiLoginPage: 'ログインページへ',
  tempPasswordCompleteMessage:
    '{email}に臨時パスワードを送信しました。\n届いていない場合は迷惑メールフォルダを確認してください。',
  checkSpamMailMessage: '登録されていないメールアドレスです。',
  notUserMailMessage: '使用中のMAKESTARのIDでログインしてください。',
  fanclubVerifySuccessMessage: '{fanclub} の認証が完了しました。',
  inputValidMakestarIdMessage: 'まだMAKESTARに登録していませんか？',
  signupMessage: 'パスワードを忘れましたか？',
  forgotPasswordMessage: '認証に失敗しました。',
  reLoginMessage:
    'プロジェクトに参加するために\nMAKESTARでログインしてください。',
  tempPasswordLoginMessage: '臨時パスワードでログインしてください。',
  requestErrorMessage:
    '要請中にエラーが発生しました。\nもう一度お試しください。',
  invalidValueMessage: '正確に入力してください。',
  allFieldIsRequiredMessage: '全ての項目に記入してください。',
  reLoginForFanclubAuthMessage:
    'ログインしてファンクラブ認証を完了してください。',
  termsConfirm: '会員規約の確認',
  allAgreeTerms: '会員規約の内容に同意する',
  tempPasswordSend: '臨時パスワードを送信する',
  toMakestar: 'MAKESTARホームページへ',
  toProject: 'KNKプロジェクトへ',
};

export default ja;
