import { handleActions, Action } from 'redux-actions';
import { Record } from 'immutable';
import {
  SET_LOCALE,
  SET_REDIRECT,
  SET_FANCLUB,
  SetLocalePayload,
  SetFanclubPayload,
  SetRedirectPayload,
} from '../actions/info';
import { makestar } from '../modules/Request';
import { localeType } from '../i18n';

interface State {
  locale: localeType;
  redirect: string;
  fanclub?: Record<SetFanclubPayload>;
}

const initialStateRecord = Record<State>({
  locale: 'ko',
  redirect: makestar,
  fanclub: undefined,
});
const fanClubRecord = Record<SetFanclubPayload>({
  email: '',
  name: '',
  projectUrl: '',
  birthday: '',
  fanclub: { id: '', name: '' },
});

export type InfoType = Record<State>;

export default handleActions<InfoType, any>(
  {
    [SET_LOCALE]: (state, action: Action<SetLocalePayload>): InfoType => {
      const { payload } = action;
      if (['ko', 'en', 'ja', 'zh'].indexOf(payload) !== -1) {
        return state.set('locale', payload);
      }
      return state;
    },
    [SET_REDIRECT]: (state, action: Action<SetRedirectPayload>): InfoType => {
      const { payload } = action;
      return state.set('redirect', payload);
    },
    [SET_FANCLUB]: (state, action: Action<SetFanclubPayload>): InfoType => {
      const { payload } = action;

      return state.set('fanclub', fanClubRecord(payload));
    },
  },
  initialStateRecord(),
);
