import classNames from 'classnames/bind';
import { Record } from 'immutable';
import moment from 'moment';
import React, { FC, useEffect, useState, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import Recaptcha from 'react-recaptcha';
import { connect } from 'react-redux';
import Select from 'react-select';
import InputText, {
  EventResultType,
} from '../../components/InputText/InputText';
import TermsWrapper from '../../components/Terms/TermsWrapper';

import { StoreState } from '../../reducers';
import { actionCreators as infoActions } from '../../actions/info';

//Styles
import styles from './SignupContainer.module.scss';
import { bindActionCreators } from 'redux';
import {
  getRequest,
  checkEmail,
  countries,
  postRequest,
  signup,
  checkNickname,
  makestar,
} from '../../modules/Request';
import { AxiosResponse, AxiosError } from 'axios';
import { useHistory, useLocation } from 'react-router';
import { useIntl } from 'react-intl';
import getMessage from './message';
import { localeType } from '../../i18n';

const cx = classNames.bind(styles);

const selectStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: (originStyle: React.CSSProperties) => ({
    ...originStyle,
    marginTop: '0',
    borderRadius: '4px',
    boxShadow: 'none',
    border: 'solid 1px #cccccc',
  }),
  control: (originStyle: React.CSSProperties) => {
    return {
      ...originStyle,
      border: 'solid 1px #c4c4c4',
      boxShadow: 'none',
      '&:hover': 'none',
      height: '48px',
    };
  },
  valueContainer: (originStyle: React.CSSProperties) => ({
    ...originStyle,
    paddingLeft: '3%',
  }),
  option: (originStyle: React.CSSProperties, { isFocused }: any) => {
    return {
      ...originStyle,
      color: isFocused ? '#ef371e' : '',
      backgroundColor: isFocused ? '#f2f2f2' : '',
      ':active': { backgroundColor: '' },
    };
  },
};
const selectValidStyles = Object.assign(Object.assign({}, selectStyles), {
  control: (originStyle: React.CSSProperties) => {
    return {
      ...originStyle,
      border: 'solid 1px #3fa2f7',
      boxShadow: 'none',
      '&:hover': 'none',
      height: '48px',
    };
  },
});

interface Props {
  locale: localeType;
  email?: string;
  name?: string;
  birthday?: string;
}

interface Validation {
  term: boolean;
  email: boolean;
  password: boolean;
  name: boolean;
  nickname: boolean;
  birth: boolean;
  gender: boolean;
  nation: boolean;
  recaptcha: boolean;
}
interface Active {
  term: boolean;
  info: boolean;
}
interface Password {
  origin: string;
  check: string;
}

const passwordRecord = Record<Password>({
  origin: '',
  check: '',
});
const validationRecord = Record<Validation>({
  term: false,
  email: false,
  password: false,
  name: false,
  nickname: false,
  birth: false,
  gender: false,
  nation: false,
  recaptcha: false,
});
const activeRecord = Record<Active>({ term: false, info: false });

const initialActive: Record<Active> = activeRecord();
const initialPassword: Record<Password> = passwordRecord();

// validation객체 불변성을 위해 immutable Record를 사용합니다.
const SignupContainer: FC<Props> = ({ email, locale }) => {
  const location = useLocation();
  const message = getMessage(useIntl());

  if (!new URLSearchParams(location.search).get('hash')) {
    window.location.href = makestar;
  }

  const [active, setActive] = useState<Record<Active>>(initialActive);
  const [step, setStep] = useState<number>(1);
  const [isChina, setIsChina] = useState<boolean>(false);

  const [password, setPassword] = useState<Record<Password>>(initialPassword);
  const [isDuplicateMail, setIsDuplicateMail] = useState<boolean>(false);
  const [isDuplicateNickname, setIsDuplicateNickname] = useState<boolean>(
    false,
  );

  const [recaptcha, setReCaptcha] = useState<boolean>(false);
  const [countriesData, setCountriesData] = useState<any>([]);
  const [validation, setValidation] = useState<Record<Validation>>(
    validationRecord({ email: !!email }),
  );

  const formRef = useRef<HTMLFormElement>(null);
  const history = useHistory();

  const successCallback = {
    countries: (response: AxiosResponse) => {
      const { content } = response.data;
      if (Array.isArray(content)) {
        const data = content.map(option => {
          return {
            value: option.code,
            label: option.name,
          };
        });

        setCountriesData(data);
      }

      setIsChina(content.isChina);
    },
  };

  useEffect(() => {
    getRequest(countries, {}, successCallback.countries);
  }, []);

  useEffect(() => {
    if (email) {
      checkMail({ email: email });
    }
  }, [email]);

  useEffect(() => {
    setValidation(validation.set('recaptcha', recaptcha));
  }, [recaptcha, validation]);

  useEffect(() => {
    let result = true;
    for (const value of validation
      .toSeq()
      .valueSeq()
      .toArray()) {
      result = result && value;
      if (!result) {
        break;
      }
    }

    setActive(active.set('info', result));
  }, [validation, active]);

  useEffect(() => {
    if (isChina) {
      setValidation(validation.set('recaptcha', true));
    }
  }, [validation, isChina]);

  const checkMail = (param: Object) => {
    let result = false;
    getRequest(
      checkEmail,
      {
        params: param,
      },
      () => {
        setValidation(validation.set('email', !!email && !result));
        setIsDuplicateMail(result);
      },
      () => {
        setValidation(validation.set('email', result));
        setIsDuplicateMail(!result);
      },
    );
  };

  const checkNicknameCallback = {
    success: (response: AxiosResponse) => {
      setIsDuplicateNickname(false);
    },
    fail: (error: AxiosError) => {
      setIsDuplicateNickname(true);
    },
  };

  const onBlur = {
    email: ({
      target: { value },
    }: React.FocusEvent<HTMLInputElement>): EventResultType => {
      if (validation.get('email')) {
        checkMail({ email: value });
      }
    },
    password: (e: React.FocusEvent<HTMLInputElement>) => {
      setPassword(password.set('origin', e.target.value));
      setValidation(
        validation.set(
          'password',
          password.get('check') !== '' &&
            password.get('origin') !== '' &&
            password.get('check') === password.get('origin'),
        ),
      );
    },
    passwordCheck: (e: React.FocusEvent<HTMLInputElement>) => {
      setValidation(
        validation.set(
          'password',
          password.get('check') !== '' &&
            password.get('origin') !== '' &&
            password.get('check') === password.get('origin'),
        ),
      );
    },
    nickname: (e: React.FocusEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const { current } = formRef;
      if (current) {
        const mail = email;

        getRequest(
          checkNickname,
          { params: { nickname: value, email: mail } },
          checkNicknameCallback.success,
          checkNicknameCallback.fail,
        );
      }
    },
    birth: (e: React.FocusEvent<HTMLInputElement>): EventResultType => {
      const { value } = e.target;
      const text = value.replace(/[^0-9]/g, '');
      if (text.length !== 8) {
        return {
          result: 'invalid',
          message: message.dobInvalidMeaage,
        };
      }

      const year = text.substr(0, 4);
      const month = text.substr(4, 2);
      const day = text.substr(6, 2);

      const date = moment([year, Number(month) - 1, day]);
      const age = moment().diff(date.format('YYYYMMDD'), 'years');
      if (!date.isValid() || date > moment(new Date()) || age < 14) {
        return {
          result: 'invalid',
          message:
            age < 14
              ? message.dobAgeRestrictionMessage
              : message.dobInvalidMeaage,
        };
      }

      return {
        result: 'valid',
        afterValue: `${year}-${month}-${day}`,
      };
    },
  };

  const onChange = {
    email: ({
      target: { value },
    }: React.ChangeEvent<HTMLInputElement>): void => {
      setValidation(
        validation.set(
          'email',
          value.match(
            /^([0-9a-zA-Z]?[-_.]?)*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,4}/i,
          ) !== null,
        ),
      );
    },
    password: (e: React.FocusEvent<HTMLInputElement>): EventResultType => {
      const { value } = e.target;
      if (value.length < 6 || value.length > 20) {
        setValidation(validation.set('password', false));

        return {
          result: 'invalid',
          message: message.passwordInvalidMessage,
        };
      }

      return {
        result: 'valid',
      };
    },
    passwordCheck: (e: React.FocusEvent<HTMLInputElement>): EventResultType => {
      const { value } = e.target;
      setPassword(password.set('check', value));

      if (value !== password.get('origin')) {
        setValidation(validation.set('password', false));

        return {
          result: 'invalid',
          message: message.passwordConfirmNotMatchMessage,
        };
      }
      return {
        result: 'valid',
      };
    },
    name: (e: React.ChangeEvent<HTMLInputElement>): EventResultType => {
      const { value } = e.target;
      if (value.length < 2) {
        setValidation(validation.set('name', false));
        return {
          result: 'invalid',
          message: message.nameInvalidMessage,
        };
      }
      setValidation(validation.set('name', true));
      return {
        result: 'valid',
      };
    },
    nickname: (e: React.ChangeEvent<HTMLInputElement>): EventResultType => {
      const { value } = e.target;
      if (value.length < 3) {
        setValidation(validation.set('nickname', false));
        return {
          result: 'invalid',
          message: message.nicknameInvalidMessage,
        };
      }
      setValidation(validation.set('nickname', true));
      return {
        result: 'valid',
      };
    },
    birth: ({
      target: { value },
    }: React.ChangeEvent<HTMLInputElement>): EventResultType => {
      const text = value.replace(/[^0-9]/g, '');
      const result: EventResultType = {
        result: 'invalid',
      };
      if (text.length !== 8) {
        setValidation(validation.set('birth', false));
        result.message = message.dobInvalidMeaage;

        return result;
      }
      const year = text.substr(0, 4);
      const month = text.substr(4, 2);
      const day = text.substr(6, 2);

      const date = moment([year, Number(month) - 1, day]);
      if (!date.isValid() || date > moment(new Date())) {
        result.message = message.dobInvalidMeaage;

        return result;
      }

      setValidation(validation.set('birth', true));
      result.result = 'valid';

      return result;
    },
    term: (isAllChecked: boolean): void => {
      setValidation(validation.set('term', isAllChecked));
      setActive(active.set('term', isAllChecked));
    },
    gender: (option: any): void => {
      const { value } = option;
      setValidation(validation.set('gender', value !== ''));
    },
    nation: (option: any): void => {
      const { value } = option;
      setValidation(validation.set('nation', value !== ''));
    },
  };

  const onClick = {
    term: (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setStep(step + 1);
    },
    info: (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      const { current } = formRef;
      if (current && active) {
        const formData = new FormData(current);
        const hash = new URLSearchParams(location.search).get('hash');
        const realname = formData.get('realname');
        if (email) formData.append('email', email);
        if (hash) {
          formData.append('hash', hash);
        }
        const birth = formData.get('birth');
        if (birth && typeof birth === 'string') {
          const [year, month, day] = birth.split('-');

          formData.append('birthYear', year);
          formData.append('birthMonth', month);
          formData.append('birthDay', day);

          formData.delete('birth');
        }

        postRequest(
          signup,
          formData,
          {},
          () => {
            history.push(`/signup/complete${location.search}`, {
              name: realname,
            });
          },
          () => {
            history.push('/result/error');
          },
        );
      }
    },
  };

  const onFocus = {
    birth: (e: React.FocusEvent<HTMLInputElement>): EventResultType => {
      const { value } = e.target;

      if (value !== '') {
        return {
          result: 'normal',
          afterValue: value.replace(/[^0-9]/gi, ''),
        };
      }
    },
    email: () => {
      setIsDuplicateMail(false);
    },
    nickname: () => {
      setIsDuplicateNickname(false);
    },
  };

  const verifyCallback = (): void => {
    setReCaptcha(true);
  };

  const expiredCallback = (): void => {
    setReCaptcha(false);
  };

  return (
    <div className={cx('wrapper')}>
      <form ref={formRef}>
        {
          <div className={cx('term', { hide: step !== 1, show: step === 1 })}>
            <div className={cx('title')}>
              <span>{message.termsConfirm}</span>
            </div>
            <TermsWrapper
              titles={[message.termTitle, message.privacyTitle]}
              onChange={onChange.term}
            >
              <ReactMarkdown source={message.termMarkDown} />
              <ReactMarkdown source={message.privacyMarkDown} />
            </TermsWrapper>

            <button
              className={cx('button', { active: active.get('term') })}
              title={active.get('term') ? '' : message.invalidValueMessage}
              onClick={
                active.get('term')
                  ? onClick.term
                  : e => {
                      e.preventDefault();
                    }
              }
            >
              {message.accecptTerms}
            </button>
          </div>
        }
        {
          <section
            className={cx('info', { hide: step !== 2, show: step === 2 })}
          >
            <div className={cx('title')}>
              <span>{message.signUp}</span>
              <span className={cx('required')}>
                {message.allFieldIsRequiredMessage}
              </span>
            </div>
            <section>
              <InputText
                type={'email'}
                disable={!!email}
                //readOnly={!!email}
                defaultValue={email}
                name={'email'}
                placeholder={message.email}
                onFocus={onFocus.email}
                onChange={onChange.email}
                onBlur={onBlur.email}
                forcedStatus={
                  !validation.get('email') && isDuplicateMail
                    ? {
                        status: 'invalid',
                        message: message.emailConflictMessage,
                      }
                    : undefined
                }
              />
              <InputText
                type={'password'}
                onChange={onChange.password}
                onBlur={onBlur.password}
                name={'passwd'}
                placeholder={message.password}
              />
              <InputText
                type={'password'}
                onChange={onChange.passwordCheck}
                onBlur={onBlur.passwordCheck}
                forcedStatus={
                  password.get('check') !== password.get('origin')
                    ? {
                        status: 'invalid',
                        message: message.passwordConfirmNotMatchMessage,
                      }
                    : password.get('check') !== ''
                    ? {
                        status: 'valid',
                      }
                    : { status: 'normal' }
                }
                placeholder={message.passwordConfirm}
              />
            </section>
            <section>
              <InputText
                type={'text'}
                placeholder={message.name}
                name={'realname'}
                onChange={onChange.name}
              />
              <InputText
                type={'text'}
                placeholder={message.nickname}
                name={'nickname'}
                onChange={onChange.nickname}
                onBlur={onBlur.nickname}
                onFocus={onFocus.nickname}
                forcedStatus={
                  isDuplicateNickname
                    ? {
                        status: 'invalid',
                        message: message.nicknameConflictMessage,
                      }
                    : undefined
                }
              />
              <InputText
                type={'text'}
                placeholder={`${message.birth}(YYYY-MM-DD)`}
                name={'birth'}
                onFocus={onFocus.birth}
                onBlur={onBlur.birth}
                onChange={onChange.birth}
              />
            </section>
            <section>
              <Select
                className={cx('select')}
                styles={
                  validation.get('gender') ? selectValidStyles : selectStyles
                }
                options={[
                  { value: 'Female', label: message.genderFemale },
                  { value: 'Male', label: message.genderMale },
                  { value: 'Other', label: message.genderOther },
                  { value: 'RatherNotSay', label: message.genderRNS },
                ]}
                onChange={onChange.gender}
                isSearchable={false}
                placeholder={message.gender}
                name={'gender'}
              />
              <Select
                name={'countryCode'}
                className={cx('select')}
                styles={
                  validation.get('nation') ? selectValidStyles : selectStyles
                }
                noOptionsMessage={() => message.noSearchResultMessage}
                options={countriesData}
                placeholder={message.country}
                onChange={onChange.nation}
              />
            </section>
            {!isChina && (
              <section>
                <div className={cx('recaptcha')}>
                  <Recaptcha
                    id="g-recaptcha"
                    // sitekey={'6LdB0wITAAAAAGb0elScng0Br6gUe-18vrW4hmo6'}
                    sitekey={'6LfNWYwUAAAAAHa-uqEzjxvLX_AAxUx4p1TeQz1W'}
                    render="explicit"
                    verifyCallback={verifyCallback}
                    expiredCallback={expiredCallback}
                    hl={locale}
                  />
                </div>
              </section>
            )}
            <button
              className={cx('button', { active: active.get('info') })}
              title={active.get('info') ? '' : message.invalidValueMessage}
              onClick={
                active.get('info')
                  ? onClick.info
                  : e => {
                      e.preventDefault();
                    }
              }
            >
              {message.signUp}
            </button>
          </section>
        }
      </form>
    </div>
  );
};

export default connect(
  ({ info }: StoreState) => ({
    email: info.getIn(['fanclub', 'email']),
    name: info.getIn(['fanclub', 'name']),
    birthday: info.getIn(['fanclub', 'birthday']),
    locale: info.get('locale'),
  }),
  dispatch => ({
    InfoActions: bindActionCreators(infoActions, dispatch),
  }),
)(SignupContainer);
